export default {
  page_header: 'Ajustes',
  page_subtitle: 'Administra tus opciones de información, privacidad y seguridad.{br}Los detalles de pago se pueden editar en la página {url}',
  payouts: '«Pagos»',
  personal_info: 'Información personal',
  change_password: 'Cambiar contraseña',
  security_options: 'Opciones de seguridad',
  email: 'E-mail',
  first_name: 'Nombre',
  last_name: 'Apellido',
  contact_details: 'Datos de contacto',
  contact_details_hint: 'Telegram, Skype o cualquier otro tipo de contacto',
  update_personal_info: 'Actualizar los datos perdonales',
  old_password: 'Contraseña anterior',
  new_password: 'Nueva contraseña',
  new_password_confirm: 'Nueva contraseña (confirmación)',
  change_password_submit: 'Cambiar la contraseña',
  security_code: 'Código de seguridad',
  security_code_description: 'Es una medida de seguridad adicional que se puede habilitar por tu cuenta. ' + 'Al eliminar archivos, solicitar los fondos acumulados o cambiar detalles de pago ' + 'el sistema comenzará a exigir una confirmación del código de seguridad.',
  use_security_code_checkbox_label: 'Confirmar acciones por código de seguridad',
  security_code_field_label: 'Código de seguridad',
  security_code_confirmation_field_label: 'Código de seguridad (confirmación)',
  security_code_hint: '*Tenga cuidado, el código de seguridad no se puede restaurar. ' + 'El nuevo código de seguridad entra en vigor a plazo de 15 días, durante este período todas las operaciones con fondos ' + ', los detalles de pago y la eliminación de archivos serán congelados.',
  update_security_options: 'Actualizar las opciones de seguridad',
  password_hacking_prevention_system: 'Sistema de prevención de hackeo de contraseñas',
  limit_login_attempts_field_label: 'Límite de intentos de iniciar sesión fallidos',
  limit_login_attempts_field_hint: 'Al realizar 10 intentos fallidos, se requerirá autorización por mail',
  use_captcha_field_label: 'Activar captcha para proteger mi cuenta',
  use_captcha_field_hint: 'Recomendamos activar esta opción',
  errors: {
    password_not_match_confirmation: 'No coincide con la contraseña confirmada',
    security_code_not_match_confirmation: 'El código de seguridad no cumple los requisitos confirmados'
  },
  password_changing_notification_sent: 'Hemos enviado la notificación del cambio de contraseña a tu correo electrónico especificado durante el registro.',
  drop_all_sessions: 'Cerrar todas las sesiones',
  current_security_code_field_label: 'Introduce el código de seguridad'
};