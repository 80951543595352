export default {
  page_title: "Condições e planos de Taxa",
  page_subtitle: "Configurações do programa de afiliados, planos de taxa com descrições e opções para renda adicional.",
  fd1_partners_program_settings: "Configurações do Programa para Afiliados Turbobit",
  fd2_partners_program_settings: "Configurações do programa de afiliado Hitfile",
  your_current_plan: "Plano de taxa atual:",
  rate_plan_from_next_day: "O plano de taxa entra em vigor no próximo dia:",
  change_rate_plan_hint: "Importante: Você pode alterar o plano de taxa uma vez por semana.",
  change_rate_plan: "Enviar alterações",
  turbobit_rate_plans: "Plano de Taxas Turbobit",
  hitfile_rate_plans: "Plano de Taxas Hitfile",
  premium_sale: "Venda Premium (Pague Por Venda)",
  rates_for_sale_of_premium_accounts: "Taxas para venda de conta premium (acesso ao turbo)",
  rates_for_sale_of_premium_accounts_hitfile: "Taxas de venda de conta premium (acesso premium)",
  country: "País",
  percent_of_sale: "% de venda",
  percent_of_rebill: "% de resgate",
  all_countries: "Todos os países",
  mixed: "Misto (Pague Por Venda e Downloads)",
  mixed_hint:
    "Um plano misto de taxas para fazer lucro a partir de downloads de seus arquivos, juntamente com contas premium de vendas",
  rates: "Taxas",
  group: "Grupo",
  for_1000_downloads: "para 1000 downloads",
  for_1000_downloads_hint:
    "apenas downloads de arquivos maiores que 10MB são contados, " +
    "downloads incompletos incluídos. A exclusividade é calculada no contexto de uma conta",
  group_and_countries: "Grupos e países",
  mixed_countries_g: "Outros países",
  all_inclusive: "Tudo Incluso (Pague por Download)",
  all_inclusive_hint: "O plano de taxa de lucro por downloads, baseado no tamanho do arquivo",
  rate_for_downloading: "Taxas para 1.000 downloads de seus arquivos",
  size_country: "Tamanho/País",
  rate_for_downloading_hint: "downloads incompletos incluídos. A exclusividade é calculada no contexto de uma conta",
  all_inclusive_countries_d: "Outros países",
  additional_earnings: "Aumente sua renda",
  bonus_program: "Programa de bônus",
  gold_partners: "Parceiros Gold",
  increased_payments_and_extra_bonuses: "Taxas maiores e bônus extras para parceiros regulares e eficazes",
  increased_payments_and_extra_bonuses_1: "Até <b>85%</b> de vendas para o plano PPS;",
  increased_payments_and_extra_bonuses_2: "Até <b>60%</b> de vendas + $8,75 por 1000 downloads no plano Misto;",
  increased_payments_and_extra_bonuses_3: "Até <b>$25</b> por 1000 downloads para o plano PPD.",
  for: "Para",
  site_owners: "Proprietário de site",
  for_site_owners_line_1:
    "Obtenha <b>+{percents}% das vendas feitas por usuários indicados em seu site</b>, blog, fórum, " +
    "página da rede social independentemente do proprietário do arquivo.",
  for_site_owners_line_2: "Use seu site e compartilhe links Hitfile " + "para gerar renda extra.",
  referral_system: "Sistema de indicação",
  referral_system_text:
    "Obtenha <b>12%</b> dos ganhos de novos parceiros a partir do momento em que eles se registrarem com seu link de indicação " +
    "no programa de afiliados durante os próximos 3 meses.<br />" +
    "<i>Após três meses:</i><br />" +
    "<b>6%</b> dos ganhos dos parceiros convidados no próximo 6 mês;<br />" +
    "<b>3%</b> dos ganhos dos parceiros convidados após aqueles 6 meses seguidos. <br /><br />" +
    "Além disso, devido ao sistema de indicação em dois níveis, você ganha 1% dos ganhos de " +
    "cada parceiro que foi apresentado ao programa de afiliados por você. <br />" +
    "Se você não tiver nenhum outro ganho, exceto o rendimento do bônus feito por suas indicações, a porcentagem do " +
    "sistema de referência de bônus começa a diminuir até que atinja 0%. A verificação é realizada mensalmente.",
  price_of_premium_account_for_users: "Preço das Contas Premium para usuários",
  price_of_premium_account_for_users_subtitle:
    "Selecione os preços das taxas. Mais tarde você também poderá definir as taxas " +
    "individualmente para cada arquivo/pasta",
  standard_rates: "Taxa padrão",
  expensive_rates: "Taxas caras",
  my_price: "Meu preço",
  make_your_own_price_rates_variation: "Faça sua própria variação de taxas de preço (selecione até {value})",
  free_download_options: "Opções de download grátis",
  free_download_options_available_only_on_pps_rate_plan:
    "Configurações de download gratuito disponíveis apenas para o plano de taxa PPS",
  allow_a_free_download_of_all_files: "Permitir download gratuito de todos os arquivos",
  allow_a_free_download_of_all_files_hint:
    "Todos os arquivos de todas as pastas estarão disponíveis para download gratuito. " +
    "Configurações individuais de arquivos e pastas serão ignoradas.",
  disallow_a_free_download_of_all_files: "Proibir download gratuito de todos os arquivos",
  disallow_a_free_download_of_all_files_hint:
    "Todos os arquivos de todas as pastas estarão disponíveis para download gratuito " +
    "download somente com acesso premium. Configurações individuais para arquivos e pastas serão ignoradas.",
  use_individual_settings_of_files_and_folders: "As configurações de download padrão dos arquivos recém-enviados",
  use_individual_settings_of_files_and_folders_hint:
    "A disponibilidade de um download gratuito depende das configurações individuais " +
    "que foram selecionadas manualmente para uma pasta ou arquivo específico. Caso contrário, as configurações padrão serão aplicadas.",
  inbox_free_download_label:
    "Selecione as configurações padrão para arquivos enviados recentemente para a pasta 'Caixa de Entrada':",
  allow_free_download: "Permitir download gratuito",
  disallow_free_download: "Proibir download gratuito",
  the_maximum_file_size_available_for_free_downloads: "O tamanho máximo de arquivo disponível para download gratuito",
  the_maximum_file_size_available_for_free_downloads_label:
    "Arquivos maiores que um determinado tamanho não estarão disponíveis " + "para download gratuito:",
  free_download: { unlimited: "sem limites" }
};
