export default {
  main: {
    periods: {
      '7D': '7 derniers jours',
      '30D': '30 derniers jours',
      W: 'Cette semaine',
      M: 'Ce mois'
    }
  },
  info_blocks: {
    earnings: {
      title: 'Recettes',
      today: 'recettes aujourd\'hui',
      stat_volume: '${volume}',
      today_volume: '+${volume}'
    },
    downloads: {
      title: 'Téléchargements',
      today: 'téléchargements aujourd\'hui',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'Achats',
      today: 'achats aujourd\'hui',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'Solde',
      link: 'montant en attente',
      volume: '${volume}',
      hold: '${volume}'
    }
  },
  programs: {
    title: 'Programmes d\'affiliation',
    columns: {
      name: 'Projet',
      description: 'Conditions générales',
      status: 'Statut'
    },
    status: {
      on: 'Actif',
      off: 'Pas activé'
    },
    conditions: 'Conditions et forfaits'
  },
  files_storage_blocks: {
    title: 'Période de stockage des fichiers',
    title_link: 'en savoir plus',
    term: 'Votre période de stockage : <b>{normal_term} jours</b> (<i>' + ' <b>{premium_term} jours</b> avec premium</i>)',
    next_update: 'Prochaine mise à jour de statut sur {next_update}'
  },
  earnings_table: {
    title: 'Revenus par mois',
    fields: {
      date: 'Mois',
      money: 'Gain réalisé',
      files_size: 'Taille des fichiers (GB)*',
      storage_cost: 'Coût de stockage**'
    },
    last30days_row_title: 'les 30 derniers jours',
    notes: {
      copies: '* Nous ne prenons pas en compte les copies de fichiers (physiques et virtuels).',
      storage_cost: '** Le stockage des fichiers est absolument gratuit, l\'espace est également illimité. Nous utilisons le graphique "coût de stockage" pour calculer\n' + ' la période de stockage des fichiers qui peut être fournie.',
      unlimited_period: 'Par conséquent, pour les partenaires avec un revenu de plus de 1$ pour les 30 derniers jours ' + 'et une variation positive des revenus à\n' + ' le coût de stockage, le temps de stockage des fichiers est illimité.',
      calculate_cost: 'Nous évaluons l\'espace sur nos serveurs à 0,016 $ par 1 Go par mois ' + 'et c\'est ainsi que le graphique "coût du stockage" est calculé.'
    }
  },
  earnings_chart: { title: 'Revenus par projets' }
};