export default {
  payout_details: "Detalles del pago",
  payout_details_subtitle:
    "Por razones de seguridad, los detalles de pago nuevos y actualizados deben ser " +
    "verificados por mail y entrar en vigor después de {days} días de período de espera.",
  type: "Tipo",
  details: "Detalles",
  create_date: "Fecha",
  fee_min_payout: "Comisión / Pago mínimo",
  status: "Estado",
  action: "Acción",
  active: "Activo",
  on_hold: "En espera ({date})",
  waiting_email_confirmation: "En espera de la confirmación por mail",
  are_you_sure_you_want_to_delete_purse: "¿Estás seguro de que deseas eliminar este bolso?",
  main_purse_can_not_be_deleted:
    "No se puede quitar el bolso principal. Selecciona el monedero verificado y configúralo como el principal para poder quitar todo el resto",
  add_new: "Enviar",
  add_new_payout_details: "Enviar nuevos detalles del pago",
  payout_system: "Sistema de pago",
  month: "Mes",
  year: "Año",
  first_name: "Nombre",
  last_name: "Apellido",
  currency: "Tipo de moneda",
  iban: "IBAN",
  bank_name: "Nombre del banco",
  set_as_main: "Eligir como bolso principal",
  delete_wallet: "Quitar",
  no_purses: "Todavía no has añadido ninguna clase",
  confirming_purse: "Proceso de verificación, por favor espera...",
  url_is_invalid: "El enlace no es válido o se ha caducado. Por favor, inténtalo de nuevo",
  purse_is_confirmed: "Verificación de detalles de pago ha sido completada correctamente",
  available_withdrawal: "Disponible para pago: {amount} USD",
  new_request: "Nueva consulta",
  amount_in_hold: "Cantidad en espera",
  what_is_hold: "¿qué significa espera?",
  files: "Archivos",
  referrals: "Referencias",
  sites_referrals: "Referencias del sitio",
  file_copies: "Copias de archivos",
  request_payout: "Solicitar pago",
  send_request: "Enviar solicitud",
  request_payout_amount_field_label: "Cantidad",
  Fee: "Tasa",
  Min_payout_is: "Pago mínimo",
  exchange_by_rates: "Tipo de cambio USD/RUB del Banco de Rusia",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "¿Está seguro de que deseas eliminar esta solicitud?",
  request_payout_errors: {
    purse_is_on_hold_one:
      "Los detalles de pago han sido cambiados. La nueva solicitud se puede enviar después de {date}",
    purse_is_on_hold_many:
      "Los detalles de pago han sido cambiados. La nueva solicitud puede ser enviada después de {date}" +
      " o establecer un monedero verificado en Ajustes"
  },
  payouts: "Pagos",
  payouts_subtitle:
    "Las solicitudes de pago suelen procesarse en un promedio de 7 días, " +
    "pero en casos de baja demanda, el tiempo de procesamiento puede ser incluso más rápido.",
  payout_table: {
    fields: {
      created_date: "Fecha",
      amount: "Cantidad",
      payment_details: "Detalles del bolso",
      status: "Estado",
      action: "Acción"
    }
  },
  payouts_status_label: {
    created: "Creado",
    review: "En proceso",
    process: "Procesando pago",
    accepted: "Hecho",
    rejected: "Rechazado",
    deleted: "Eliminado"
  },
  submit_payouts_details: "Guardar"
};
