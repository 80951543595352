export default {
  summary: "Сводка",
  total_referrals: "Всего рефералов",
  first_level: "Первый уровень",
  second_level: "Второй уровень",
  total_earned: "Всего заработано",
  total_earned_value: "${value}",
  detailed_statistics: "Подробная статистика",
  data_table: {
    fields: {
      user: "Пользователь",
      referral_level: "Уровень реферала",
      registration_date: "Дата регистрации",
      today_earnings: "Доход сегодня",
      weekly_earnings: "Заработок за неделю",
      total_earnings: "Общий доход",
      ref_percent: "Реф. процент"
    }
  },
  earnings: "${value}",
  users_with_earnings: "Пользователи с доходом",
  search_by_user: "Поиск по пользователям",
  referrals_promo_meterials: "Промо-материалы для привлечения рефералов",
  referral_link: "Реферальная пригласительная ссылка",
  referrals_banners: "Баннеры",
  banner_code: "Код баннера"
};
