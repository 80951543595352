export default {
  data_table: {
    fields: {
      date: "Дата",
      email: "Email",
      file: "Файл",
      source: "Источник",
      payment_system: "Способ оплаты",
      tariff: "Тип премиума",
      file_sales: "Доход $",
      site_sales: "Доход от переходов с сайта $",
      status: "Статус*"
    },
    notes: {
      note1: "* Подробную информацию о статусах {hold}, {void} и {refund} можно найти в нашем {faq}",
      status_hold: "Hold",
      status_void: "Void",
      status_refund: "Refund",
      note2: "** В таблице представлены данные за последние два года",
      type_rebill: "- ребилл",
      type_copy: "- на копии файла",
      type_promo: "- уникальная премиум-ссылка и баннеры"
    }
  },
  file_money_type: {
    direct: "Прямая",
    rebill: "Ребилл",
    copy: "На копии файла"
  },
  refresh: "Обновить",
  filters: "Фильтры",
  sites: "Сайты",
  files: "Файлы",
  files_copies: "Копии файла $",
  status: "Статус",
  payment_system: "Способ оплаты",
  all: "Все",
  date_from_placeholder: "Дата начала",
  date_to_placeholder: "Дата окончания",
  promo_banner_or_link: "Промо ссылка",
  site_referral: "Сайты рефералы",
  unknown: "Неизвестно"
};
