export default {
  data_table: {
    fields: {
      date: 'التاريخ',
      email: 'البريد الإلكتروني',
      file: 'الملف',
      source: 'المصدر',
      payment_system: 'طريقة الدفع',
      tariff: 'نوع العضوية',
      file_sales: 'الإيرادات $',
      site_sales: 'إيرادات إحالات الموقع $',
      status: 'الحالة*'
    },
    notes: {
      note1: '* يرجى قراءة المزيد عن حالات {hold} و {void} و {refund} في {faq} الخاص بنا',
      status_hold: 'معلق',
      status_void: 'لاغٍ',
      status_refund: 'إسترداد',
      note2: '** يعرض الجدول بيانات عن السنتين الماضيتين',
      type_rebill: '- إعادة الدفع',
      type_copy: '- من نسخة الملف',
      type_promo: '- رابط ترويجي أو بانر'
    }
  },
  file_money_type: {
    direct: 'مباشر',
    rebill: 'إعادة الإصدار',
    copy: 'عبر نسخة الملف'
  },
  refresh: 'تحديث',
  filters: 'الفلاتر',
  sites: 'المواقع',
  files: 'الملفات',
  files_copies: 'الملفات المنسوخة $',
  status: 'الحالة',
  payment_system: 'طريقة الدفع',
  all: 'الكل',
  date_from_placeholder: 'تاريخ البدء',
  date_to_placeholder: 'تاريخ الانتهاء',
  promo_banner_or_link: 'رابط ترويجي',
  site_referral: 'إحالات الموقع',
  unknown: 'غير معروف'
};