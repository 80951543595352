export default {
  payout_details: "Auszahlung Details",
  payout_details_subtitle:
    "Aus Sicherheitsgründen müssen neue und aktualisierte Auszahlungsdetails sein " +
    "per E-Mail überprüft und in Kraft treten nach {days} Tagen Wartezeit.",
  type: "Typ",
  details: "Details",
  create_date: "Datum",
  fee_min_payout: "Gebühr / Min. Auszahlung",
  status: "Status",
  action: "Aktion",
  active: "Aktiv",
  on_hold: "Wartezeit ({date})",
  waiting_email_confirmation: "Warte auf E-Mail-Bestätigung",
  are_you_sure_you_want_to_delete_purse: "Bist Du sicher, dass Du diesen Server entfernen möchtest?",
  main_purse_can_not_be_deleted:
    "Der Haupt-Geldbeutel kann nicht entfernt werden. Wähle den verifizierten Geldbeutel aus und setze  den als den Haupt-Geldbeutel, um den Rest entfernen zu können",
  add_new: "Bestätige",
  add_new_payout_details: "Neue Auszahlungsdetails senden",
  payout_system: "Auszahlungssystem",
  month: "Monat",
  year: "Jahr",
  first_name: "Vorname",
  last_name: "Nachname",
  currency: "Währung",
  iban: "IBAN",
  bank_name: "Name der Bank",
  set_as_main: "Als Titelbild einstellen",
  delete_wallet: "Entfernen",
  no_purses: "Es wurde bis jetzt keine Position hinzugefügt",
  confirming_purse: "Sendet, bitte warten...",
  url_is_invalid: "URL ist ungültig oder abgelaufen. Bitte erneut versuchen",
  purse_is_confirmed: "Überprüfung der Auszahlungsdetails erfolgreich abgeschlossen",
  available_withdrawal: "Für Auszahlung verfügbar: {amount} USD",
  new_request: "Neue Anfrage",
  amount_in_hold: "Betrag auf Halten",
  what_is_hold: "was hält man bereit?",
  files: "Dateien",
  referrals: "Empfehlungen",
  sites_referrals: "Website-Empfehlungen",
  file_copies: "Kopien der Dateien",
  request_payout: "Auszahlung anfordern",
  send_request: "Anfrage absenden",
  request_payout_amount_field_label: "Betrag",
  Fee: "Gebühr",
  Min_payout_is: "Min. Auszahlungsbetrag",
  exchange_by_rates: "Wechselkurs USD/RUB durch die Russische Bank",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Bist Du sicher, dass Du es löschen möchtest?",
  request_payout_errors: {
    purse_is_on_hold_one: "Auszahlungsdetails wurden geändert. Neue Anfrage kann nach {date} eingereicht werden",
    purse_is_on_hold_many:
      "Auszahlungsdetails wurden geändert. Neue Anfrage kann nach {date} eingereicht werden" +
      " oder den verifizierten Geldbeutel in den Einstellungen festlegen"
  },
  payouts: "Auszahlungen",
  payouts_subtitle:
    "Zahlungsanfragen werden in der Regel innerhalb von durchschnittlich 7 " +
    "Tagen bearbeitet, bei geringer Nachfrage kann die Bearbeitungszeit jedoch sogar noch kürzer sein.",
  payout_table: {
    fields: {
      created_date: "Datum",
      amount: "Betrag",
      payment_details: "Geldbeutel Details",
      status: "Status",
      action: "Aktion"
    }
  },
  payouts_status_label: {
    created: "Erstellt",
    review: "In Arbeit …",
    process: "Zahlungsabwicklung",
    accepted: "Erledigt",
    rejected: "Abgelehnt",
    deleted: "Gelöscht"
  },
  submit_payouts_details: "Speichern"
};
