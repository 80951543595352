export default {
  sites_referrals: 'Referencias del sitio',
  sites_referrals_subheader: 'Obtenga la comisión de los ingresos de cada compra realizada por el usuario que se remitió desde tu sitio ',
  data_table: {
    fields: {
      site: 'Sitio',
      visits: 'Visitas',
      sales: 'Compras',
      percent_from_sale: '% de la compra',
      today_earnings: 'Ganados hoy',
      weekly_earnings: 'Ganancias Semanales',
      total_earnings: 'Total de ganancias',
      status: 'Estado',
      action: 'Acción'
    }
  },
  earnings: '${value}',
  activate: 'Activar',
  add_site: 'Enviar tu sitio',
  status: {
    active: 'Activo',
    created: 'No verificado',
    banned: 'Bloqueado',
    deleted: 'Eliminado'
  },
  enter_site_url: 'Especifica el enlace de tu sitio',
  site_url_placeholder: 'https://example.com',
  enter_valid_url: 'Dirección de enlace inválida',
  option_1: 'Opción #1',
  option_1_text: 'Coloca el archivo {file} con tu nombre de usuario en el directorio raíz de tu sitio.{br}' + 'El archivo debe estar disponible en este enlace {url}.',
  option_2: 'Opción #2',
  option_2_text: 'Coloca una etiqueta META especial entre las <head> y </head> de tu sitio.',
  verify_ownership_of_site: 'Verificar la propiedad a través del sitio',
  site_configuration: 'Configuración del sitio',
  site_configuration_line_1: 'Si tu sitio utiliza el protocolo HTTPS:',
  site_configuration_line_2: 'Coloca una etiqueta META adicional para todas las páginas del sitio que contengan vínculos de archivos ' + ' para archivos - {code} (<a href=\'{link}\' target=\'_blank\'>leer más sobre</a>); ',
  site_configuration_line_3: '- Asegúrate de que tus enlaces <strong>no contengan</strong> <code style="font-size: 14px;">' + 'rel="noopener noreferrer nofollow"</code> atributos' + ' (eliminarlo para todos los enlaces de archivos). ',
  site_configuration_line_4: 'Estos ajustes <strong> son absolutamente seguros</strong> y nos permitirán identificar correctamente tu sitio. ',
  premium_sale_promo_materials: 'Materiales promocionales para el sitio',
  premium_sale_promo_materials_new_year: 'Materiales promocionales de Año Nuevo para el sitio',
  promo_link_and_banners: 'Enlace y banners premium únicos',
  use_link_to_get_commision: 'Utiliza este enlace y banners para realizar ventas directas y obtener {value}% de comisión',
  premium_sale_link: 'Enlace único para ventas premium',
  premium_sale_banners: 'Banners de venta Premium',
  download_psd: 'Puede descargar <a href=\'{link}\' target=\'_blank\'>.PSD archivos </a> originales ' + 'y traducir banner a tu idioma, o cambiarle el texto. ',
  banner_code: 'Código de banner'
};