export default {
  page_header: "Настройки",
  page_subtitle:
    "Управляйте настройками персональной информации, конфиденциальности и безопасности.{br}" +
    "Способы вывода можно изменить на странице {url}",
  payouts: "«Выплаты»",
  personal_info: "Персональные данные",
  change_password: "Изменить пароль",
  security_options: "Параметры безопасности",
  email: "Email",
  first_name: "Имя",
  last_name: "Фамилия",
  contact_details: "Контактные данные",
  contact_details_hint: "Telegram, Skype or any other contact type",
  update_personal_info: "Обновить персональную информацию",
  old_password: "Старый пароль",
  new_password: "Новый пароль",
  new_password_confirm: "Новый пароль (подтвердите)",
  change_password_submit: "Изменить пароль",
  security_code: "Защитный код",
  security_code_description:
    "Дополнительная мера безопасности, которую можно включить по собственному желанию. " +
    "При удалении файлов, запросе средств на вывод или изменении реквизитов выплаты " +
    "система начнет запрашивать подтверждение через Защитный код.",
  use_security_code_checkbox_label: "Включить подтверждение действий посредством защитного кода",
  security_code_field_label: "Защитный код",
  security_code_confirmation_field_label: "Защитный код (подтвердите)",
  security_code_hint:
    "* Будьте осторожны, Защитный код не может быть восстановлен. " +
    "Новый Защитный код вступает в силу через 15 дней, в этот период все операции с денежными средствами " +
    ", реквизитами для вывода и возможность удалять файлов будут заморожены.",
  update_security_options: "Обновить параметры безопасности",
  password_hacking_prevention_system: "Система предотвращения взлома пароля",
  limit_login_attempts_field_label: "Установить лимит неудачных попыток входа",
  limit_login_attempts_field_hint: "После 10 неудачных попыток входа потребуется подтверждение по электронной почте",
  use_captcha_field_label: "Использовать капчу для защиты моей учетной записи",
  use_captcha_field_hint: "Настоятельно рекомендуется включить эту опцию",
  errors: {
    password_not_match_confirmation: "Не совпадает с подтвержденным паролем",
    security_code_not_match_confirmation: "Код безопасности не соответствует подтвержденным требованиям"
  },
  password_changing_notification_sent:
    "Уведомление о смене пароля было отправлено на адрес электронной почты, указанный при регистрации.",
  drop_all_sessions: "Сбросить все сессии",
  current_security_code_field_label: "Введите текущий защитный код"
};
