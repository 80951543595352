export default {
  security_frozen_period_notification: 'Atenção, seu código de segurança foi alterado {changedAt}<br />' + 'Esta ação será permitida após {allowedAt}',
  security_check: 'Verificação de segurança',
  security_code: 'Código de segurança',
  enter_security_code: 'Insira o código de segurança',
  wrong_code: 'Código de segurança incorreto',
  password_changed_successfully: 'Sua senha foi alterada com sucesso!',
  password_changing_url_is_invalid: 'O link para alterar sua senha não é mais válido!',
  sections: {
    dashboard: 'Painel de Controle',
    statistics: 'Estatíticas',
    stats: {
      sources: 'Fontes',
      sales: 'Compras',
      files: 'Arquivos',
      'files-copies': 'Cópias de Arquivos',
      detailed: 'Estatísticas Detalhadas'
    },
    earnings: 'Receita',
    referrals: 'Referências',
    sites: 'Meus sites'
  },
  notifications: 'Notificações',
  view_notifications: 'Ver todas as notificações',
  mark_as_read: 'Marcar como lida',
  messages_and_notifications: 'Mensagens e notificações',
  news: 'Notícias',
  news_and_updates: 'Notícias e atualizações',
  file_manager: 'Gerenciador de arquivos',
  menu: {
    tools: 'Entrar no',
    fd1_login: 'Entrar no turbobit.net',
    fd2_login: 'Entrar no hitfile.net'
  },
  help: 'Ajuda',
  faq: 'Perguntas Frequentes',
  support_center: 'Central de suporte',
  additional_information_faq: 'Informação Adicional (FAQ)',
  activation_in_process: 'Ativação em andamento',
  feedback_link_text: 'Algo não está funcionando? Escreva-nos',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'Carregar arquivo',
    Payouts: 'Pagamentos',
    'Site referals': 'Indicações do site',
    'Downloads and statistics': 'Downloads e estatísticas',
    Purchases: 'Compras',
    'Other questions ': 'Outras perguntas '
  },
  get_started: 'Começar',
  sidebar_nav: {
    dashboard: 'Painel de Controle',
    payouts: 'Pagamentos',
    turbobit_net: 'Turbobit.net',
    statistics: 'Estatíticas',
    detailed: 'Detalhado',
    files: 'Arquivos',
    sales: 'Vendas',
    sources: 'Fontes',
    files_copies: 'Cópias de arquivos',
    earnings: 'Receita',
    gold_partners: 'Parceiros gold',
    my_sites: 'Meus sites',
    referrals: 'Indicações',
    hitfile_net: 'Hitfile.net',
    activate: 'Ativado',
    conditions: 'Condições e planos',
    affiliate_programs: 'Programas de afiliados'
  },
  months: {
    full: {
      1: 'Janeiro',
      2: 'Fevereiro',
      3: 'Março',
      4: 'Abril',
      5: 'Maio',
      6: 'Junho',
      7: 'Julho',
      8: 'Agosto',
      9: 'Setembro',
      10: 'Outubro',
      11: 'Novembro',
      12: 'Dezembro'
    },
    short: {
      1: 'Jan',
      2: 'Fev',
      3: 'Mar',
      4: 'Abr',
      5: 'Mai',
      6: 'Jun',
      7: 'Jul',
      8: 'Ago',
      9: 'Set',
      10: 'Out',
      11: 'Nov',
      12: 'Dez'
    }
  },
  need_security_password: 'Senha da conta obrigatória',
  enter_security_password: 'Por favor, digite a senha',
  wrong_password: 'Senha incorreta',
  password_check: 'Verificação de senha'
};