export default {
  main: {
    periods: {
      "7D": "Последние 7 дней",
      "30D": "Последние 30 дней",
      W: "На этой неделе",
      M: "В этом месяце"
    }
  },
  info_blocks: {
    earnings: {
      title: "Доход",
      today: "доход сегодня",
      stat_volume: "${volume}",
      today_volume: "+${volume}"
    },
    downloads: {
      title: "Скачивания",
      today: "скачиваний сегодня",
      today_volume: "+{volume}"
    },
    sales: {
      title: "Продажи",
      today: "продаж сегодня",
      today_volume: "+{volume}"
    },
    balance: {
      title: "Баланс",
      link: "сумма в Hold",
      volume: "${volume}",
      hold: "${volume}"
    }
  },
  programs: {
    title: "Партнерские программы",
    columns: {
      name: "Проект",
      description: "Условия",
      status: "Статус"
    },
    status: {
      on: "Активен",
      off: "Не активирован"
    },
    conditions: "Условия и планы заработка"
  },
  files_storage_blocks: {
    title: "Срок хранения файла",
    title_link: "узнать больше",
    term: "Ваш текущий срок хранения: <b>{normal_term} дней</b> (<i>" + " <b>{premium_term} дней</b> с премиумом</i>)",
    next_update: "Следующее обновление статуса {next_update}",
    select_unlimit_status: "Получить безлимитный статус",
    unlimit_status_title: "Безлимитный статус хранения файлов",
    description_reminder_1: "Данный статус <b>автоматически получают</b> все партнеры с заработком более 1$ за последние 30 дней и положительной разницей между заработком и стоимостью хранения файлов.",
    description_reminder_2: "<b>Для новичков и старых партнеров временно приостанавливающих работу, мы предусмотрели возможность взять пробный безлимитный статус на 2 месяца.</b>",
    select_period_unlimited_status: "Получить пробный безплатный статус",
    periods: {
      one_month: "На 1 месяц",
      two_month: "На 2 месяца"
    },
    unused_files_blocks: {
      title: "Очистка места",
      table_title: "Файлы без скачиваний и покупок в течение",
      period: "Период*",
      count_files: "Количество",
      size_files: "Размер",
      cost_storage_files: "Стоимость хранения**",
      button_search_and_remove: "Поиск и удаление неиспользуемых файлов",
      first_info: "*Указан полный период, в течение которого не было скачиваний и покупок. Такие файлы не приносят вам дохода и снижают вашу эффективность.",
      second_info: "**Стоимость хранения указана за месяц. Вы ничего не платите за хранение, но данное значение используется для расчета безлимитного статуса и GOLD статуса",
      days: "дней",
      unit_size: "Гб"
    },
    title_description_unlimited_status: "Получить пробный безлимитный статус можно не более чем на 2 месяца в течение одного года",
    send_request: "Получить",
    history_activations: "<b>История активаций</b>",
    date_start: "Дата активации",
    date_end: "Дата окончания",
    period: "Период",
    unlimited_status_not_set: "Безлимитный статус не установлен",
    unlimited_status_set: "Безлимитный статус установлен"
  },
  earnings_table: {
    title: "Доход по месяцам",
    fields: {
      date: "Mесяц",
      money: "Заработано",
      files_size: "Размер файлов (GB)*",
      storage_cost: "Стоимость хранения**"
    },
    last30days_row_title: "последние 30 дней",
    notes: {
      copies: "* Копии файлов не учитываются.",
      storage_cost:
        "** Хранение файлов является бесплатным, не ограниченным по количеству и объему. " +
        "Графа «Стоимость хранения» указывает, в какую сумму\n" +
        " файлообменнику обходится хранение ваших файлов на серверах.",
      unlimited_period:
        "Следовательно, для партнеров с доходом более 1 $ за последние 30 дней " +
        "и положительной разницей между доходом и\n" +
        " стоимостью хранения, время хранения файлов будет не ограничено.",
      calculate_cost:
        "Мы оцениваем пространство на наших серверах в 0,016$ за 1 ГБ в месяц " +
        "исходя из данных величин рассчитывается график «стоимость хранения» разделе статистики."
    }
  },
  earnings_chart: { title: "Доход по проектам" }
};
