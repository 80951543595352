import Vue from "vue";

export default {
  namespaced: true,
  state: {
    securityAction: null,
    onCloseAction: null, // callback при клике на close в модальном окне
    securityMode: null, // both, code, password
    dialog: {
      type: null,
      visible: false,
      title: null,
      message: null,
      closeButtonLabel: null
    },
    snackbar: {
      visible: false,
      text: null,
      timeout: 5000
    },
    confirmDialog: {
      visible: false,
      title: null,
      message: null,
      onCancel: null,
      onConfirm: null
    },
    mainAlert: {
      visible: false,
      type: "info",
      message: null,
      dismissible: true
    },
    unlimitedStatusStoreFilesDialog: {
      visible: false,
      title: null,
      message: null,
      onCancel: null,
      onConfirm: null
    },
    appData: {
      fd1: {
        domain: "turbobit.net",
        alias: "turbobit",
        title: "Turbobit",
        color: "#F14004",
        redirectors: ["trbbt.net", "turb.pw", "turb.to", "turbo.to", "turb.cc"],
        mirrors: ["turbobit.net"]
      },
      fd2: {
        domain: "hitfile.net",
        alias: "hitfile",
        title: "Hitfile",
        color: "#4CA8FF",
        redirectors: ["htfl.net", "hitf.cc", "hitf.to", "hil.to"],
        mirrors: ["hitfile.net"]
      },
      ptr: {
        domain: process.env.VUE_APP_PTR_DOMAIN,
        title: "Costaction"
      }
    }
  },
  mutations: {
    setSecurityAction(state, callback) {
      state.securityAction = callback;
    },
    setOnCloseAction(state, callback) {
      state.onCloseAction = callback;
    },
    setSecurityMode(state, mode) {
      state.securityMode = mode;
    },
    setDialog(state, payload) {
      state.dialog = payload;
    },
    setConfirmDialog(state, payload) {
      state.confirmDialog = payload;
    },
    setMainAlert(state, payload) {
      state.mainAlert = payload;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
  },
  getters: {
    /**
     * Получение короткого доменя для apptype
     * @param state
     */
    redirectorDomain: state => appType => {
      return state.appData[appType]["redirectors"][0];
    },

    /**
     * Получение ссылки на кабинет
     * @param state
     */
    cabinetDomain: state => {
      return window.location.host;
    },

    /**
     * Получение ссылки на файл менеджер
     * @param state
     */
    filemanagerDomain: state => {
      return `filemanager.${state.appData.ptr.domain}`;
    },

    /**
     * Получение домена проекта для apptype
     * @param state
     */
    appDomain: state => appType => {
      return state.appData[appType]["domain"];
    },

    /**
     * Получение названия проекта по apptype
     * @param state
     */
    appTitle: state => appType => {
      return state.appData[appType]["title"];
    },

    appAlias: state => appType => {
      return state.appData[appType]["alias"];
    },

    /**
     * Доступ ко всем имеющимся данным о приложениях
     */
    appsData: state => {
      return state.appData;
    }
  },
  actions: {
    markUserMessagesAsRead() {
      return Vue.axios.post("/messages/read");
    },

    loadSecurityInfo(module, mode) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/security/info/" + mode)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    doSecureAction({ commit }, data) {
      commit("setSecurityAction", data.callback);
      commit("setSecurityMode", data.mode);
      commit("setOnCloseAction", data.onClose);
    },

    checkSecurityCode(module, code) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/security/check-code", { code })
          .then(response => {
            resolve(response.data.success);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    checkSecurityPassword(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/security/check-password", data)
          .then(response => {
            resolve(response.data.success);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },

    showSnackbar({ commit }, payload) {
      payload.visible = true;
      commit("setSnackbar", payload);
    },

    closeSnackbar({ commit, state }) {
      let data = state.snackbar;
      data.visible = false;
      commit("setSnackbar", data);
    },

    showDialog({ commit }, payload) {
      payload.visible = true;
      commit("setDialog", payload);
    },

    closeDialog({ commit }) {
      commit("setDialog", {
        visible: false,
        title: null,
        message: null,
        closeButtonLabel: null
      });
    },

    showConfirmDialog({ commit }, payload) {
      commit("setConfirmDialog", {
        visible: true,
        title: payload.title,
        message: payload.message,
        onCancel: payload.onCancel,
        onConfirm: payload.onConfirm
      });
    },
    closeConfirmDialog({ commit }) {
      commit("setConfirmDialog", {
        visible: false,
        title: null,
        message: null,
        onCancel: null,
        onConfirm: null
      });
    },

    showMainAlert({ commit, state }, data) {
      data.visible = true;
      commit("setMainAlert", Object.assign(state.mainAlert, data));
    },
    hideMainAlert({ commit, state }) {
      commit("setMainAlert", Object.assign(state.mainAlert, { visible: false }));
    },
  }
};
