export default {
  summary: 'Özet',
  total_referrals: 'Toplam yönlendirmeler',
  first_level: 'Birinci seviye',
  second_level: 'İkinci seviye',
  total_earned: 'Kazanılan toplam',
  total_earned_value: '${value}',
  detailed_statistics: 'Detaylı istatistik',
  data_table: {
    fields: {
      user: 'Kullanıcı',
      referral_level: 'Referans Seviyesi',
      registration_date: 'Kayıt tarihi',
      today_earnings: 'Bugün kazanılan',
      weekly_earnings: 'Haftalık kazanç',
      total_earnings: 'Toplam kazanç',
      ref_percent: 'Ref. yüzde'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'Kazançlı kullanıcılar',
  search_by_user: 'Kullanıcıya göre ara',
  referrals_promo_meterials: 'Yönlendirmeler promosyon malzemeleri',
  referral_link: 'Yönlendirme davet bağlantısı',
  referrals_banners: 'Yönlendirme Afişleri',
  banner_code: 'Banner kod'
};