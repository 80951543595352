export default {
  main: {
    periods: {
      '7D': 'Letzte 7 Tage',
      '30D': 'Letzte 30 Tage',
      W: 'Diese Woche',
      M: 'Aktueller Monat'
    }
  },
  info_blocks: {
    earnings: {
      title: 'Einnahmen',
      today: 'Einnahmen heute',
      stat_volume: '${volume}',
      today_volume: '+${volume}'
    },
    downloads: {
      title: 'Downloads',
      today: 'Downloads heute',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'Einkäufe',
      today: 'Einkäufe heute',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'Guthaben',
      link: 'gehaltene Betrag',
      volume: '${volume}',
      hold: '${volume}'
    }
  },
  programs: {
    title: 'Affiliate-Programme',
    columns: {
      name: 'Projekt',
      description: 'Begriffe',
      status: 'Status'
    },
    status: {
      on: 'Aktiv',
      off: 'Nicht aktiviert'
    },
    conditions: 'Bedingungen und Pläne'
  },
  files_storage_blocks: {
    title: 'Datei-Speicherzeitraum',
    title_link: 'mehr erfahren',
    term: 'Ihr Speicherzeitraum: <b>{normal_term} Tage</b> (<i>' + ' <b>{premium_term} Tage</b> mit Premium</i>)',
    next_update: 'Nächste Statusaktualisierung am {next_update}'
  },
  earnings_table: {
    title: 'Einnahmen nach Monaten',
    fields: {
      date: 'Monat',
      money: 'Erhalten',
      files_size: 'Dateigröße (GB)*',
      storage_cost: 'Speicherkosten**'
    },
    last30days_row_title: 'letzte 30 Tage',
    notes: {
      copies: '* Wir berücksichtigen nicht die Kopien von Dateien (physisch und virtuell).',
      storage_cost: '** Dateispeicher ist absolut kostenlos, Speicherplatz ist auch unbegrenzt. Wir verwenden den Graph "Speicherkosten" um zu berechnen' + ' die Datei-Speicherdauer, die zur Verfügung gestellt werden kann.',
      unlimited_period: 'Daher für Partner mit einem Einkommen von mehr als 1 $ in den letzten 30 Tagen ' + 'und eine positive Einkommensdifferenz für\n' + ' Speicherkosten, die Speicherzeit für Dateien ist unbegrenzt.',
      calculate_cost: 'Wir bewerten den Speicherplatz auf unseren Servern bei $0.016 pro 1Gb pro Monat ' + 'und das ist, wie die Grafik "Kosten für den Speicher" berechnet wird.'
    }
  },
  earnings_chart: { title: 'Einnahmen nach Projekten' }
};