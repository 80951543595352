export default {
  email_required: "Email adress is required",
  password_required: "Password is required",
  enter_captcha: "Please pass captcha verification",
  login: "Log in",
  account_was_blocked: "The selected account is active and doesn't require restoration.",
  account_unblocking: "Account unblocking",
  restore: "Restore",
  unblock: "Unblock",
  invalid_link: "Invalid link. Please contact the administrator.",
  account_is_unblocked: "Your account is unblocked. Now you can log in.",
  account_restore_email_sent: "To recover account please follow the link that has been sent to your email address",
  email: "Email",
  password: "Password",
  email_must_be_valid: "The email must be a valid email address",
  forgot_password: "Forgot Password?",
  create_new_account: "Create new account",
  sign_in_to_your_account: "Login to Costaction",
  sign_in: "Sign In",
  agree_with_terms_of_site: "Agree with Terms of Service",
  subscribe_to_newsletter: "Subscribe to newsletter",
  sign_up_now: "Sign up now!",
  required: "Required",
  register_confirm_sent_success: "Please check your inbox, we have sent a confirmation link to your email",
  account_activation: "Account activation",
  activation_in_progress: "Activation in progress, please wait",
  invalid_token: "Your verification link has expired",
  register_success_message: "Your account successfully activated, please login",
  password_recovery: "Password recovery",
  recovering_password: "Recovering password",
  invalid_recover_password_link:
    "This link for new password is not active. Please apply to " +
    "<a href='http://turbobit.net/helpdesk/' target='_blank'>support</a> if you have any difficulties with " +
    "password restore.",
  password_changed_success:
    "Your password was successfully changed. We have sent new password to email. " +
    "Also you can change it to new one.",
  recovery_password_link_sent: "A message with a password reset link has been sent to your email address."
};
