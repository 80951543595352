export default {
  email_required: 'Endereço de e-mail é obrigatório',
  password_required: 'A senha é obrigatória',
  enter_captcha: 'Por favor, faça verificação de captcha',
  login: 'Entrar',
  account_was_blocked: 'A conta selecionada está ativa e não requer restauração.',
  account_unblocking: 'Desbloqueio da conta',
  restore: 'Restaurar',
  unblock: 'Desbloquear',
  invalid_link: 'Link inválido. Por favor, contate o administrador.',
  account_is_unblocked: 'Sua conta foi desbloqueada. Agora você pode entrar.',
  account_restore_email_sent: 'Para recuperar a conta, por favor, siga o link enviado para o seu endereço de e-mail',
  email: 'E-mail',
  password: 'Senha',
  email_must_be_valid: 'Este deve ser um endereço de e-mail válido',
  forgot_password: 'Esqueceu sua Senha?',
  create_new_account: 'Criar nova conta',
  sign_in_to_your_account: 'Entrar para Custos',
  sign_in: 'Iniciar sessão',
  agree_with_terms_of_site: 'Aceitar os Termos de Serviço',
  subscribe_to_newsletter: 'Assinar newsletter',
  sign_up_now: 'Inscreva-se agora!',
  required: 'Obrigatório',
  register_confirm_sent_success: 'Por favor, verifique a sua caixa de entrada, enviamos um link de confirmação para seu e-mail',
  account_activation: 'Ativação de conta',
  activation_in_progress: 'Ativação em andamento, por favor aguarde',
  invalid_token: 'Seu link de verificação expirou',
  register_success_message: 'Sua conta foi ativada com sucesso, por favor faça login',
  password_recovery: 'Recuperação de senha',
  recovering_password: 'Recuperando a senha',
  invalid_recover_password_link: 'Este link para a nova senha não está ativo. Por favor, aplique a ' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>support</a> se você tiver algum problema com ' + 'recuperação de senha.',
  password_changed_success: 'Sua senha foi alterada com sucesso. Nós enviamos uma nova senha para o e-mail. ' + 'Você também pode alterá-la para uma nova.',
  recovery_password_link_sent: 'Uma mensagem com um link de redefinição de senha foi enviada para seu endereço de e-mail.'
};