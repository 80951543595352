export default {
  data_table: {
    fields: {
      date: 'Datum',
      email: 'Email',
      file: 'Datei',
      source: 'Quelle',
      payment_system: 'Zahlungsart',
      tariff: 'Mitgliedschaftstyp',
      file_sales: 'Einnahmen $',
      site_sales: 'Website Empfehlungen Einnahmen $',
      status: 'Status*'
    },
    notes: {
      note1: '* Bitte lesen Sie mehr über {hold}, {void} und {refund} Status in unseren {faq}',
      status_hold: 'Halten',
      status_void: 'Storniert',
      status_refund: 'Rückerstattung',
      note2: '** Die Tabelle zeigt die Daten der letzten zwei Jahre an',
      type_rebill: '- Nachrechnung',
      type_copy: '- von Datei-Kopie',
      type_promo: '- Promo-Link oder Banner'
    }
  },
  file_money_type: {
    direct: 'Direkt',
    rebill: 'Nachladen',
    copy: 'Per Dateikopie'
  },
  refresh: 'Aktualisieren',
  filters: 'Filter',
  sites: 'Websites',
  files: 'Dateien',
  files_copies: 'Kopien der Dateien $',
  status: 'Status',
  payment_system: 'Zahlungsart',
  all: 'Alle',
  date_from_placeholder: 'Anfangsdatum',
  date_to_placeholder: 'Abschlussdatum',
  promo_banner_or_link: 'Promo-Link',
  site_referral: 'Website-Empfehlungen',
  unknown: 'Unbekannt'
};