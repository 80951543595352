export default {
  site_name: 'CostAction.com - جمع أفضل برامج الشركاء في مكان واحد',
  dashboard: 'لوحة المعلومات',
  earnings: 'الأرباح',
  page_not_found: 'لم يتم العثور على الصفحة',
  faq: 'الأسئلة الأكثر شيوعاً',
  get_started: 'بدأ الإستخدام',
  gold: 'الشركاء الذهبيين',
  news: 'ألأخبار',
  notifications: 'الإشعارات',
  payouts: 'المدفوعات',
  referrals: 'الإحالات',
  rates: 'الشروط والخطط',
  settings: 'الإعدادات',
  sites: 'المواقع',
  stats_sources: 'المصادر',
  stats_sales: 'المبيعات',
  stats_files_copies: 'نسخ الملفات',
  stats_files: 'الملفات',
  stats_detailed: 'إلاحصائيات المفصلة',
  sign_in: 'تسجيل الدخول',
  create_new_account: 'انشاء حساب جديد',
  restore_access: 'استعادة الوصول',
  confirm_registration: 'تأكيد التسجيل',
  purse_confirmation: 'تأكيد المحفظة',
  password_recovery: 'استعادة كلمة المرور'
};