export default {
  payout_details: "Реквизиты для вывода",
  payout_details_subtitle:
    "По соображениям безопасности все новые (и обновленные) реквизиты должны быть " +
    "подтверждены по электронной почте и станут доступны к использованию после {days} дней.",
  type: "Тип",
  details: "Реквизиты",
  create_date: "Дата",
  fee_min_payout: "Комиссия / Мин. выплата",
  status: "Статус",
  action: "Действие",
  active: "Активно",
  on_hold: "Холд ({date})",
  waiting_email_confirmation: "Ожидание подтверждения по электронной почте",
  are_you_sure_you_want_to_delete_purse: "Вы уверены, что хотите удалить этот кошелек?",
  main_purse_can_not_be_deleted:
    "Основной кошелек не может быть удален. " +
    "Выберите подтвержденный кошелек и сделайте его основным, чтобы удалить другие",
  add_new: "Добавить новые реквизиты",
  add_new_payout_details: "Добавить новые данные для вывода",
  payout_system: "Платежная система",
  month: "Mесяц",
  year: "Год",
  first_name: "Имя",
  last_name: "Фамилия",
  currency: "Валюта",
  iban: "IBAN",
  bank_name: "Наименование банка",
  set_as_main: "Сделать основным кошельком",
  delete_wallet: "Удалить",
  no_purses: "Вы ещё не добавили ни одного способа для вывода",
  confirming_purse: "Подтверждение в процессе. Пожалуйста, ожидайте...",
  url_is_invalid: "URL недействителен или устарел. Пожалуйста, попробуйте еще раз",
  purse_is_confirmed: "Проверка реквизитов для выплаты успешно завершена",
  available_withdrawal: "Доступно для вывода: {amount} USD",
  new_request: "Заказать выплату",
  amount_in_hold: "Сумма в Hold",
  what_is_hold: "что такое hold?",
  files: "Файлы",
  referrals: "Рефералы",
  sites_referrals: "Сайты рефералы",
  file_copies: "Копии файлов",
  request_payout: "Запрос на выплату",
  send_request: "Отправить запрос",
  request_payout_amount_field_label: "Сумма",
  Fee: "Комиссия",
  Min_payout_is: "Минимальная сумма",
  exchange_by_rates: "Конвертация USD/RUB по курсу CBR",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Вы уверены, что хотите удалить запрос?",
  request_payout_errors: {
    purse_is_on_hold_one: "Реквизиты для вывода изменены. Новый запрос может быть отправлен после {date}",
    purse_is_on_hold_many:
      "Реквизиты для вывода изменены. Новый запрос может быть отправлен после {date}" +
      " или выберите другой подтвержденный кошелек в Настройках"
  },
  payouts: "Выплаты",
  payouts_subtitle:
    "Выплаты производятся в среднем в течение 7 дней. " +
    "При низком количестве единовременных запросов - быстрее.",
  payout_table: {
    fields: {
      created_date: "Дата",
      amount: "Сумма",
      payment_details: "Реквизиты для вывода",
      status: "Статус",
      action: "Действие"
    }
  },
  payouts_status_label: {
    created: "Создано",
    review: "В процессе",
    process: "В процессе оплаты",
    accepted: "Оплачено",
    rejected: "Отклонено",
    deleted: "Удалено"
  },
  submit_payouts_details: "Сохранить",
  quick_request: "Быстрый заказ",
  quick_request_text: "Создать заявку на вывод всей доступной суммы {amount}$ на основной кошелек {wallet}: {number}",
};
