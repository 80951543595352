export default {
  page_header: 'Paramètres',
  page_subtitle: 'Gérez vos informations, votre confidentialité et vos options de sécurité. Les détails de paiement de{br}peuvent être modifiés sur la page {url}',
  payouts: '«Paiements»',
  personal_info: 'Informations personnelles',
  change_password: 'Changer le mot de passe',
  security_options: 'Options de sécurité',
  email: 'E-mail',
  first_name: 'Prénom',
  last_name: 'Nom',
  contact_details: 'Détails du contact',
  contact_details_hint: 'Télégramme, Skype ou tout autre type de contact',
  update_personal_info: 'Mettre à jour les informations personnelles',
  old_password: 'Ancien mot de passe',
  new_password: 'Nouveau mot de passe',
  new_password_confirm: 'Nouveau mot de passe (confirmation)',
  change_password_submit: 'Changer le mot de passe',
  security_code: 'Code de sécurité',
  security_code_description: 'Une mesure de sécurité supplémentaire qui peut être activée de votre propre chef. ' + 'Lors de la suppression de fichiers, la demande de fonds accumulés ou le changement des détails de paiement ' + 'le système va commencer à demander une confirmation de code de sécurité.',
  use_security_code_checkbox_label: 'Activer la confirmation des actions par code de sécurité',
  security_code_field_label: 'Code de sécurité',
  security_code_confirmation_field_label: 'Code de sécurité (confirmation)',
  security_code_hint: '*Attention, le code de sécurité ne peut pas être restauré. ' + 'Le nouveau code de sécurité entre en vigueur dans 15 jours, durant cette période, toutes les opérations avec des fonds ' + ', les détails de paiement et la suppression de fichiers seront gelés.',
  update_security_options: 'Mettre à jour les options de sécurité',
  password_hacking_prevention_system: 'Système de prévention du piratage du mot de passe',
  limit_login_attempts_field_label: 'Limite de tentatives de connexion infructueuses',
  limit_login_attempts_field_hint: 'Après 10 tentatives infructueuses, une autorisation par e-mail sera requise',
  use_captcha_field_label: 'Activer le captcha pour protéger mon compte',
  use_captcha_field_hint: 'Fortement recommandé pour activer cette option',
  errors: {
    password_not_match_confirmation: 'Ne correspond pas au mot de passe confirmé',
    security_code_not_match_confirmation: 'Le code de sécurité ne répond pas aux exigences confirmées'
  },
  password_changing_notification_sent: 'La notification de la modification du mot de passe a été envoyée à l\'adresse e-mail spécifiée lors de l\'inscription.',
  drop_all_sessions: 'Abandonner toutes les sessions',
  current_security_code_field_label: 'Veuillez entrer votre code de sécurité actuel'
};