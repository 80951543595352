import Vuex from "vuex";
import Vue from "vue";

import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import chat from "./modules/chat";
import themeConfig from "./modules/themeConfig";
import auth from "./modules/auth";
import invoice from "./modules/invoice";
import cart from "./modules/cart";
import verticalSidebar from "./modules/verticalSidebar";
import scrumboard from "./modules/scrumboard";
import lang from "./modules/lang";
import payouts from "./modules/payouts";
import statistics from "./modules/statistics";
import earnings from "./modules/earnings";
import videos from "./modules/videos";
import dashboard from "./modules/dashboard";
import app from "./modules/app";
import helpdesk from "./modules/helpdesk";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    chat,
    themeConfig,
    auth,
    invoice,
    cart,
    verticalSidebar,
    scrumboard,
    lang,
    payouts,
    statistics,
    earnings,
    videos,
    dashboard,
    app,
    helpdesk
  }
});
