export default {
  hints: "Подсказки",
  hint_plus:
    "Файлы, загруженные менее 10 дней назад; скачанные более чем 100 раз; с 1 или более продаж." +
    "Подобные файлы могут только повышать эффективность",
  hint_arrow_up: "Прибыльные и эффективные файлы",
  hint_arrow_down: "Неэффективные и убыточные файлы",
  hint_minus: "Крайне невыгодные файлы, чрезвычайно снижающие вашу эффективность",
  data_table: {
    fields: {
      name: "Имя файла (папка)",
      size: "Размер",
      created_date: "Дата добавления",
      download_count_today: "Скачиваний сегодня",
      download_count_total: "Скачиваний всего",
      video_view_count_today: "Просмотры видео за сегодня",
      video_view_count_total: "Просмотры видео всего",
      money_today: "Доход сегодня",
      money_total: "Всего заработано",
      premium_count_today: "Продажи сегодня",
      premium_count_total: "Всего продаж"
    }
  },
  inbox: "Входящие",
  show_hints: "Отображение подсказок",
  folder: "Папка",
  find_by_name: "Поиск по имени",
  all: "Все",
  detailed_statistics_by_files: "Подробная статистика по файлам",
  // files copies
  detailed_statistics_by_file_copies: "Подробная статистика по копиям файлов"
};
