import Vue from "vue";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language"),
    list: null
  },
  mutations: {
    setLangs: (state, langs) => {
      state.list = langs;
    },
    setLanguage: (state, language) => {
      state.language = language;
    }
  },
  actions: {
    loadLangs({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/langs")
          .then(response => {
            commit("setLangs", response.data.langs);
            resolve(response.data.langs);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    storeLanguage({ commit }, lang) {
      commit("setLanguage", lang);
      if (lang) {
        localStorage.setItem("language", lang);
      }
    }
  }
};
