export default {
  payout_details: "Payout details",
  payout_details_subtitle:
    "Due to security reasons, new and updated payout details must be " +
    "verified by email and come into effect after {days} days hold period.",
  type: "Type",
  details: "Details",
  create_date: "Date",
  fee_min_payout: "Fee / Min payout",
  status: "Status",
  action: "Action",
  active: "Active",
  on_hold: "On hold ({date})",
  waiting_email_confirmation: "Awaiting email confirmation",
  are_you_sure_you_want_to_delete_purse: "Are you sure you want to remove this purse?",
  main_purse_can_not_be_deleted:
    "Main purse cannot be removed. Select verified purse and set it as the main one to be able to remove all the rest",
  add_new: "Add",
  add_new_payout_details: "Submit new payout details",
  payout_system: "Payout system",
  month: "Month",
  year: "Year",
  first_name: "First Name",
  last_name: "Last Name",
  currency: "Currency",
  iban: "IBAN",
  bank_name: "Bank Name",
  set_as_main: "Set as main purse",
  delete_wallet: "Remove",
  no_purses: "You haven't added any purses yet",
  confirming_purse: "Verification in progress. Please wait...",
  url_is_invalid: "URL is invalid or expired. Please try again",
  purse_is_confirmed: "Payout details verification successfully completed",
  available_withdrawal: "Available for payout: {amount} USD",
  new_request: "Request payout",
  amount_in_hold: "Amount on Hold",
  what_is_hold: "what is hold?",
  files: "Files",
  referrals: "Referrals",
  sites_referrals: "Sites-referrals",
  file_copies: "File copies",
  request_payout: "Request payout",
  send_request: "Submit request",
  request_payout_amount_field_label: "Amount",
  Fee: "Fee",
  Min_payout_is: "Min payout is",
  exchange_by_rates: "USD/RUB exchange rate by the CBR",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Are you sure you want to delete request?",
  request_payout_errors: {
    purse_is_on_hold_one: "Payout details have been changed. New request can be submitted after {date}",
    purse_is_on_hold_many:
      "Payout details have been changed. New request can be submitted after {date}" +
      " or set verified purse in Settings"
  },
  payouts: "Payouts",
  payouts_subtitle:
    "Payment requests are typically processed within an average of 7 days, " +
    "but in cases of low demand, the processing time may be even quicker.",
  payout_table: {
    fields: {
      created_date: "Date",
      amount: "Amount",
      payment_details: "Purse details",
      status: "Status",
      action: "Action"
    }
  },
  payouts_status_label: {
    created: "Created",
    review: "In progress",
    process: "Payment processing",
    accepted: "Completed",
    rejected: "Rejected",
    deleted: "Deleted"
  },
  submit_payouts_details: "Submit",
  quick_request: "Quick request",
  quick_request_text: "Payment of the full amount of ${amount} to the main wallet {wallet}: {number}"
};
