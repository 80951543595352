<template>
  <div>
    <v-dialog width="700" persistent :value="dialogVisible">
      <v-card>
        <v-card-title class="headline lighten-2">
          {{ $t("app.security_check") }}
        </v-card-title>

        <v-card-text class="pt-4">
          <ca-alert type="info">{{ $t("app.enter_security_code") }}</ca-alert>
          <ca-alert v-if="codeIsWrongError" type="error">{{ $t("app.wrong_code") }}</ca-alert>

          <v-row>
            <v-col class="d-flex" cols="12" sm="12">
              <v-text-field v-model="code" type="password" :label="$t('app.security_code')" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" :loading="saving" @click="save">
            {{ $t("common.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="700" persistent :value="passwordDialogVisible">
      <v-card>
        <v-card-title class="headline lighten-2">
          {{ $t("app.password_check") }}
        </v-card-title>

        <v-card-text class="pt-4">
          <ca-alert type="info">{{ $t("app.enter_security_password") }}</ca-alert>
          <ca-alert v-if="errorMessage" type="error">{{ errorMessage }}</ca-alert>

          <v-row>
            <v-col class="d-flex" cols="12" sm="12">
              <v-text-field v-model="password" type="password" :label="$t('common.password')" />
            </v-col>
          </v-row>

          <recaptcha v-model="captcha" :invalid="true" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn color="primary" :loading="saving" @click="sendPassword">
            {{ $t("common.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CaAlert from "@/views/components/Alert";
import Recaptcha from "@/views/components/Recaptcha";

export default {
  components: { Recaptcha, CaAlert },
  data() {
    return {
      saving: false,
      code: null,
      password: null,
      codeIsWrongError: false,
      errorMessage: false,
      dialogVisible: false,
      passwordDialogVisible: false,
      captcha: null
    };
  },
  computed: {
    securityAction() {
      return this.$store.state.app.securityAction;
    },
    securityMode() {
      return this.$store.state.app.securityMode;
    },
    onCloseAction() {
      return this.$store.state.app.onCloseAction;
    }
  },
  watch: {
    securityAction(val) {
      if (!val) {
        return;
      }

      this.$store.dispatch("app/loadSecurityInfo", this.securityMode).then(securityInfo => {
        if (this.securityMode === "both" || this.securityMode === "code") {
          // операции заморожены
          if (!securityInfo.is_allowed) {
            if (this.onCloseAction) {
              this.onCloseAction();
            }

            this.$store.commit("app/setSecurityAction", null);
            this.$store.dispatch("app/showDialog", {
              type: "error",
              title: this.$t("app.security_check"),
              message: this.$t("app.security_frozen_period_notification", {
                changedAt: securityInfo.code_created_at,
                allowedAt: securityInfo.will_be_allowed_at
              })
            });
            return;
          }

          // требуется код
          if (securityInfo.ask_code) {
            this.dialogVisible = true;
            return;
          }
        }

        if (this.securityMode === "both" || this.securityMode === "password") {
          // требуется пароль
          if (securityInfo.ask_password) {
            this.passwordDialogVisible = true;
            this.captcha = false;
            this.errorMessage = null;
            return;
          }
        }

        // выполняем callback
        this.securityAction();
      });
    }
  },
  methods: {
    save() {
      if (!this.code) {
        this.codeIsWrongError = true;
        return;
      }

      this.saving = true;
      this.codeIsWrongError = false;

      this.$store.dispatch("app/checkSecurityCode", this.code).then(success => {
        if (success) {
          this.securityAction();

          this.$store.commit("app/setSecurityAction", null);
          this.dialogVisible = false;
          this.saving = false;
          this.code = null;
        } else {
          this.codeIsWrongError = true;
          this.saving = false;
        }
      });
    },

    sendPassword() {
      if (!this.password) {
        this.codeIsWrongError = true;
        return;
      }

      this.saving = true;
      this.codeIsWrongError = false;

      const captcha = window.grecaptcha.getResponse();

      this.$store
        .dispatch("app/checkSecurityPassword", {
          password: this.password,
          captcha
        })
        .then(success => {
          if (success) {
            this.securityAction();

            this.$store.commit("app/setSecurityAction", null);
            this.passwordDialogVisible = false;
            this.saving = false;
            this.password = null;
          } else {
            this.errorMessage = this.$t("app.wrong_password");
            this.saving = false;
            this.captcha = false;
          }
        })
        .catch(response => {
          this.saving = false;

          if (response.status === 422) {
            const errors = response.data.errors;

            if (errors.captcha) {
              this.errorMessage = this.$t("auth.enter_captcha");
            } else if (errors.password) {
              this.errorMessage = this.$t("app.wrong_password");
            }

            this.captcha = false;
          }
        });
    },

    close() {
      if (this.onCloseAction) {
        this.onCloseAction();
      }

      this.code = null;
      this.password = null;
      this.saving = false;
      this.codeIsWrongError = false;
      this.dialogVisible = false;
      this.passwordDialogVisible = false;
      this.errorMessage = null;
    }
  }
};
</script>
