export default {
  header_subtitle_1: 'Vielen Dank, dass du uns beigetreten bist! Lass uns in den Prozess der Pflege deines Partnerkontos eintauchen.',
  header_subtitle_2: 'Bitte folge den weiteren Schritten, um alle erforderlichen Informationen zu übermitteln.',
  skip_step: 'Schritt überspringen',
  done: 'Erledigt',
  add_payment_details: 'Einreichungsdetails zur Qualifizierung einer Auszahlungsanfrage senden',
  next_step: 'Nächster Schritt',
  step1: {
    title: 'Fügen Sie Ihre Geldbörse zur Seite «Auszahlungen» hinzu. ',
    description: 'Sie können Systeme wie PayPal, Webmoney, Bitcoin, Payoneer, Qiwi und viele andere nutzen!',
    hint: 'Wenn Sie noch keinen Geldbeutel haben, können Sie diesen Schritt überspringen und später hinzufügen.'
  },
  step2: {
    title: 'turbobit.net- und/oder hitfile.net-Konto aktivieren.',
    description: 'Wählen Sie den Affiliate-Plan aus, indem Sie auf den Tab «Affiliate-Programm» klicken, oder benutzen Sie die Schaltflächen unten:',
    hint: 'Nach der Aktivierung werden alle übermittelten Daten an Ihre E-Mail gesendet.'
  },
  step3: {
    title: 'Affiliate-Plan auswählen',
    description1: 'Erfahren Sie mehr über alle verfügbaren Affiliate-Pläne auf dem ' + 'Seite «Bedingungen und Pläne» und entscheide über die Seite, die am besten zu Dir passt.',
    description2: 'Sie können Ihren Partner-Plan auf der Seite «Einstellungen» für jedes Projekt überprüfen und ändern.',
    hint: 'Der Affiliate-Plan kann einmal in der Woche gewechselt werden.'
  },
  step4: {
    title: ' Laden Sie die Datei auf turbobit.net und/oder hitfile.net hoch ',
    description1: 'Melde Dich mit Deiner aktuellen E-Mail an turbobit.net/hitfile.net an und lade die Dateien hoch.',
    description2: 'Oder wähle den brandneuen «Dateimanager» aus dem Menü «Tools» aus ' + ', um mit Dateien effektiver zu arbeiten (empfohlen).'
  },
  step5: {
    title: 'Glückwunsch, Dein Konto ist fertig. ',
    description1: 'So weiter, lade Dateien hoch und teile sie weiter, um Einnahmen für jeden Download und/oder Verkauf zu generieren, ' + 'je nach ausgewähltem Affiliate-Plan! ',
    description2: 'Bei weiteren Fragen oder Herausforderungen an Support, lese bitte die FAQs oder kontaktiere uns. {url}'
  }
};