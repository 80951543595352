export default {
  data_table: {
    fields: {
      date: 'Tarih',
      email: 'E-posta',
      file: 'Dosya',
      source: 'Kaynak',
      payment_system: 'Ödeme türü',
      tariff: 'Üyelik türü',
      file_sales: 'Gelir $',
      site_sales: 'Site Referans gelirleri $',
      status: 'Durum*'
    },
    notes: {
      note1: '* Lütfen {faq} sayfamızdaki {hold}, {void} ve {refund} durumları hakkında daha fazla bilgi edinin',
      status_hold: 'Beklet',
      status_void: 'Geçersiz',
      status_refund: 'iade',
      note2: '** Tablo son iki yıla ait verileri görüntüler',
      type_rebill: '- yenilenen kazanç',
      type_copy: '- dosya kopyasından',
      type_promo: '- promosyon bağlantısı veya afiş'
    }
  },
  file_money_type: {
    direct: 'Direk',
    rebill: 'Yenilenen kazanç',
    copy: 'Via Dosya kopyasından'
  },
  refresh: 'Yenile',
  filters: 'Filtreler',
  sites: 'Siteler',
  files: 'Dosyalar',
  files_copies: 'Dosya kopyaları $',
  status: 'Durum',
  payment_system: 'Ödeme türü',
  all: 'Tümü',
  date_from_placeholder: 'Başlama tarihi',
  date_to_placeholder: 'Bitiş tarihi',
  promo_banner_or_link: 'Promosyon Linki',
  site_referral: 'Web sitesi referansları',
  unknown: 'Bilinmeyen'
};