<template>
  <v-alert border="right" colored-border :type="type" elevation="2" :dismissible="dismissible">
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  name: "CaAlert",
  props: {
    type: {
      type: String,
      default: "info"
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
