import Vue from "vue";

export default {
  namespaced: true,
  state: {},
  actions: {
    loadSourcesBaseData(module, params) {
      return Vue.axios.get("/statistics/sources/base", { params });
    },
    loadSourcesDetailsData(module, params) {
      return Vue.axios.get("/statistics/sources/details", { params });
    },
    loadSalesData(module, params) {
      return Vue.axios.get("/statistics/sales", { params });
    },
    loadFilesData(module, params) {
      return Vue.axios.get("/statistics/files", { params });
    },
    loadFilesCopiesData(module, params) {
      return Vue.axios.get("/statistics/files-copies", { params });
    },
    loadDetailedTodayData(module, params) {
      return Vue.axios.get("/statistics/detailed/today", { params });
    },
    loadDetailedBaseTableData(module, params) {
      return Vue.axios.get("/statistics/detailed/base", { params });
    },
    loadDetailedCountriesTableData(module, params) {
      return Vue.axios.get("/statistics/detailed/countries", { params });
    }
  }
};
