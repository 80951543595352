import store from "@/store";
import i18n from "./../lang/index";
const axios = require("axios");

// axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ENDPOINT;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;

// if (auth.token()) {
//   axios.defaults.headers.common["Auth-Token"] = auth.token();
// }

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      store.dispatch("auth/logOut");
      window.location.href = "/";
      return;
    }

    if (error.response.status === 500) {
      store.dispatch("app/showDialog", {
        type: "error",
        title: "500 error",
        message: i18n.t("common.try_later")
      });
    }

    if (error.response.status === 403 && error.response.data.message === "operations_are_frozen") {
      store.dispatch("app/showDialog", {
        type: "error",
        title: i18n.tc("app.security_check"),
        message: i18n.tc(
          "app.security_frozen_period_notification",
          {},
          {
            changedAt: store.state.app.securityInfo.code_created_at,
            allowedAt: store.state.app.securityInfo.will_be_allowed_at
          }
        )
      });
    }

    if (error.response.status === 403 && error.response.data.message === "need_security_code") {
      store.dispatch("app/showDialog", {
        type: "error",
        title: i18n.tc("app.security_check"),
        message: i18n.tc("app.need_security_code")
      });
    }

    if (error.response.status === 403 && error.response.data.message === "need_security_password") {
      store.dispatch("app/showDialog", {
        type: "error",
        title: i18n.tc("app.security_check"),
        message: i18n.tc("app.need_security_password")
      });
    }

    // if (process.env.NODE_ENV !== "production") {
    //   console.log("error happened", error.response);
    //
    //   let message = "Error. ";
    //
    //   if (error.response.status === 422) {
    //     message += JSON.stringify(error.response.data);
    //   } else {
    //     message += "Status " + error.response.status + ". " + error.response.statusText;
    //
    //     if (error.response.data && error.response.data.message) {
    //       message += " " + error.response.data.message;
    //     }
    //   }
    //
    //   if (message) {
    //     alert(message);
    //   }
    // }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
