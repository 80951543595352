export default {
  main: {
    periods: {
      "7D": "Last 7 days",
      "30D": "Last 30 days",
      W: "This week",
      M: "This month"
    }
  },
  info_blocks: {
    earnings: {
      title: "Revenue",
      today: "revenue today",
      stat_volume: "${volume}",
      today_volume: "+${volume}"
    },
    downloads: {
      title: "Downloads",
      today: "downloads today",
      today_volume: "+{volume}"
    },
    sales: {
      title: "Purchases",
      today: "purchases today",
      today_volume: "+{volume}"
    },
    balance: {
      title: "Balance",
      link: "amount in hold",
      volume: "${volume}",
      hold: "${volume}"
    }
  },
  programs: {
    title: "Affiliate programs",
    columns: {
      name: "Project",
      description: "Terms",
      status: "Status"
    },
    status: {
      on: "Active",
      off: "Not activated"
    },
    conditions: "Conditions and plans"
  },
  files_storage_blocks: {
    title: "File storage period",
    title_link: "read more",
    term: "Your storage period: <b>{normal_term} days</b> (<i>" + " <b>{premium_term} days</b> with premium</i>)",
    next_update: "Next status update on {next_update}",
    select_unlimit_status: "Get Unlimited Status",
    unlimit_status_title: "Unlimited file storage status",
    description_reminder_1: "This status is <b>automatically granted</b> to all partners who have earned more than $1 in the last 30 days with a positive difference between their earnings and the cost of storing files.",
    description_reminder_2: "<b>For beginners and old partners who temporarily suspend their work, we provide the opportunity to take a trial unlimited status for 2 months.</b>",
    select_period_unlimited_status: "Get a trial unlimited status",
    periods: {
      one_month: "For 1 month",
      two_month: "For 2 months"
    },
    title_description_unlimited_status: "You can get a trial unlimited status for no more than 2 months within one year.",
    history_activations: "<b>Activation history</b>",
    date_start: "Activation date",
    date_end: "End date",
    period: "Period",
    send_request: "Get",
    unlimited_status_not_set: "Unlimited status not set",
    unlimited_status_set: "Unlimited status set"
  },
  unused_files_blocks: {
    title: "Storage Cleanup",
    table_title: "Files without downloads or purchases within",
    period: "Period*",
    count_files: "Quantity",
    size_files: "Size",
    cost_storage_files: "Storage Cost**",
    button_search_and_remove: "Search and Delete Unused Files",
    first_info: "*The indicated period represents the total duration without any downloads or purchases. These files don't generate income for you and only reduce your efficiency.",
    second_info: "**The storage cost is specified per month. You don't pay anything for storage, but this value is used for calculating the unlimited status and GOLD status.",
    days: "days",
    unit_size: "Gigabyte"
  },
  earnings_table: {
    title: "Revenue by months",
    fields: {
      date: "Month",
      money: "Earned",
      files_size: "Files size (GB)*",
      storage_cost: "Storage cost**"
    },
    last30days_row_title: "last 30 days",
    notes: {
      copies: "* We don't take into account the copies of files (physical and virtual).",
      storage_cost:
        '** Files storage is absolutely free, space is also unlimited. We use the "Storage cost" graph to calculate\n' +
        " the files storage period that can be provided.",
      unlimited_period:
        "Therefore, for partners with an income more than 1$ for the past 30 days " +
        "and a positive variance of income to\n" +
        " storage cost, the storage time for files is unlimited.",
      calculate_cost:
        "We evaluate the space on our servers at $0.016 per 1Gb per month " +
        'and that\'s how the graph "cost of the storage" is calculated.'
    }
  },
  earnings_chart: { title: "Income by projects" }
};
