export default {
  hints: 'Astuces',
  hint_plus: 'Fichiers téléchargés il y a moins de 10 jours; Fichiers ayant plus de 100 téléchargements; Fichiers avec un ou plusieurs achats.' + 'Ces fichiers ne peuvent qu\'améliorer votre efficacité',
  hint_arrow_up: 'Fichiers rentables et efficaces',
  hint_arrow_down: 'Fichiers inefficaces et non rentables',
  hint_minus: 'Fichiers extrêmement peu rentables réduisant considérablement votre efficacité',
  data_table: {
    fields: {
      name: 'Nom du fichier (dossier)',
      size: 'Taille',
      created_date: 'Date d\'ajout',
      download_count_today: 'Téléchargements aujourd\'hui',
      download_count_total: 'Total des téléchargements',
      money_today: 'Gain réalisé aujourd\'hui',
      money_total: 'Gain réalisé aujourd\'hui',
      premium_count_today: 'Ventes aujourd\'hui',
      premium_count_total: 'Total des ventes'
    }
  },
  inbox: 'Courrier entrant',
  show_hints: 'Affichage des astuces',
  folder: 'Dossier',
  find_by_name: 'Rechercher par nom',
  all: 'Tout',
  detailed_statistics_by_files: 'Statistiques détaillées sur les fichiers',
  // files copies
  detailed_statistics_by_file_copies: 'Statistiques détaillées sur les copies de fichiers'
};