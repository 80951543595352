export default {
  page_title: "خطط الشروط والأسعار",
  page_subtitle: "إعدادات برنامج الشراكة وخطط الأسعار مع الأوصاف والخيارات للحصول على دخل إضافي.",
  fd1_partners_program_settings: "إعدادات البرنامج الشراكة لـتوربوت",
  fd2_partners_program_settings: "إعدادات البرنامج الشراكة لهيتفيل",
  your_current_plan: "خطة السعر الحالية:",
  rate_plan_from_next_day: "خطة الأسعار المعمول بها في اليوم التالي:",
  change_rate_plan_hint: "هام: يمكنك تغيير خطة الأسعار مرة واحدة في الأسبوع.",
  change_rate_plan: "تقديم التغييرات",
  turbobit_rate_plans: "خطط أسعار توربوبيت",
  hitfile_rate_plans: "خطط أسعار هيتفيل",
  premium_sale: "المبيعات من الدرجة الممتازة (الدفع لكل بيع)",
  rates_for_sale_of_premium_accounts: "أسعار بيع الحساب المتميز (للقبول لتوربو)",
  rates_for_sale_of_premium_accounts_hitfile: "معدلات بيع الحساب المتميز (حق الوصول المميز)",
  country: "البلد",
  percent_of_sale: "% من المبيعات",
  percent_of_rebill: "% من إعادة اصدار الفاتورة",
  all_countries: "جميع الدول",
  mixed: "مختلط (الدفع لكل بيع وتنزيل)",
  mixed_hint: "خطة أسعار المختلطة لتحقيق الدخل من تنزيلات ملفاتك جنبًا إلى جنب مع مبيعات الحسابات المتميزة",
  rates: "المعدلات",
  group: "المجموعة",
  for_1000_downloads: "لـ 1000 تنزيل",
  for_1000_downloads_hint:
    "فقط تحميل الملفات أكبر من 10 ميغابايت يتم حسابها، " + "التنزيلات غير المكتملة. يتم حساب التفرد في سياق الحساب",
  group_and_countries: "المجموعات والبلدان",
  mixed_countries_g: "البلدان الأخرى",
  all_inclusive: "يشمل الكل (الدفع لكل تنزيل)",
  all_inclusive_hint: "خطة الأسعار لتحقيق الدخل عن طريق التنزيلات ، بناءً على حجم الملف",
  rate_for_downloading: "اسغار المعدلات ل 1,000 تنزيل من الملفات الخاصة بك",
  size_country: "الحجم/البلد",
  rate_for_downloading_hint: "التنزيلات غير المكتملة. يتم حساب التفرد في سياق الحساب",
  all_inclusive_countries_d: "البلدان الأخرى",
  additional_earnings: "قم بزيادة دخلك",
  bonus_program: "برنامج المكافآت",
  gold_partners: "الشركاء الذهبيين",
  increased_payments_and_extra_bonuses: "زيادة اسعارالمعدلات والمكافآت الإضافية للشركاء المنتظمين والفعالين",
  increased_payments_and_extra_bonuses_1: "يصل <b>85%</b> من المبيعات لخطة PPS;",
  increased_payments_and_extra_bonuses_2: "حتى <b>60%</b> من المبيعات + 8.75 دولار لكل 1000 تنزيل للخطة المختلطة;",
  increased_payments_and_extra_bonuses_3: "حتى <b>25 $</b> لكل 1000 تنزيل لخطة PPD.",
  for: "لــ",
  site_owners: "أصاحب الموقع",
  for_site_owners_line_1:
    "احصل على <b>+{percents}% من المبيعات التي قام بها المستخدمون المشار إليهم من موقع الويب الخاص بك</b>المدونة، المنتدى، " +
    "صفحة الشبكة الاجتماعية بغض النظر عن مالك الملف.",
  for_site_owners_line_2: "استخدم موقعك ومشاركة روابط توربوبيت " + "لتوليد الدخل الإضافي.",
  referral_system: "نظام الإحالة",
  referral_system_text:
    "الحصول على <b>12%</b> من أرباح الشركاء الجدد منذ اللحظة التي سجلوا فيها مع رابط الإحالة الخاص بك " +
    "في برنامج الشراكة خلال الأشهر الثلاثة القادمة.<br />" +
    "<i>بعد مرور ثلاثة أشهر:</i><br />" +
    "<b>6%</b> من أرباح الشركاء المدعوين يربح في 6 أشهر قادمة؛<br />" +
    "<b>3%</b> من أرباح الشركاء المدعوين بعد 6 أشهر فصاعدا. <br /><br />" +
    "علاوة على ذلك، بسبب نظام الإحالة ذي المستويين، تحصل على 1% من الدخل من " +
    "كل شريك تم عرضه على البرنامج التابع من قبلك. <br />" +
    "إذا لم يكن لديك أي أرباح أخرى باستثناء الدخل الإضافي الذي تحصل عليه من إحالاتك، النسبة المئوية من " +
    "يبدأ دخل نظام الإحالة الإضافي في الانخفاض حتى يصل إلى 0٪. يتم إجراء الفحص شهريًا.",
  price_of_premium_account_for_users: "سعر الحسابات الممتازة للمستخدمين",
  price_of_premium_account_for_users_subtitle:
    "حدد معدلات الأسعار. في وقت لاحق يمكنك أيضا تعيين المعدلات " + "فرديا لكل ملف/مجلد",
  standard_rates: "السعر المعتاد",
  expensive_rates: "معدلات الإنفاق",
  my_price: "السعر الخاص بي",
  make_your_own_price_rates_variation: "قم بتغيير أسعار الأسعار الخاصة بك (اختر حتى {value})",
  free_download_options: "خيارات التنزيل المجانية",
  free_download_options_available_only_on_pps_rate_plan: "إعدادات التحميل المجانية متوفرة فقط لخطة معدل PPS",
  allow_a_free_download_of_all_files: "السماح بالتحميل المجاني لجميع الملفات",
  allow_a_free_download_of_all_files_hint:
    "جميع الملفات من جميع المجلدات ستكون متاحة للتحميل مجاناً. " + "سيتم تجاهل الإعدادات الفردية للملفات والمجلدات.",
  disallow_a_free_download_of_all_files: "عدم السماح بالتحميل المجاني لجميع الملفات",
  disallow_a_free_download_of_all_files_hint:
    "جميع الملفات من جميع المجلدات ستكون متاحة " +
    "التحميل فقط مع الوصول المتميز. سيتم تجاهل الإعدادات الفردية للملفات والمجلدات.",
  use_individual_settings_of_files_and_folders: "إعدادات التحميل الافتراضية للملفات التي تم رفعها حديثا",
  use_individual_settings_of_files_and_folders_hint:
    "توافر تنزيل مجاني يعتمد على الفرد " +
    "الإعدادات التي تم اختيارها يدوياً لمجلد معين أو ملف. وإلا تم تطبيق الإعدادات الافتراضية.",
  inbox_free_download_label: "حدد الإعدادات الافتراضية للملفات التي تم تحميلها مؤخرا إلى مجلد 'صندوق الوارد' الخاص بك:",
  allow_free_download: "السماح بالتحميل المجاني",
  disallow_free_download: "عدم السماح بالتحميل المجاني",
  the_maximum_file_size_available_for_free_downloads: "الحد الأقصى لحجم الملف المتاح للتحميل المجاني",
  the_maximum_file_size_available_for_free_downloads_label:
    "ملفات أكبر من حجم معين لن تكون متاحة " + "للتنزيل المجاني:",
  free_download: { unlimited: "بلا حدود" }
};
