export default {
  today_stat: {
    title: 'Estatística de hoje',
    sub_title: '(atualizada automaticamente a cada quatro horas)',
    refresh: 'Atualizar',
    gold: 'Bônus Gold'
  },
  chart: {
    select: { label: 'Gráfico' },
    title: 'Gráfico diário e filtros'
  },
  table: {
    title: 'Estatísticas detalhadas',
    fields: {
      date: 'Data',
      downloads: 'Downloads',
      sum: 'Soma $',
      avg_sales: 'Média de Vendas $',
      sales: 'Vendas',
      gold: 'Bônus Gold $',
      copies: 'Cópias*',
      total: 'Total',
      avg_download_amount: 'Média por 1000 downloads**'
    }
  },
  sub_table: {
    title: 'Estatísticas detalhadas: {date}',
    fields: {
      countries: 'Países/Grupos',
      sum: 'Soma + Bônus Gold $'
    },
    countries: {
      A: 'Grupo A',
      B: 'Grupo B',
      C: 'Grupo C',
      D: 'Grupo D',
      E: 'Grupo E',
      F: 'Grupo F',
      G: 'Grupo G',
      R: 'Russo',
      U: 'Ucraniano'
    }
  },
  notes: {
    copies: '*Ganhos nos seus arquivos <a href=\'{copies_url}\'>cópias</a>, criado por outros usuários.',
    avg_downloads: '**Coluna \'Média por 1000 downloads\' exibe o valor médio para 1.000 downloads únicos dos seus ' + 'arquivos e compras de acesso premium'
  }
};