export default {
  page_header: 'الإعدادات',
  page_subtitle: 'إدارة المعلومات والخيارات الخاصة بك والخصوصية والأمان. يمكن تعديل تفاصيل الدفع{br}على صفحة {url}',
  payouts: '«المدفوعات»',
  personal_info: 'المعلومات الشخصية',
  change_password: 'تغيير كلمة المرور',
  security_options: '‮خيارات الأمان',
  email: 'البريد الإلكتروني',
  first_name: 'الاسم الأول',
  last_name: 'اللقب',
  contact_details: 'معلومات التواصل',
  contact_details_hint: 'تيليجرام، سكايب أو أي نوع آخر من جهات الاتصال',
  update_personal_info: 'تحديث المعلومات الشخصية',
  old_password: 'كلمة المرور القديمة',
  new_password: 'كلمة المرور الجديدة',
  new_password_confirm: 'كلمة المرور الجديدة (تأكيد)',
  change_password_submit: 'تغيير كلمة المرور',
  security_code: 'رمز الحماية',
  security_code_description: 'إجراء أمني إضافي يمكن تمكينه بموافقتك. ' + 'أثناء حذف الملفات، طلب الأموال المتراكمة أو تغيير تفاصيل الدفع ' + 'سيبدأ النظام في طلب تأكيد رمز الأمان.',
  use_security_code_checkbox_label: 'تمكين تأكيد الإجراءات بواسطة رمز الأمان',
  security_code_field_label: 'رمز الحماية',
  security_code_confirmation_field_label: 'رمز الحماية (تأكيد)',
  security_code_hint: '* كن حذرا، لا يمكن استعادة رمز الأمان. ' + 'يدخل رمز الحماية الجديد حيز التنفيذ في غضون 15 يومًا ، خلال هذه الفترة ، يتم تنفيذ جميع العمليات بالأموال ' + '، سيتم تجميد تفاصيل الدفع وحذف الملفات.',
  update_security_options: 'تم تحديث إعدادات الأمان',
  password_hacking_prevention_system: 'نظام منع قرصنة كلمة المرور',
  limit_login_attempts_field_label: 'حد محاولات تسجيل الدخول غير الناجحة',
  limit_login_attempts_field_hint: 'بعد 10 محاولات غير ناجحة، سوف يتطلب الإذن عبر البريد الإلكتروني',
  use_captcha_field_label: 'تمكين كلمة التحقق لحماية حسابي',
  use_captcha_field_hint: 'نوصي بشدة لتمكين هذا الخيار',
  errors: {
    password_not_match_confirmation: 'لا تتطابق كلمة المرور مع تأكيد كلمة المرور',
    security_code_not_match_confirmation: 'رمز الأمان لا يلبي المتطلبات المؤكدة'
  },
  password_changing_notification_sent: 'تم إرسال إشعار بتغيير كلمة المرور إلى البريد الإلكتروني المحدد أثناء التسجيل.',
  drop_all_sessions: 'إسقاط جميع الجلسات',
  current_security_code_field_label: 'الرجاء إدخال رمز الأمان الحالي الخاص بك'
};