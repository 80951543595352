export default {
  bonus_program: 'برنامج المكافآت',
  gold_partners: '"الشركاء الذهبيين"',
  golden_partner: 'المرتبة الذهبية',
  page_subtitle: 'يمكن للشركاء المنتظمين والفعالين الحصول على <strong>الذهب</strong><br />' + 'الحالات والمحصول على دخل إضافي يصل إلى <strong>+25%</strong> من الإيرادات الحالية.',
  how_to_get_gold_status: 'كيفية الحصول على الحالة الذهبية',
  how_to_get_gold_status_subtitle: 'ستصبح <strong>شريك ذهبي</strong> إذا كان العائد الذي تحصل عليه ' + 'خلال الشهر ألاعلى من "الملفات التي تخزن الأسعار" ينعكس في الصفحة الإحصائية.',
  earnings: 'الأرباح',
  efficiency_is_above: 'الكفاءة أعلى من {value}',
  how_to_get_gold_status_line: 'لتحليل نجاح الأداء، يرجى الانتقال إلى صفحة {link} ' + 'حيث تظهر الإحصاءات والكفاءة خلال الأيام 30 الأخيرة. ' + 'في نهاية الشهر، إذا كان {efficiency} في ' + 'المستوى الصحيح، سيتم الحصول على الحالة {gold_partner} تلقائياً و ' + 'سوف تحصل على مكافأة إضافية في الشهر التالي.',
  how_to_increase_extra_bonus_amount: 'كيفية ترقية الحالة الذهبية وكسب المزيد',
  how_to_increase_extra_bonus_amount_subtitle: 'كما حصلت على الحالة الذهبية، تم تنفيذ النشاط ' + 'سيمنح العملات الافتراضية ، التي تم ترقية حالتك تلقائياً إلى أعلى.',
  how_to_increase_extra_bonus_amount_line_1: 'سيتم الحصول على عملة واحدة إذا كانت الـ 24 ساعة الحالية لديك عدد أو أكثر من التنزيلات التي تم تحميلها سابقا ' + '72 ساعة، لكل فترة 24 ساعة في المتوسط (للتحميلات).',
  how_to_increase_extra_bonus_amount_line_2: 'سيتم الحصول على عملة واحدة إذا كانت الـ 24 ساعة الحالية لديك عدد أو أكثر من التنزيلات التي تم تحميلها سابقا ' + '72 ساعة، لكل فترة 24 ساعة في المتوسط (للتحميلات).',
  how_to_increase_extra_bonus_amount_line_3: 'يتم إزالة عملة واحدة إذا كانت الكفاءة اقل على استمرارية من 0 في آخر 30 يوما',
  comparative_table_of_partners_statuses: 'الحالات والامتيازات',
  comparative_table_of_partners_statuses_subtitle: 'ابقى نشطاً للحصول على المزيد من العملات المعدنية و ' + 'زيادة دخلك. كلما حصلت على عملات أكثر، كلما زادت الحالة = المزيد من الدخل.',
  comparative_table_of_partners_statuses_hint_1: 'مهم! لن تنخفض الحالة حتى لو لم يكن لديك عملات كافية. إذا كان عدد العملات متراكمة ' + 'هو أقل مما يجب أن يكون لوضعك الحالي والوضع قبلها، فقط بعد ذلك قم بالحالة الذهبيية ' + 'الحالة يتم خفضها. <br />على سبيل المثال، إذا كان لديك حالة "سيد الذهب"، فإنه ' + 'يتم تخفيضها فقط بعد أن ينخفض عدد العملات النقدية إلى أقل من 80.',
  comparative_table_of_partners_statuses_hint_2: 'تفقد حالة الشريك الذهبي إذا وجد في نهاية الشهر ' + 'الكفاءة أقل من 0 ولديك 0 عملة.',
  comparative_table_of_partners_statuses_hint_3: 'المشتريات غير الآمنة، مع حالة "الاحتيال" أو "الاسترداد" ' + 'لن يؤخذ في الاعتبار عند تجميع العملات المعدنية.',
  status: 'الحالة',
  efficiency: 'الكفاءة<br />في بداية الشهر',
  coins: 'عدد العملات<br />في نهاية اليوم',
  percent_of_earnings: '% الأرباح'
};