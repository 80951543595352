export default {
  today_stat: {
    title: "Статистика сегодня",
    sub_title: "(обновляется автоматически каждые четыре часа)",
    refresh: "Обновить",
    gold: "Gold бонус"
  },
  chart: {
    select: { label: "Чарт" },
    title: "Ежедневный чарт и фильтры"
  },
  table: {
    title: "Подробная статистика",
    fields: {
      date: "Дата",
      downloads: "Скачивания",
      sum: "Сумма $",
      avg_sales: "Средн продажи $",
      sales: "Продажи",
      gold: "Gold бонус $",
      copies: "Копии*",
      total: "Итого",
      avg_download_amount: "Средн за 1000 скачиваний**"
    }
  },
  sub_table: {
    title: "Подробная статистика за: {date}",
    fields: {
      countries: "Страны/Группы",
      sum: "Сумма + Gold бонус $"
    },
    countries: {
      A: "Группа A",
      B: "Группа B",
      C: "Группа C",
      D: "Группа D",
      E: "Группа E",
      F: "Группа F",
      G: "Группа G",
      R: "Россия",
      U: "Украина"
    }
  },
  notes: {
    copies: "*Заработок на <a href='{copies_url}'>копиях</a>, ваших файлов, сделанных другими пользователями.",
    avg_downloads:
      "**Графа 'Средн за 1000 скачиваний' отображает средний доход за 1000 уникальных скачиваний ваших " +
      "файлов и схожая графа присутствует для анализа заработка на покупках"
  }
};
