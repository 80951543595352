export default {
  site_name: "CostAction.com - Best Affiliate programs assembled in one place",
  dashboard: "Dashboard",
  videos: "Videos",
  earnings: "Earnings",
  page_not_found: "Page not found",
  faq: "FAQ",
  get_started: "Get started",
  gold: "Gold partners",
  news: "News",
  notifications: "Notifications",
  payouts: "Payouts",
  referrals: "Referrals",
  rates: "Conditions and plans",
  settings: "Settings",
  sites: "Sites",
  stats_sources: "Sources",
  stats_sales: "Sales",
  stats_files_copies: "Files copies",
  stats_files: "Files",
  stats_detailed: "Detailed statistics",
  sign_in: "Sign in",
  create_new_account: "Create new account",
  restore_access: "Restore access",
  confirm_registration: "Confirm registration",
  purse_confirmation: "Purse confirmation",
  password_recovery: "Password recovery",
  video_page: "Video"
};
