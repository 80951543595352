export default {
  bonus_program: 'Programa de bônus',
  gold_partners: '"Parceiros gold"',
  golden_partner: 'Status gold',
  page_subtitle: 'Parceiros regulares e eficientes podem ganhar o status <strong>Gold</strong><br />' + 'e recebe uma renda adicional de até <strong>+25%</strong> da receita atual.',
  how_to_get_gold_status: 'Como obter o status Gold',
  how_to_get_gold_status_subtitle: 'Você se tornará <strong>Parceiro Gold</strong> se a receita que você fizer ' + 'durante o mês for mais alto que o preço de armazenamento de arquivos" refletido na página de estatísticas.',
  earnings: 'Ganhos',
  efficiency_is_above: 'eficiência é maior que {value}',
  how_to_get_gold_status_line: 'Para analisar o sucesso realizado, prossiga para a página {link} ' + 'onde as estatísticas e a eficiência dos últimos 30 dias são exibidas. ' + 'No final do mês, se {efficiency} estiver no ' + 'nível adequado, o status {gold_partner} será obtido automaticamente e ' + 'você receberá um bônus adicional no mês seguinte.',
  how_to_increase_extra_bonus_amount: 'Como upgrade para o status gold e ganhar mais',
  how_to_increase_extra_bonus_amount_subtitle: 'Como você obteve o status Gold, a atividade realizada ' + 'irá conceder Moedas virtuais, que fazem automaticamente o upgrade do seu status para a maior.',
  how_to_increase_extra_bonus_amount_line_1: '1 moeda será obtida se nas últimas 24 horas você tiver mais downloads do que as anteriores ' + '72 horas por cada período médio de 24 horas (para Downloads).',
  how_to_increase_extra_bonus_amount_line_2: '1 moeda será obtida se nas últimas 24 horas você tiver mais downloads do que as anteriores ' + '72 horas por cada período médio de 24 horas (Para Vendas).',
  how_to_increase_extra_bonus_amount_line_3: '1 moeda é removida se a eficiência for consistentemente menor que 0 nos últimos 30 dias',
  comparative_table_of_partners_statuses: 'Status e privilégios',
  comparative_table_of_partners_statuses_subtitle: 'Mantenha-se ativo para obter mais moedas e ' + 'aumentar sua renda. Quanto mais moedas você obter, maior o status = mais renda.',
  comparative_table_of_partners_statuses_hint_1: 'Importante! O status não será rebaixado mesmo se você não tiver moedas suficientes. Se o número de moedas acumuladas ' + 'for menor do que é necessário para o seu status atual e o status antes dele, somente assim seu status gold atual ' + 'será rebaixado. <br />Como por exemplo, se você tem o status "Magnata Gold", ele ' + 'será rebaixado somente após o número de moedas cair abaixo dos 80.',
  comparative_table_of_partners_statuses_hint_2: 'Você perde o status de Parceiro Gold se no final do mês ' + 'se a eficiência for inferior a 0 e você tem 0 moedas.',
  comparative_table_of_partners_statuses_hint_3: 'Compras desprotegidas, com o status "fraude" ou "reembolso", ' + 'não serão levadas em conta enquanto as moedas são acumuladas.',
  status: 'Status',
  efficiency: 'Eficiência<br />no início do mês',
  coins: 'Número de moedas<br />no final do dia',
  percent_of_earnings: '% de ganhos'
};