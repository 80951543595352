export default {
  security_frozen_period_notification: 'Attention, votre code de sécurité a été modifié {changedAt}<br />' + 'Cette action sera admise après {allowedAt}',
  security_check: 'Contrôle de sécurité',
  security_code: 'Code de sécurité',
  enter_security_code: 'Entrez le code de sécurité',
  wrong_code: 'Code de sécurité incorrect',
  password_changed_successfully: 'Votre mot de passe a été modifié avec succès!',
  password_changing_url_is_invalid: 'Le lien pour changer votre mot de passe n\'est plus valide !',
  sections: {
    dashboard: 'Tableau de bord',
    statistics: 'Statistiques',
    stats: {
      sources: 'Sources',
      sales: 'Achats',
      files: 'Fichiers',
      'files-copies': 'Copies de fichiers',
      detailed: 'Statistiques détaillées'
    },
    earnings: 'Recettes',
    referrals: 'Parrainages',
    sites: 'Mes sites'
  },
  notifications: 'Notifications',
  view_notifications: 'Voir toutes les notifications',
  mark_as_read: 'Marquer comme lu',
  messages_and_notifications: 'Messages et notifications',
  news: 'Actualités',
  news_and_updates: 'Nouvelles et actualités',
  file_manager: 'Gestionnaire de fichiers',
  menu: {
    tools: 'Se connecter à',
    fd1_login: 'Se connecter à turbobit.net',
    fd2_login: 'Se connecter à hitfile.net'
  },
  help: 'Aide',
  faq: 'FAQ',
  support_center: 'Service d\'assistance',
  additional_information_faq: 'Informations supplémentaires(FAQ)',
  activation_in_process: 'Activation en cours',
  feedback_link_text: 'Quelque chose ne fonctionne pas? Écrivez-nous',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'Chargement de fichier',
    Payouts: 'Paiements',
    'Site referals': 'Références du site',
    'Downloads and statistics': 'Téléchargement et statistiques',
    Purchases: 'Achats',
    'Other questions ': 'D’autres questions '
  },
  get_started: 'Demarrage',
  sidebar_nav: {
    dashboard: 'Tableau de bord',
    payouts: 'Paiements',
    turbobit_net: 'Turbobit.net',
    statistics: 'Statistiques',
    detailed: 'Détaillée',
    files: 'Fichiers',
    sales: 'Ventes',
    sources: 'Sources',
    files_copies: 'Copies de fichiers',
    earnings: 'Revenu',
    gold_partners: 'Partenaires Gold',
    my_sites: 'Mes sites',
    referrals: 'Références',
    hitfile_net: 'Hitfile.net',
    activate: 'Activer',
    conditions: 'Conditions et forfaits',
    affiliate_programs: 'Programmes d\'affiliation'
  },
  months: {
    full: {
      1: 'Janvier',
      2: 'Février',
      3: 'Mars',
      4: 'Avril',
      5: 'Mai',
      6: 'Juin',
      7: 'Juillet',
      8: 'Août',
      9: 'Septembre',
      10: 'Octobre',
      11: 'Novembre',
      12: 'Décembre'
    },
    short: {
      1: 'Janv',
      2: 'Fév',
      3: 'Mars',
      4: 'Avr',
      5: 'Mai',
      6: 'Juin',
      7: 'Juil',
      8: 'Août',
      9: 'Sept',
      10: 'Oct',
      11: 'Nov',
      12: 'Déc'
    }
  },
  need_security_password: 'Mot de passe du compte est requis',
  enter_security_password: 'Veuillez saisir le mot de passe',
  wrong_password: 'Mot de passe incorrect',
  password_check: 'Vérification de mot de passe'
};