export default {
  sites_referrals: 'Site Referansları',
  sites_referrals_subheader: 'Sitenizden yönlendirilen kullanıcı tarafından yapılan her satın alımdan elde edilen gelirin komisyonunu kazanın',
  data_table: {
    fields: {
      site: 'Site',
      visits: 'Ziyaretler',
      sales: 'Satın almalar',
      percent_from_sale: '% Satın almalardan',
      today_earnings: 'Bugün kazanılan',
      weekly_earnings: 'Haftalık kazanç',
      total_earnings: 'Toplam kazanç',
      status: 'Durum',
      action: 'Eylem'
    }
  },
  earnings: '${value}',
  activate: 'Etkinleştir',
  add_site: 'Siteyi gönder',
  status: {
    active: 'Aktif',
    created: 'Doğrulanmadı',
    banned: 'Engellenmiş',
    deleted: 'Silinen'
  },
  enter_site_url: 'Sitenizin URL\'sini belirtin',
  site_url_placeholder: 'https://ornek.com',
  enter_valid_url: 'URL Adresi geçerli değil',
  option_1: 'Seçenek #1',
  option_1_text: 'Oturum açma adınızla birlikte {file} dosyasını sitenizin kök dizinine yerleştirin.{br}' + 'Dosya bu URL tarafından kullanılabilir olmalıdır {url}.',
  option_2: 'Seçenek #2',
  option_2_text: 'Sitenizin <head> ve </head> etiketleri arasına özel bir META etiket yerleştirin.',
  verify_ownership_of_site: 'Site üzerindeki sahipliği doğrulama',
  site_configuration: 'Site yapılandırma',
  site_configuration_line_1: 'Siteniz HTTPS protokolü kullanıyorsa:',
  site_configuration_line_2: '- Dosyalarınız ve sitenizin tüm sayfalarını içeren bağlantılar için ek bir META etiketi ' + ' yerleştirin - {code} (<a href=\'{link}\' target=\'_blank\'>bu konuda daha fazla bilgiyi buradan okuyabilirsiniz</a>); ',
  site_configuration_line_3: '- Bağlantılarınızın <strong> içermediğinden emin olun</strong> <code style="font-size: 14px;">' + 'rel="noopener noreferrer nofollow"</code> kod öz niteliklerini içermediğinden emin olun' + ' (lütfen tüm dosya bağlantıları için silin). ',
  site_configuration_line_4: 'Bu ayarlar <strong>kesinlikle güvenli</strong> ve sitenizi doğru bir şekilde tanımlamamıza izin verecektir. ',
  premium_sale_promo_materials: 'Site için promosyon malzemeleri',
  premium_sale_promo_materials_new_year: 'Site için  Yeni Yıl promosyon malzemeleri',
  promo_link_and_banners: 'Benzersiz premium bağlantı ve afişler',
  use_link_to_get_commision: 'Doğrudan satış yapmak ve % {value} komisyon kazanmak için bu bağlantıyı ve afişleri kullanın',
  premium_sale_link: 'Prim satışı için benzersiz bağlantı',
  premium_sale_banners: 'Premium satış afişleri',
  download_psd: 'Indirebilirsiniz <a href=\'{link}\' target=\'_blank\'>.Orjinal PSD</a> dosyaları ' + 've banner\'ı dilinize çevirin veya içindeki metni değiştirin. ',
  banner_code: 'Banner kod'
};