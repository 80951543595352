export default {
  main: {
    periods: {
      '7D': 'Últimos 7 dias',
      '30D': 'Últimos 30 dias',
      W: 'Esta semana',
      M: 'Este mês'
    }
  },
  info_blocks: {
    earnings: {
      title: 'Receita',
      today: 'receita de hoje',
      stat_volume: '${volume}',
      today_volume: '+${volume}'
    },
    downloads: {
      title: 'Downloads',
      today: 'downloads de hoje',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'Compras',
      today: 'compras de hoje',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'Saldo',
      link: 'valor retido',
      volume: '${volume}',
      hold: '${volume}'
    }
  },
  programs: {
    title: 'Programas de afiliados',
    columns: {
      name: 'Projeto',
      description: 'Termos',
      status: 'Status'
    },
    status: {
      on: 'Ativo',
      off: 'Não ativado'
    },
    conditions: 'Condições e planos'
  },
  files_storage_blocks: {
    title: 'Período de armazenamento de arquivos',
    title_link: 'saiba mais',
    term: 'Seu período de armazenamento: <b>{normal_term} dias</b> (<i>' + ' <b>{premium_term} dias</b> com premium</i>)',
    next_update: 'Próxima atualização de status em {next_update}'
  },
  earnings_table: {
    title: 'Receita por meses',
    fields: {
      date: 'Mês',
      money: 'Ganhos',
      files_size: 'Tamanho dos arquivos (GB)*',
      storage_cost: 'Custo de armazenamento**'
    },
    last30days_row_title: 'últimos 30 dias',
    notes: {
      copies: '* Não levamos em conta as cópias dos arquivos (físicos e virtuais).',
      storage_cost: '** O armazenamento de arquivos é absolutamente gratuito, o espaço também é ilimitado. Usamos o gráfico de "Custo de armazenamento" para calcular\n' + ' o período de armazenamento de arquivos que pode ser fornecido.',
      unlimited_period: 'Portanto, para parceiros com rendimentos superiores a 1$ nos últimos 30 dias ' + 'e uma variação positiva de renda até\n' + ' custo de armazenamento, o tempo de armazenamento dos arquivos é ilimitado.',
      calculate_cost: 'Nós avaliamos o espaço em nossos servidores de $0,016 por 1GB por mês ' + 'e é assim que o gráfico do "custo do armazenamento" é calculado.'
    }
  },
  earnings_chart: { title: 'Rendimento por projetos' }
};