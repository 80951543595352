export default {
  bonus_program: "Bonus program",
  gold_partners: '"Gold Partners"',
  golden_partner: "Gold status",
  page_subtitle:
    "Regular and effective partners can obtain <strong>Gold</strong><br />" +
    "status and receive an additional income up to <strong>+25%</strong> of current revenue.",
  how_to_get_gold_status: "How to get Gold status",
  how_to_get_gold_status_subtitle:
    "You will become <strong>Gold Partner</strong> if the revenue you make " +
    'during the month is higher than "files storing price" reflected on the statistic page.',
  earnings: "Earnings",
  efficiency_is_above: "efficiency is higher than {value}",
  how_to_get_gold_status_line:
    "To analyze performed successfulness, please proceed to the {link} page " +
    "where the statistics and efficiency for the last 30 days are displayed. " +
    "At the end of the month, if {efficiency} is at the " +
    "proper level, the status {gold_partner} will be obtained automatically and " +
    "you will receive an additional bonus the following month.",
  how_to_increase_extra_bonus_amount: "How to upgrade gold status and earn more",
  how_to_increase_extra_bonus_amount_subtitle:
    "As you have obtained Gold status, performed activity " +
    "will grant virtual Coins, which automatically upgrade your status to the higher one.",
  how_to_increase_extra_bonus_amount_line_1:
    "1 coin will be obtained if the current 24 hours you have as many or more downloads as for the previous " +
    "72 hours, per each average 24-hour period (for Downloads).",
  how_to_increase_extra_bonus_amount_line_2:
    "1 coin will be obtained if the current 24 hours you have as many or more downloads as for the previous " +
    "72 hours, per each average 24-hour period (for Sales).",
  how_to_increase_extra_bonus_amount_line_3:
    "1 Coin gets removed if efficiency is consistently less than 0 in the last 30 days",
  comparative_table_of_partners_statuses: "Statuses and privileges",
  comparative_table_of_partners_statuses_subtitle:
    "Stay active to get more coins and " +
    "increase your income. The more coins you obtain, the higher the status = more income.",
  comparative_table_of_partners_statuses_hint_1:
    "Important! Status won't downgrade even if you don't have enough coins. If the number of accumulated coins " +
    "is lower than it has to be for your current status and the status before it, only then does your current gold " +
    'status get downgraded. <br />As an example, if you have had "Gold Magnate" status, it ' +
    "gets downgraded only after the number of coins falls below 80.",
  comparative_table_of_partners_statuses_hint_2:
    "You lose Gold Partner status if at the end of the month " + "efficiency is below 0 and you have 0 coins.",
  comparative_table_of_partners_statuses_hint_3:
    'Unsecure purchases, with the "fraud" or "refund" status, ' +
    "will not be taken into account while accruing the coins.",
  status: "Status",
  efficiency: "Efficiency<br />at the beginning of the month",
  coins: "Number of coins<br />at the end of the day",
  percent_of_earnings: "% of earnings"
};
