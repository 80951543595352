export default {
  subTitle: "(подробнее о видео)",
  today_stat: {
    title: "Статистика сегодня",
    sub_title: "(обновляется автоматически каждые четыре часа)",
    refresh: "Обновить",
    views: "Просмотры",
    viewers: "Зрители",
    new_videos: "Новых видео",
    sales: "Продажи",
  },
  chart: {
    title: "Ежедневный чарт и фильтры",
    target_select: {
      label: "Показатель",
      views: "Просмотры",
      viewers: "Уник. зрители",
      sales: "Продажи",
    },
    player_select: {
      label: "Тип плеера",
      all: "Все",
      embed: "embed",
      on_site: "on_site",
      full: "full",
      demo: "demo"
    }
  },
  filters: {
    title: "Показатель дохода",
    date_from: "Дата начала",
    date_to: "Дата окончания",
    group_by: "Группировать по",
    group: {
      day: "Дням",
      week: "Неделям",
      month: "Месяцам"
    },
    switcher: "Фильтры"
  },
  table: {
    title: "Подробная статистика",
    fields: {
      date: "Дата",
      video_files: "Видео-файлов",
      views: "Просмотры*",
      viewers: "Уник. зрителей*",
      viewers_embed: "Уник. зрители (embed)",
      sales: "Продажи",
      sum: "Сумма $"
    }
  },
  notes: {
    reference: "* По умолчанию  статистика по <b>просмотрам</b> и <b>зрителям</b> включает все типы плеера:",
    embed: "embed - видео-плеер размещённый вами самостоятельно",
    on_site: "on-site - видео-плеер на странице скачивания turbobit.net",
    full: "full - полноценный плеер, с полным видео, доступный премиум пользователям",
    demo: "demo - бесплатный плеер с коротким видео, доступный обычным пользователям"
  }
};
