<template>
  <v-dialog width="400" persistent :value="confirmDialog.visible">
    <v-card>
      <v-card-title v-if="confirmDialog.title" class="headline grey lighten-2">
        {{ confirmDialog.title }}
      </v-card-title>

      <v-card-text class="pt-4">
        {{ confirmDialog.message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="cancel">
          {{ $t("common.cancel") }}
        </v-btn>
        <v-btn color="danger" text @click="confirm">
          {{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CaConfirmDialog",
  computed: {
    confirmDialog() {
      return this.$store.state.app.confirmDialog;
    }
  },
  methods: {
    confirm() {
      if (this.confirmDialog.onConfirm) {
        this.confirmDialog.onConfirm();
      }

      this.$store.dispatch("app/closeConfirmDialog");
    },
    cancel() {
      if (this.confirmDialog.onCancel) {
        this.confirmDialog.onCancel();
      }

      this.$store.dispatch("app/closeConfirmDialog");
    }
  }
};
</script>
