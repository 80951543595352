<template>
  <div v-if="langs && userLang">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-chip v-bind="attrs" style="font-size: 14px;" class="transparent" v-on="on">
          <flag :iso="flagAlias(userLang)" :squared="false" />
        </v-chip>
      </template>
      <v-list>
        <v-list-item v-for="(lang, index) in langs" :key="index" link @click="changeLang(index)">
          <flag :iso="flagAlias(index)" class="mr-2" :squared="false" />
          <v-list-item-title>{{ lang.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "CaLangSelector",
  computed: {
    langs() {
      return this.$store.state.lang.list;
    },
    userLang() {
      const lang = this.$store.state.lang.language;
      return lang != null ? lang : this.$i18n.locale;
    },
    messages() {
      return this.$i18n.locale;
    }
  },
  mounted() {
    if (!this.langs) {
      this.$store.dispatch("lang/loadLangs");
    }
  },
  methods: {
    changeLang(lang) {
      if (this.$store.getters["auth/loggedIn"]) {
        this.$store.dispatch("auth/setUserLang", lang).then(() => {
          this.setLocale(this.$store.state.auth.userData.lang);

          window.location.reload();
        });
      }

      this.setLocale(lang);
    },
    flagAlias(lang) {
      return this.langs[lang].flag;
    }
  }
};
</script>
