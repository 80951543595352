export default {
  cancel: 'Abbrechen',
  confirm: 'Bestätigen',
  close: 'Schließen',
  save: 'Speichern',
  all_fields_are_required: 'alle Felder müssen ausgefüllt werden',
  ok: 'OK',
  loading: 'Ladevorgang...',
  wait: 'Warten...',
  delete: 'Löschen',
  reset: 'Zurücksetzen',
  refresh: 'Aktualisieren',
  continue: 'Fortsetzen',
  skip: 'Überspringen',
  done: 'Erledigt',
  send: 'Absenden',
  filters: 'Filter',
  tables: { total: 'Insgesamt' },
  error: 'Fehler',
  try_later: 'Fehler aufgetreten. Versuche später',
  form: {
    errors: {
      required: '{field} ist ein Pflichtfeld',
      number: 'Bitte geben sie eine gültige Nummer an',
      max: 'Wert muss kleiner als oder gleich {value} sein.',
      min: 'Der Wert muss größer oder gleich 40.00 sein.'
    }
  },
  email: 'E-Mail',
  password: 'Passwort'
};