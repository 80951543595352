export default {
  email_required: 'Adresse e-mail requise',
  password_required: 'Mot de passe requis',
  enter_captcha: 'Veuillez passer la vérification du captcha',
  login: 'Se connecter',
  account_was_blocked: 'Le compte sélectionné est actif et ne nécessite pas de restauration.',
  account_unblocking: 'Déblocage du compte',
  restore: 'Restaurer',
  unblock: 'Débloquer',
  invalid_link: 'Lien invalide. Veuillez contacter l\'administrateur.',
  account_is_unblocked: 'Votre compte est débloqué. Vous pouvez maintenant vous connecter.',
  account_restore_email_sent: 'Pour récupérer votre compte, veuillez suivre le lien qui a été envoyé à votre adresse e-mail',
  email: 'E-mail',
  password: 'Mot de passe',
  email_must_be_valid: 'L\'email doit être une adresse email valide',
  forgot_password: 'Mot de passe oublié ?',
  create_new_account: 'Créer un nouveau compte',
  sign_in_to_your_account: 'Se connecter à Costaction',
  sign_in: 'S\'enregistrer',
  agree_with_terms_of_site: 'Accepter les conditions d\'utilisation',
  subscribe_to_newsletter: 'S\'abonner à la newsletter',
  sign_up_now: 'Inscrivez-vous dès maintenant!',
  required: 'Nécessaire',
  register_confirm_sent_success: 'Veuillez vérifier votre boîte de réception, nous avons envoyé un lien de confirmation à votre adresse e-mail',
  account_activation: 'Activation du compte',
  activation_in_progress: 'Activation en cours, veuillez patienter',
  invalid_token: 'Ce lien de vérification a expiré',
  register_success_message: 'Votre compte a bien été activé, veuillez vous connecter',
  password_recovery: 'Récupération du mot de passe',
  recovering_password: 'Récupérer le mot de passe',
  invalid_recover_password_link: 'Ce lien pour le nouveau mot de passe n\'est pas actif. Veuillez appliquer à ' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>assistance</a> si vous avez des difficultés avec ' + 'récupération du mot de passe.',
  password_changed_success: 'Votre mot de passe a été modifié avec succès. Veuillez utiliser le nouveau mot de passe pour vous connecter. ' + 'Vous pouvez aussi le changer en nouveau.',
  recovery_password_link_sent: 'Un message avec un lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.'
};