export default {
  site_name: 'CostAction.com - En iyi Ortaklık programları tek bir yerde toplandı',
  dashboard: 'Kontrol Paneli',
  earnings: 'Kazançlar',
  page_not_found: 'Sayfa bulunamadı',
  faq: 'SSS',
  get_started: 'Başlayın',
  gold: 'Altın Ortaklar',
  news: 'Haberler',
  notifications: 'Bildirimler',
  payouts: 'Ödemeler',
  referrals: 'Referanslar',
  rates: 'Koşullar ve Tarife planları',
  settings: 'Ayarlar',
  sites: 'Siteler',
  stats_sources: 'Kaynaklar',
  stats_sales: 'Satışlar',
  stats_files_copies: 'Dosya kopyaları',
  stats_files: 'Dosyalar',
  stats_detailed: 'Ayrıntılı istatistikler',
  sign_in: 'Giriş yap',
  create_new_account: 'Yeni Hesap Oluşturun',
  restore_access: 'Erişimi geri yükle',
  confirm_registration: 'Kaydı onaylayın',
  purse_confirmation: 'Cüzdan onayı',
  password_recovery: 'Şifre kurtarma'
};