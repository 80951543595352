export default {
  summary: 'Resumen',
  total_referrals: 'Total de referencias',
  first_level: 'Primer nivel',
  second_level: 'Segundo nivel',
  total_earned: 'Total ganado',
  total_earned_value: '${value}',
  detailed_statistics: 'Estadística detallada',
  data_table: {
    fields: {
      user: 'Usuario',
      referral_level: 'Nivel de referencia',
      registration_date: 'Fecha de registro',
      today_earnings: 'Ganados hoy',
      weekly_earnings: 'Ganancias Semanales',
      total_earnings: 'Total de ganancias',
      ref_percent: 'Porcentaje de Ref.'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'Usuarios con ganancias',
  search_by_user: 'Buscar por Usuario',
  referrals_promo_meterials: 'Materiales promo de los referidos',
  referral_link: 'Enviar enlace de invitación',
  referrals_banners: 'Banners de Referencias',
  banner_code: 'Código de banner'
};