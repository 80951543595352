export default {
  header_subtitle_1: 'Obrigado por se juntar a nós! Vamos mergulhar no processo de manter a conta do seu parceiro.',
  header_subtitle_2: 'Por favor, siga os passos abaixo para enviar todas as informações obrigatórias.',
  skip_step: 'Pular etapa',
  done: 'Concluído',
  add_payment_details: 'Enviar detalhes da carteira para qualificar uma solicitação de pagamento',
  next_step: 'Próxima etapa',
  step1: {
    title: 'Adicione os detalhes da sua carteira à página «Pagamentos». ',
    description: 'Você pode usar sistemas como PayPal, Webmoney, Bitcoin, Payoneer, Qiwi e muitos outros!',
    hint: 'Se você ainda não tem uma carteira, você pode pular esta etapa e adicioná-la mais tarde.'
  },
  step2: {
    title: 'Ative a conta turbobit.net e/ou hitfile.net.',
    description: 'Selecione o plano de afiliados clicando em «Ativar» na aba «Programa de Afiliados», ou use os botões abaixo:',
    hint: 'Após a ativação, todos os dados enviados serão enviados para o seu e-mail.'
  },
  step3: {
    title: 'Selecione o plano de afiliado',
    description1: 'Saiba mais sobre todos os planos de afiliados disponíveis na ' + 'página de «Condições e planos» e decida aquela que é melhor para você.',
    description2: 'Você pode verificar e alterar o seu plano de afiliados na página «Configurações» para cada projeto.',
    hint: 'Plano de afiliado pode ser alterado uma vez por semana.'
  },
  step4: {
    title: ' Envie o arquivo para turbobit.net e/ou hitfile.net ',
    description1: 'Faça o login com seu e-mail atual em turbobit.net/hitfile.net e envie os arquivos.',
    description2: 'Ou selecione o novo «Gerenciador de arquivos» disponível no menu «Ferramentas» ' + 'para operar os arquivos mais efetivamente (recomendado).'
  },
  step5: {
    title: 'Parabéns! Sua conta está pronta. ',
    description1: 'Continue, carregue e compartilhe seus arquivos para gerar receita para cada download ou/e venda, ' + 'de acordo com o plano de afiliado selecionado! ',
    description2: 'Se você tiver outras perguntas ou questões que requerem suporte, leia as Perguntas Frequentes ou entre em contato conosco. {url}'
  }
};