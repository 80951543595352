export default {
  data_table: {
    fields: {
      date: 'Fecha',
      email: 'Correo electrónico',
      file: 'Archivo',
      source: 'Fuente',
      payment_system: 'Forma de Pago',
      tariff: 'Tipo de membresía',
      file_sales: 'Ingresos $',
      site_sales: 'Ingresos de referencias del sitio $',
      status: 'Estado*'
    },
    notes: {
      note1: '* Por favor, lee más sobre los estados {hold}, {void} y {refund} en nuestros {faq}',
      status_hold: 'Esperar',
      status_void: 'Vacío',
      status_refund: 'Reembolsar',
      note2: '** La tabla muestra los datos de los últimos dos años',
      type_rebill: '- recarga',
      type_copy: '- desde la copia del archivo',
      type_promo: '- enlace promocional o banner'
    }
  },
  file_money_type: {
    direct: 'Directo',
    rebill: 'Recarga',
    copy: 'Copiando archivos'
  },
  refresh: 'Actualizar',
  filters: 'Filtros',
  sites: 'Sitios',
  files: 'Archivos',
  files_copies: 'Copias de archivos $',
  status: 'Estado',
  payment_system: 'Forma de Pago',
  all: 'Todos',
  date_from_placeholder: 'Fecha de inicio',
  date_to_placeholder: 'Fecha final',
  promo_banner_or_link: 'Enlace promocional',
  site_referral: 'Referencias del sitio',
  unknown: 'Desconocido'
};