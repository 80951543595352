export default {
  today_stat: {
    title: 'Ingresos de hoy',
    sub_title: '(se actualiza automáticamente cada cuatro horas)',
    refresh: 'Actualizar'
  },
  filters: {
    title: 'Índice de ingresos',
    date_from: 'Fecha de inicio',
    date_to: 'Fecha final',
    group_by: 'Agrupado por',
    group: {
      day: 'Días',
      week: 'Semanas',
      month: 'Meses'
    },
    switcher: 'Filtros'
  },
  gold_status: {
    title: 'Programa «Socios Gold»',
    current_status: 'Estatus actual',
    next_status: 'Próximo estado',
    levels: {
      level_0: 'Socio estándar',
      level_1: 'Socio Gold',
      level_2: 'poster Gold +{percent}%',
      level_3: 'Maestro Gold +{percent}%',
      level_4: 'Administrador Gold +{percent}%',
      level_5: 'Héroe Gold +{percent}%',
      level_6: 'Magno Gold +{percent}%'
    },
    efficiency: 'eficiencia {value}',
    coins: '{value} monedas',
    next_level_condition: {
      wait_next_period: 'Espera un mes para conseguir',
      increase_efficiency: 'Aumenta la eficiencia en {value} para conseguir',
      maximum_level: 'Has alcanzado el estado más alto posible. ¡Sigue así!',
      need_more_coins: 'Debes añadir {value} moneda(s) más para alcanzar'
    }
  },
  earnings_table: {
    title: 'Estadística detallada',
    fields: {
      date: 'Fecha',
      files: 'Archivos',
      sites: 'Referencias del sitio',
      referrals: 'Referencias',
      additional: 'Adicional*',
      total: 'Total',
      efficiency: 'Eficiencia**',
      coins: 'Monedas'
    }
  },
  notes: {
    additional: '*Adicional de eventos especiales, compensaciones devengadas y etc.',
    efficiency: '**Por favor, lee acerca de<a href=\'{efficiency_url}\'>&laquo;Eficiencia&raquo;</a> y ' + '<a href=\'{gold_url}\'>&laquo;Programa de bonificación &laquo;Socios Gold&raquo;</a> en <a href=\'{faq_url}\'>Preguntas frecuentes</a>'
  }
};