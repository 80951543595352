export default {
  today_stat: {
    title: "Revenue today",
    sub_title: "(updated automatically every four hours)",
    refresh: "Refresh"
  },
  filters: {
    title: "Revenue index",
    date_from: "Start date",
    date_to: "End date",
    group_by: "Group by",
    group: {
      day: "Days",
      week: "Weeks",
      month: "Months"
    },
    switcher: "Filters"
  },
  gold_status: {
    title: "«Gold partners» program",
    current_status: "Current status",
    next_status: "Next status",
    levels: {
      level_0: "Standard partner",
      level_1: "Gold partner",
      level_2: "Gold poster +{percent}%",
      level_3: "Gold master +{percent}%",
      level_4: "Gold admin +{percent}%",
      level_5: "Gold hero +{percent}%",
      level_6: "Gold magnate +{percent}%"
    },
    efficiency: "efficiency {value}",
    coins: "{value} coins",
    next_level_condition: {
      wait_next_period: "Wait for a month to achieve",
      increase_efficiency: "Increase the efficiency by {value} to achieve",
      maximum_level: "You have reached the highest status available. Keep it up!",
      need_more_coins: "You should add {value} more coin(s) to reach"
    }
  },
  earnings_table: {
    title: "Detailed statistic",
    fields: {
      date: "Date",
      files: "Files",
      sites: "Site referrals",
      referrals: "Referrals",
      additional: "Additional*",
      total: "Total",
      efficiency: "Efficiency**",
      coins: "Coins"
    }
  },
  notes: {
    additional: "*Additional from special events, compensation accrual and etc.",
    efficiency:
      "**Please, read about <a href='{efficiency_url}'>&laquo;Efficiency&raquo;</a> and " +
      "<a href='{gold_url}'>&laquo;Bonus program &laquo;Gold Partners&raquo;</a> in <a href='{faq_url}'>FAQ</a>"
  }
};
