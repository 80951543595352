export default {
  cancel: 'Annuler',
  confirm: 'Confirmer',
  close: 'Fermer',
  save: 'Sauvegarder',
  all_fields_are_required: 'Tous les champs sont requis',
  ok: 'Ok',
  loading: 'Chargement...',
  wait: 'Veuillez patienter...',
  delete: 'Supprimer',
  reset: 'Réinitialiser',
  refresh: 'Actualiser',
  continue: 'Continuer',
  skip: 'Ignorer',
  done: 'Terminé',
  send: 'Envoyer',
  filters: 'Filtres',
  tables: { total: 'Total' },
  error: 'Erreur',
  try_later: 'Une erreur est survenue. Essayez plus tard',
  form: {
    errors: {
      required: '{field} est obligatoire',
      number: 'Veuillez introduire un numéro valable',
      max: 'La valeur doit être inférieure ou égale à {value}',
      min: 'la valeur doit être supérieure ou égale à 40.00'
    }
  },
  email: 'E-mail',
  password: 'Mot de passe'
};