export default {
  summary: "Summary",
  total_referrals: "Total referrals",
  first_level: "First level",
  second_level: "Second level",
  total_earned: "Total earned",
  total_earned_value: "${value}",
  detailed_statistics: "Detailed statistic",
  data_table: {
    fields: {
      user: "User",
      referral_level: "Referral's level",
      registration_date: "Registration date",
      today_earnings: "Earned today",
      weekly_earnings: "Weekly earnings",
      total_earnings: "Total earnings",
      ref_percent: "Ref. percentage"
    }
  },
  earnings: "${value}",
  users_with_earnings: "Users with earnings",
  search_by_user: "Search by user",
  referrals_promo_meterials: "Referrals promo materials",
  referral_link: "Referral invitation link",
  referrals_banners: "Referrals Banners",
  banner_code: "Banner code"
};
