export default {
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  close: 'إغلاق',
  save: 'حفظ',
  all_fields_are_required: '* جميع الحقول مطلوبة',
  ok: 'موافق',
  loading: 'جار التحميل...',
  wait: 'انتظر...',
  delete: 'حذف',
  reset: 'إعادة ضبط',
  refresh: 'تحديث',
  continue: 'متابعة',
  skip: 'تخطي',
  done: 'تم',
  send: 'إرسال',
  filters: 'الفلاتر',
  tables: { total: 'الإجمالي' },
  error: 'خطأ',
  try_later: 'حدث خطأ. حاول لاحقاً',
  form: {
    errors: {
      required: '{field} مطلوب',
      number: 'من فضلك ، حدد الرقم الصحيح',
      max: 'يجب أن تكون القيمة أقل من أو تساوي {value}',
      min: 'يجب أن تكون القيمة أكبر من أو تساوي 40.00'
    }
  },
  email: 'البريد الإلكتروني',
  password: 'كلمه السر'
};