export default {
  sites_referrals: 'Indicações de site',
  sites_referrals_subheader: 'Ganhe comissão da receita de cada compra feita pelo usuário que foi indicado no seu site ',
  data_table: {
    fields: {
      site: 'Site',
      visits: 'Visitas',
      sales: 'Compras',
      percent_from_sale: '% da compra',
      today_earnings: 'Ganhos de hoje',
      weekly_earnings: 'Ganhos semanais',
      total_earnings: 'Total de ganhos',
      status: 'Status',
      action: 'Ação'
    }
  },
  earnings: '${value}',
  activate: 'Ativado',
  add_site: 'Enviar o seu site',
  status: {
    active: 'Ativo',
    created: 'Não verificado',
    banned: 'Banido',
    deleted: 'Excluído'
  },
  enter_site_url: 'Especifique o URL do seu site',
  site_url_placeholder: 'https://example.com',
  enter_valid_url: 'Endereço de URL inválido',
  option_1: 'Opção 1',
  option_1_text: 'Coloque o arquivo {file} com seu nome de login no diretório raiz do seu site.{br}' + 'O arquivo deve estar disponível através desta URL {url}.',
  option_2: 'Opção 2',
  option_2_text: 'Coloque uma tag META especial entre as tags <head> e </head> do seu site.',
  verify_ownership_of_site: 'Verificar propriedade sobre o site',
  site_configuration: 'Configuração do site',
  site_configuration_line_1: 'Se o seu site usa protocolo HTTPS:',
  site_configuration_line_2: '- Coloque uma META-tag adicional para todas as páginas do seu site contendo links para arquivos ' + ' para arquivos - {code} (<a href=\'{link}\' target=\'_blank\'>ler mais sobre isso</a>); ',
  site_configuration_line_3: '- Certifique-se que os links <strong>não contêm</strong> <code style="font-size: 14px;">' + 'rel="noopener noreferrer nofollow"</code> atributos' + ' (exclua-o para todos os links dos arquivos). ',
  site_configuration_line_4: 'Estas configurações <strong>são absolutamente seguras</strong> e nos permitem identificar seu site corretamente ',
  premium_sale_promo_materials: 'Materiais promocionais para o site',
  premium_sale_promo_materials_new_year: 'Materiais promocionais de ano novo para o site',
  promo_link_and_banners: 'Banners e link premium único',
  use_link_to_get_commision: 'Use este link e banners para realizar as vendas diretas e ganhar {value}% de comissão',
  premium_sale_link: 'Link único para vendas premium',
  premium_sale_banners: 'Banners de vendas premium',
  download_psd: 'Você pode baixar arquivos <a href=\'{link}\' target=\'_blank\'>.PSD originais</a> ' + 'e traduzir o banner para o seu idioma, ou alterar o texto nele. ',
  banner_code: 'Código do banner'
};