export default {
  hints: 'Consejos',
  hint_plus: 'Archivos subidos hace menos de 10 días; Archivos con más de 100 descargas; Archivos con 1 o más compras.' + 'Estos archivos sólo pueden mejorar tu eficiencia',
  hint_arrow_up: 'Archivos rentables y eficientes',
  hint_arrow_down: 'Archivos ineficientes y no rentables',
  hint_minus: 'Archivos no rentables que disminuyen drásticamente tu eficiencia',
  data_table: {
    fields: {
      name: 'Nombre de archivo (carpeta)',
      size: 'Tamaño',
      created_date: 'Añadido',
      download_count_today: 'Descargas actuales',
      download_count_total: 'Total de descargas',
      money_today: 'Ganados hoy',
      money_total: 'Ganado en total',
      premium_count_today: 'Ventas hoy',
      premium_count_total: 'Ventas en total'
    }
  },
  inbox: 'Bandeja de entrada',
  show_hints: 'Mostrar consejos',
  folder: 'Carpeta',
  find_by_name: 'Buscar por nombre',
  all: 'Todos',
  detailed_statistics_by_files: 'Estadísticas detalladas de los archivos',
  // files copies
  detailed_statistics_by_file_copies: 'Estadísticas detalladas de las copias de archivos'
};