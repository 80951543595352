export default {
  header_subtitle_1: 'شكرا لك على الانضمام إلينا! دعونا نتعمق في عملية الحفاظ على حساب الشريك.',
  header_subtitle_2: 'يرجى اتباع الخطوات التالية لتقديم جميع المعلومات المطلوبة.',
  skip_step: 'تخطي الخطوة',
  done: 'تم',
  add_payment_details: 'قم بتقديم تفاصيل المحفظة لتأهيل طلب الدفع',
  next_step: 'الخطوة التالية',
  step1: {
    title: 'أضف تفاصيل محفظتك إلى صفحة "الدفع". ',
    description: 'يمكنك استخدام أنظمة مثل بأي بال وويبموني وبيتكوين وبايونير وكيوي وغيرها الكثير!',
    hint: 'إذا لم يكن لديك محفظة بعد، يمكنك تخطي هذه الخطوة وإضافتها في وقت لاحق.'
  },
  step2: {
    title: 'تفعيل حساب turbobit.net و/أو hitfile.net.',
    description: 'حدد خطة الشراكة عن طريق النقر فوق "تفعيل" على علامة "برنامج الشركاء"، أو استخدم الأزرار أدناه:',
    hint: 'بعد التفعيل، سيتم إرسال جميع البيانات المرسلة إلى بريدك الإلكتروني.'
  },
  step3: {
    title: 'اختار خطة الشراكة',
    description1: 'تعرف على جميع خطط الشراكة المتاحة على ' + 'صفحة "الشروط والخطط" وقرر ما يناسبك على أفضل وجه.',
    description2: 'يمكنك التحقق من خطة العمولة الخاصة بك وتغييرها على صفحة "الإعدادات" لكل مشروع.',
    hint: 'يمكن تغيير خطة الشراكة مرة في الأسبوع.'
  },
  step4: {
    title: ' رفع الملف إلى turbobit.net و/أو hitfile.net ',
    description1: 'تسجيل الدخول باستخدام بريدك الإلكتروني الحالي إلى turbobit.net/hitfile.net ورفع الملفات.',
    description2: 'أو حدد علامة جديدة "مدير الملفات" المتاحة من قائمة "الأدوات" ' + 'للعمل مع الملفات بشكل أكثر فعالية (الموصى به).'
  },
  step5: {
    title: 'تهانينا، حسابك جاهز. ',
    description1: 'استمر, قم برفع ومشاركة ملفاتك لتوليد الدخل لكل تنزيل أو/و بيع, ' + 'وفقا لخطة الشراكة المحددة! ',
    description2: 'إذا كان لديك أي أسئلة أو مشاكل أخرى تحتاج إلى الدعم، اقرأ الأسئلة الشائعة أو اتصل بنا. {url}'
  }
};