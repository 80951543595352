export default {
  payout_details: "Détails des paiements",
  payout_details_subtitle:
    "Pour des raisons de sécurité, les informations de paiement nouvelles et actualisées doivent être " +
    "vérifié par e-mail et entre en vigueur après {days} jours de détente.",
  type: "Type",
  details: "Détails",
  create_date: "Date",
  fee_min_payout: "Frais / Paiement minimum",
  status: "Statut",
  action: "Action",
  active: "Actif",
  on_hold: "En attente ({date})",
  waiting_email_confirmation: "En attente de confirmation",
  are_you_sure_you_want_to_delete_purse: "Êtes-vous sûr de vouloir supprimer ce porte-monnaie?",
  main_purse_can_not_be_deleted:
    "Le porte-monnaie principal ne peut pas être supprimé. Sélectionnez le porte-monnaie vérifié et définissez-le comme le principal pour pouvoir supprimer tout le reste",
  add_new: "Soumettre",
  add_new_payout_details: "Soumettre de nouveaux détails de paiement",
  payout_system: "Système de paiement",
  month: "Mois",
  year: "Année",
  first_name: "Prénom",
  last_name: "Nom de famille",
  currency: "Devise",
  iban: "IBAN",
  bank_name: "Nom de la banque",
  set_as_main: "Définir comme porte-monnaie principal",
  delete_wallet: "Retirer",
  no_purses: "Vous n'avez pas encore ajouté de porte-monnaie",
  confirming_purse: "Vérification en cours. Veuillez patienter...",
  url_is_invalid: "L'URL est invalide ou a expiré. Veuillez réessayer",
  purse_is_confirmed: "Vérification des détails du paiement effectuée avec succès",
  available_withdrawal: "Disponible pour le paiement : {amount} USD",
  new_request: "Nouvelle demande",
  amount_in_hold: "Montant en attente",
  what_is_hold: "qu'est-ce qui est en attente?",
  files: "Fichiers",
  referrals: "Parrainages",
  sites_referrals: "Sites_références",
  file_copies: "Copies de fichiers",
  request_payout: "Demander un paiement",
  send_request: "Soumettre une requête",
  request_payout_amount_field_label: "Montant ",
  Fee: "Frais",
  Min_payout_is: "Le paiement minimum est de",
  exchange_by_rates: "Taux de change USD/RUB de la Banque de Russie",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Êtes-vous sûr de vouloir supprimer la demande ?",
  request_payout_errors: {
    purse_is_on_hold_one:
      "Les détails du paiement ont été modifiés. La nouvelle demande peut être envoyée après le {date}",
    purse_is_on_hold_many:
      "Данные вывода были изменены. Новый запрос возможен к подаче, после {date}" +
      " ou définissez un porte-monnaie vérifié dans les paramètres"
  },
  payouts: "Paiements",
  payouts_subtitle:
    "Les demandes de paiement sont généralement traitées dans un délai moyen de 7 jours, " +
    "mais en cas de faible demande, le délai de traitement peut être encore plus rapide.",
  payout_table: {
    fields: {
      created_date: "Date",
      amount: "Montant ",
      payment_details: "Détails du porte-monnaie",
      status: "Statut",
      action: "Action"
    }
  },
  payouts_status_label: {
    created: "Créé",
    review: "En cours",
    process: "Paiement en cours",
    accepted: "Complété",
    rejected: "Rejeté",
    deleted: "Supprimé"
  },
  submit_payouts_details: "Sauvegarder"
};
