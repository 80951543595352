export default {
  sites_referrals: "Sites-referrals",
  sites_referrals_subheader:
    "Earn commission of the revenue from each purchase made by the user who was referred from your site ",
  data_table: {
    fields: {
      site: "Site",
      visits: "Visits",
      sales: "Purchases",
      percent_from_sale: "% from purchase",
      today_earnings: "Earned today",
      weekly_earnings: "Weekly earnings",
      total_earnings: "Total earnings",
      status: "Status",
      action: "Action"
    }
  },
  earnings: "${value}",
  activate: "Activate",
  add_site: "Submit your site",
  status: {
    active: "Active",
    created: "Not verified",
    banned: "Banned",
    deleted: "Deleted"
  },
  enter_site_url: "Specify your site URL",
  site_url_placeholder: "https://example.com",
  enter_valid_url: "URL address not valid",
  option_1: "Option #1",
  option_1_text:
    "Place the {file} file with your login name in the root directory of your site.{br}" +
    "File must be available by this URL {url}.",
  option_2: "Option #2",
  option_2_text: "Place a special META-tag between the <head> and </head> tags of your site.",
  verify_ownership_of_site: "Verify ownership over the site",
  site_configuration: "Site configuration",
  site_configuration_line_1: "If your site uses HTTPS protocol:",
  site_configuration_line_2:
    "- Place an additional META-tag for all the pages of your site containing links for files " +
    " to files - {code} (<a href='{link}' target='_blank'>read more about this</a>); ",
  site_configuration_line_3:
    '- Make sure your links <strong>do not contain</strong> <code style="font-size: 14px;">' +
    'rel="noopener noreferrer nofollow"</code> attributes' +
    " (delete it for all the file links). ",
  site_configuration_line_4:
    "These settings <strong>absolutely safe</strong> and will allow us to correctly identify your site. ",
  premium_sale_promo_materials: "Promo materials for the site",
  premium_sale_promo_materials_new_year: "New Year promo materials for the site",
  promo_link_and_banners: "Unique premium link and banners",
  use_link_to_get_commision: "Use this link and banners to accomplish direct sales and earn {value}% commission",
  premium_sale_link: "Unique link for premiums sale",
  premium_sale_banners: "Premium sale banners",
  download_psd:
    "You can download <a href='{link}' target='_blank'>.PSD original</a> files " +
    "and translate banner for your language, or change text in it. ",
  banner_code: "Banner code"
};
