import Vue from "vue";

export default {
  namespaced: true,
  state: {},
  actions: {
    loadEarningsData(module, params) {
      return Vue.axios.get("/earnings/stat", { params });
    },
    loadTodayData(module, params) {
      return Vue.axios.get("/earnings/today", { params });
    },
    loadGoldStatusData(module, params) {
      return Vue.axios.get("/earnings/gold-status", { params });
    }
  }
};
