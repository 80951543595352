export default {
  main_block_title: "Домены",
  search_placeholder: "Поиск по домену",
  dates_placeholder: "Период",
  domain_table: {
    fields: {
      domain: "Источники",
      hits: "Посещения",
      downloads: "Скачивания",
      unique_downloads: "Уникальные скачивания",
      purchases: "Покупки"
    },
    detailed_row_title: "Подробная статистика:"
  },
  url_table: { fields: { url: "Url" } }
};
