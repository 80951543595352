export default {
  summary: 'Résumé',
  total_referrals: 'Total des parrainages',
  first_level: 'Premier niveau',
  second_level: 'Second niveau',
  total_earned: 'Total des gains',
  total_earned_value: '${value}',
  detailed_statistics: 'Statistiques détaillées',
  data_table: {
    fields: {
      user: 'Utilisateur',
      referral_level: 'Niveau du parrainage',
      registration_date: 'Date d\'enregistrement',
      today_earnings: 'Gain réalisé aujourd\'hui',
      weekly_earnings: 'Gains hebdomadaires',
      total_earnings: 'Revenus totales',
      ref_percent: 'Pourcentage de réf'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'Utilisateurs avec des revenus',
  search_by_user: 'Rechercher par utilisateur',
  referrals_promo_meterials: 'Matériel promotionnel de parrainage',
  referral_link: 'Lien d\'invitation de parrainage',
  referrals_banners: 'Bannières de parrainage',
  banner_code: 'Code de bannière'
};