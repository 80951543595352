export default {
  header_subtitle_1: '¡Gracias por unirse! Vamos a sumergirnos en el proceso de mantener la cuenta de tu compañero.',
  header_subtitle_2: 'Siga los siguientes pasos para enviar toda la información requerida.',
  skip_step: 'Saltar paso',
  done: 'Listo',
  add_payment_details: 'Enviar detalles del bolso para calificar la solicitud de pago',
  next_step: 'Paso siguiente',
  step1: {
    title: 'Añada los detalles de tu bolso a la página de «Pagos». ',
    description: '¡Puedes usar tales sistemas como PayPal, Webmoney, Bitcoin, Payoneer, Qiwi y muchos otros!',
    hint: 'Si aún no tienes un bolso, puedes saltarte este paso y añadirla más tarde.'
  },
  step2: {
    title: 'Activar la cuenta turbobit.net y/o hitfile.net.',
    description: 'Selecciona el plan de afiliados haciendo clic en «Activar» en la pestaña «Programa de Afiliados», o utilice los botones siguientes:',
    hint: 'Después de la activación, todos los datos serán enviados a tu correo electrónico.'
  },
  step3: {
    title: 'Seleccionar plan de afiliados',
    description1: 'Más información sobre todos los planes de afiliados disponibles en el ' + 'página «Condiciones y planes» y decide sobre la que más le convenga.',
    description2: 'Comprueba y cambia tu plan de afiliados en «Ajustes» de cada proyecto.',
    hint: 'El plan de afiliados se puede cambiar una vez a la semana.'
  },
  step4: {
    title: ' Subir el archivo a turbobit.net and/or hitfile.net ',
    description1: 'Inicia sesión con tu correo electrónico actual en turbobit.net/hitfile.net y sube los archivos.',
    description2: 'O selecciona el nuevo «Administrador de archivos» disponible en el menú de «Herramientas» ' + 'para operar con los archivos de forma más eficaz (recomendado).'
  },
  step5: {
    title: '¡Felicidades, tu cuenta está activada! ',
    description1: 'Sigue adelante, sube y comparte tus archivos para generar ingresos para cada descarga y/o venta, ' + 'según el plan de afiliados seleccionado! ',
    description2: 'Si tiene más preguntas o problemas que requieran ayuda, lee las preguntas frecuentes o ponte en contacto con nosotros. {url}'
  }
};