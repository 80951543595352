export default {
  main_block_title: 'Domaines',
  search_placeholder: 'Recherche par domaine',
  dates_placeholder: 'Intervalle de date',
  domain_table: {
    fields: {
      domain: 'Sources',
      hits: 'Visites',
      downloads: 'Téléchargements',
      unique_downloads: 'Téléchargements uniques',
      purchases: 'Achats'
    },
    detailed_row_title: 'Statistiques détaillées:'
  },
  url_table: { fields: { url: 'Url' } }
};