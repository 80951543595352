export default {
  page_header: 'Ayarlar',
  page_subtitle: 'Bilgilerinizi, gizlilik ve güvenlik seçeneklerinizi yönetin.{br} Ödeme detayları {url} sayfasında düzenlenebilir',
  payouts: '«Ödemeler»',
  personal_info: 'Kişisel bilgiler',
  change_password: 'Şifreyi değiştir',
  security_options: 'Güvenlik seçenekleri',
  email: 'E-posta',
  first_name: 'İsim',
  last_name: 'Soyadı',
  contact_details: 'İletişim bilgileri',
  contact_details_hint: 'Telegram, Skype veya başka herhangi bir iletişim türü',
  update_personal_info: 'Kişisel bilgileri güncelle',
  old_password: 'Kişisel bilgileri güncelle',
  new_password: 'Yeni şifre',
  new_password_confirm: 'Yeni şifre (onay)',
  change_password_submit: 'Şifreyi değiştir',
  security_code: 'Güvenlik kodu',
  security_code_description: 'Kendi isteğinizle etkinleştirilebilecek ek bir güvenlik önlemi. ' + 'Dosyaları silerken, birikmiş fonları talep ederken veya ödeme ayrıntılarını değiştirirken ' + 'sistem bir güvenlik kodu onayı gerektirmeye başlayacaktır.',
  use_security_code_checkbox_label: 'Eylemlerin güvenlik koduyla onaylanmasını etkinleştirme',
  security_code_field_label: 'Güvenlik kodu',
  security_code_confirmation_field_label: 'Güvenlik kodu (onay)',
  security_code_hint: '* Dikkatli olun, güvenlik kodu geri yüklenemez. ' + 'Yeni güvenlik kanunu 15 gün içinde yürürlüğe giriyor, bu süre zarfında fonlarla yapılan tüm işlemler ' + 'ödeme detayları ve dosyaların silinmesi dondurulacaktır.',
  update_security_options: 'Güvenlik seçeneklerini güncelle',
  password_hacking_prevention_system: 'Şifre kırma önleme sistemi',
  limit_login_attempts_field_label: 'Başarısız oturum açma girişimlerinin sınırı',
  limit_login_attempts_field_hint: '10 başarısız denemeden sonra, e-posta yoluyla yetkilendirme gerekli olacaktır',
  use_captcha_field_label: 'Hesabımı korumak için captcha\'yı etkinleştir',
  use_captcha_field_hint: 'Bu seçeneği etkinleştirmeniz şiddetle tavsiye edilir',
  errors: {
    password_not_match_confirmation: 'Onaylanan parola ile eşleşmiyor',
    security_code_not_match_confirmation: 'Güvenlik kodu onaylanmış gereksinimleri karşılamıyor'
  },
  password_changing_notification_sent: 'Kayıt sırasında belirtilen e-postaya şifre değişikliği bildirimi gönderilmiştir.',
  drop_all_sessions: 'Tüm oturumları kapat',
  current_security_code_field_label: 'Lütfen geçerli güvenlik kodunuzu girin'
};