export default {
  sites_referrals: "Сайты рефералы",
  sites_referrals_subheader:
    "Получайте дополнительный доход с каждой покупки, совершенной пользователем, " +
    "который был перенаправлен с вашего сайта ",
  data_table: {
    fields: {
      site: "Сайт",
      visits: "Посещения",
      sales: "Покупки",
      percent_from_sale: "% от покупки",
      today_earnings: "Доход сегодня",
      weekly_earnings: "Заработок за неделю",
      total_earnings: "Общий доход",
      status: "Статус",
      action: "Действие"
    }
  },
  earnings: "${value}",
  activate: "Активировать",
  add_site: "Укажите свой сайт",
  status: {
    active: "Активен",
    created: "Не подтвержден",
    banned: "Забанен",
    deleted: "Удалён"
  },
  enter_site_url: "Укажите URL вашего сайта",
  site_url_placeholder: "https://example.com",
  enter_valid_url: "URL-адрес недействителен",
  option_1: "Вариант #1",
  option_1_text:
    "Разместите файл {file} с вашим логином в корневой директории сайта.{br}" +
    "Файл должен быть доступен по этому адресу {url}.",
  option_2: "Вариант #2",
  option_2_text: "Разместите специальный META-тег между <head> и </head> тегами вашего сайта.",
  verify_ownership_of_site: "Подтвердить право собственности на сайт",
  site_configuration: "Конфигурация сайта",
  site_configuration_line_1: "Если ваш сайт использует HTTPS:",
  site_configuration_line_2:
    "- Поместите дополнительный META-тег для всех страниц вашего сайта, содержащих ссылки " +
    " на файлы - {code} (<a href='{link}' target='_blank'>подробнее читайте здесь</a>); ",
  site_configuration_line_3:
    '- Убедитесь, что ваши ссылки <strong>не содержат</strong> <code style="font-size: 14px;">' +
    'атрибутов rel="noopener noreferrer nofollow"</code>' +
    " (удалите данный атрибут для всех ссылок на файлы). ",
  site_configuration_line_4:
    "Эти настройки <strong>абсолютно безопасны</strong> " +
    "и позволят правильно идентифицировать ваш сайт в качестве источника. ",
  premium_sale_promo_materials: "Промо материалы для сайта",
  premium_sale_promo_materials_new_year: "Новогодние промо материалы для сайта",
  promo_link_and_banners: "Уникальная премиум-ссылка и баннеры",
  use_link_to_get_commision:
    "Используйте эту ссылку и баннеры, чтобы осуществлять прямые продажи и получать комиссию в размере {value}%",
  premium_sale_link: "Уникальная ссылка для продажи премиум доступов",
  premium_sale_banners: "Баннеры",
  download_psd:
    "Вы можете скачать <a href='{link}' target='_blank'>исходные файлы в формате PSD</a> " +
    "и перевести баннеры на любой язык, при необходимости изменив текст. ",
  banner_code: "Код баннера"
};
