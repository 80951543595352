export default {
  sites_referrals: 'إحالات- المواقع',
  sites_referrals_subheader: 'اكسب عمولة من الإيرادات من كل عملية شراء قام بها المستخدم الذي تمت إحالته من موقعك ',
  data_table: {
    fields: {
      site: 'الموقع',
      visits: 'الزيارات',
      sales: 'المشتريات',
      percent_from_sale: '% من المشتريات',
      today_earnings: 'تم اكتسابها اليوم',
      weekly_earnings: 'الأرباح الأسبوعية',
      total_earnings: 'إجمالي الأرباح',
      status: 'الحالة',
      action: 'الإجراء'
    }
  },
  earnings: '${value}',
  activate: 'تفعيل',
  add_site: 'قم بإرسال موقعك',
  status: {
    active: 'مفعل',
    created: 'لم يتم التحقق',
    banned: 'محظور',
    deleted: 'محذوف'
  },
  enter_site_url: 'حدد عنوان URL لموقعك',
  site_url_placeholder: 'https://example.com',
  enter_valid_url: 'عنوان URL غير صالح',
  option_1: 'الخيار 1#',
  option_1_text: 'ضع الملف {file} مع اسم تسجيل الدخول الخاص بك في الدليل الجذر لموقعك.{br}' + 'يجب أن يكون الملف متاحا من قبل عنوان URL هذا {url}.',
  option_2: 'الخيار 2#',
  option_2_text: 'ضع علامة ميتى خاصة بين علامتي <head> و </head> لموقعك.',
  verify_ownership_of_site: 'التحقق من الملكية عبر الموقع',
  site_configuration: 'إعدادات الموقع',
  site_configuration_line_1: 'إذا كان موقعك يستخدم بروتوكول HTTPS:',
  site_configuration_line_2: 'وضع علامة- ميتا إضافية لجميع صفحات موقعك تحتوي على روابط للملفات ' + ' إلى الملفات - {code} (<a href=\'{link}\' target=\'_blank\'>اقرأ المزيد عن هذا</a>); ',
  site_configuration_line_3: '- تأكد من أن الروابط الخاصة بك <strong>لا تحتوي على</strong> <code style="font-size: 14px;">' + 'إعادة ="متابعة إشارة مفتح"</code> خصائص' + ' (حذف لجميع روابط الملفات). ',
  site_configuration_line_4: 'هذه الإعدادات <strong>آمنة تمامًا</strong> وستسمح لنا بتحديد موقعك بشكل صحيح. ',
  premium_sale_promo_materials: 'المواد الترويجية للموقع',
  premium_sale_promo_materials_new_year: 'المواد الترويجية للعام الجديد للموقع',
  promo_link_and_banners: 'الرابط الممتاز الفريد و اللافتات',
  use_link_to_get_commision: 'استخدم هذا الرابط والبانرات لإنجاز المبيعات المباشرة وكسب عمولة {value}٪',
  premium_sale_link: 'رابط فريد للبيع المميز',
  premium_sale_banners: 'بانرات بيع مميزة',
  download_psd: 'يمكنك تنزيل ملفات <a href=\'{link}\' target=\'_blank\'>.PSD الأصلية</a> ' + 'وترجمة البانر للغتك أو تغيير النص فيه. ',
  banner_code: 'رمز البانر'
};