export default {
  header_subtitle_1: 'Bize katıldığınız için teşekkür ederiz! Partner hesabını koruma sürecine geçelim.',
  header_subtitle_2: 'Gerekli tüm bilgileri göndermek için lütfen aşağıdaki adımları izleyin.',
  skip_step: 'Bu adımı atla',
  done: 'Tamamlandı',
  add_payment_details: 'Ödeme talebine hak kazanmak için cüzdan bilgilerinizi gönderin',
  next_step: 'Bir Sonraki Adım',
  step1: {
    title: 'Cüzdan bilgilerinizi "Ödemeler" sayfasına ekleyin.',
    description: 'PayPal, Webmoney, Bitcoin, Payoneer, Qiwi ve diğerleri gibi sistemleri kullanabilirsiniz!',
    hint: 'Henüz bir cüzdanınız yoksa, bu adımı atlayıp daha sonra ekleyebilirsiniz.'
  },
  step2: {
    title: 'Etkinleştir turbobit.net ve/veya hitfile.net hesap.',
    description: 'Ortaklık Programı sekmesindeki «Etkinleştir» i tıklayarak «ortaklık planını» seçin veya aşağıdaki düğmeleri kullanın:',
    hint: 'Etkinleştirildikten sonra, gönderilen tüm veriler e-postanıza gönderilecektir.'
  },
  step3: {
    title: 'Ortaklık planı seçin',
    description1: 'Web sitesindeki mevcut tüm ortaklık planları hakkında bilgi edinin ' + '«Koşullar ve planlar» sayfası ve size en uygun olana karar verin.',
    description2: 'Ortaklık planınızı her proje için «Ayarlar» sayfasından kontrol edebilir ve değiştirebilirsiniz.',
    hint: 'Ortaklık planı haftada bir kez değiştirilebilir.'
  },
  step4: {
    title: ' Dosyayı karşıya yükle turbobit.net ve/veya hitfile.net ',
    description1: 'Geçerli e-postanızla giriş yapın turbobit.net/hitfile.net ve dosyaları yükleyin.',
    description2: 'Veya yeni "Dosya Yöneticisi" menüsüne "araçlar" menüsünden erişilebilir ' + 'dosyalarla daha etkin çalışmak için (önerilir).'
  },
  step5: {
    title: 'Tebrikler, hesabınız hazır. ',
    description1: 'Her indirme veya / ve satış için gelir elde etmek üzere dosyalarınızı devam ettirin, yükleyin ve paylaşın, ' + 'seçilen ortaklık planına göre! ',
    description2: 'Destek gerektiren başka sorularınız veya sorunlarınız varsa, SSS bölümünü okuyun veya bizimle iletişime geçin. {url}'
  }
};