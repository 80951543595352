export default {
  main_block_title: 'Domínios',
  search_placeholder: 'Pesquisar por domínio',
  dates_placeholder: 'Intervalo de datas',
  domain_table: {
    fields: {
      domain: 'Fontes',
      hits: 'Visitas',
      downloads: 'Downloads',
      unique_downloads: 'Downloads únicos',
      purchases: 'Compras'
    },
    detailed_row_title: 'Estatísticas detalhadas:'
  },
  url_table: { fields: { url: 'Url' } }
};