import checkAuthenticated from "@/auth/checkAuthenticated";
import checkGuest from "@/auth/checkGuest";

export default {
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/app/Index"),
      beforeEnter: checkAuthenticated,
      redirect: "/dashboard",

      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/dashboard/Index")
        },
        {
          path: "page-video",
          name: "page_video",
          component: () => import("@/views/pages/page-video/Index")
        },
        {
          path: "/payouts",
          name: "payouts",
          component: () => import("@/views/pages/payouts/Index")
        },
        {
          path: "/purse/confirm/:code",
          component: () => import("@/views/pages/confirm-purse/Index")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/pages/settings/Index")
        },
        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/views/pages/notifications/Index")
        },
        {
          path: "/news",
          name: "news",
          component: () => import("@/views/pages/news/Index")
        },
        {
          path: "/app/test",
          component: () => import("@/views/pages/test/Test"),
          children: [
            {
              path: "testing",
              component: () => import("@/views/pages/test/Testing")
            }
          ]
        },
        {
          path: "/auth/confirm-password-changing/:userId/:token",
          component: () => import("@/views/pages/sessions/ConfirmNewPassword")
        },
        {
          path: "/app/:app/statistics",
          component: () => import("@/views/pages/statistics/Index"),
          children: [
            {
              path: "sources",
              name: "stats.sources",
              component: () => import("@/views/pages/statistics/sources/Index")
            },
            {
              path: "sales",
              name: "stats.sales",
              component: () => import("@/views/pages/statistics/sales/Index")
            },
            {
              path: "files",
              name: "stats.files",
              component: () => import("@/views/pages/statistics/files/Index")
            },
            {
              path: "files-copies",
              name: "stats.files-copies",
              component: () => import("@/views/pages/statistics/files-copies/Index")
            },
            {
              path: "detailed",
              name: "stats.detailed",
              component: () => import("@/views/pages/statistics/detailed/Index")
            }
          ]
        },
        {
          path: "/app/:app/earnings",
          name: "earnings",
          component: () => import("@/views/pages/earnings/Index")
        },
        {
          path: "/app/:app/videos",
          name: "videos",
          component: () => import("@/views/pages/videos/Index")
        },
        {
          path: "/app/:app/referrals",
          name: "referrals",
          component: () => import("@/views/pages/referrals/Index")
        },
        {
          path: "/app/:app/gold",
          name: "gold",
          component: () => import("@/views/pages/gold/Index")
        },
        {
          path: "/app/:app/sites",
          name: "sites",
          component: () => import("@/views/pages/sites/Index")
        },
        {
          path: "/app/:app/rates",
          name: "rates",
          component: () => import("@/views/pages/rates/Index")
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/views/pages/faq/Index")
        },
        {
          path: "/start",
          name: "get-started",
          component: () => import("@/views/pages/get-started/Index")
        },
        {
          path: "/logout",
          component: () => import("@/views/pages/sessions/Logout")
        },
        {
          path: "/user/logout",
          component: () => import("@/views/pages/sessions/Logout")
        },
        {
          path: "/zendesk/sso",
          component: () => import("@/views/pages/sessions/ZendeskLogin")
        },
      ]
    },
    {
      path: "/auth",
      component: () => import("@/views/pages/sessions/Sessions"),
      beforeEnter: checkGuest,
      children: [
        {
          path: "register",
          name: "register",
          component: () => import("@/views/pages/sessions/Registration")
        },
        {
          path: "confirm-registration/:token",
          component: () => import("@/views/pages/sessions/ConfirmRegistration")
        },
        {
          path: "auto-login/:token",
          component: () => import("@/views/pages/sessions/AutoLoginPage")
        },
        {
          path: "login",
          name: "login",
          component: () => import("@/views/pages/sessions/Login")
        },
        {
          path: "restore-access",
          name: "request-restore-access",
          component: () => import("@/views/pages/sessions/RequestRestoreAccess")
        },
        {
          path: "restore-access/:token",
          name: "restore-access-token",
          component: () => import("@/views/pages/sessions/RestoreAccess")
        },
        {
          path: "restore-password/:email/:token",
          name: "restore-password-token",
          component: () => import("@/views/pages/sessions/RestorePassword")
        },
        {
          path: "restore-password",
          name: "restore-password",
          component: () => import("@/views/pages/sessions/RequestRestorePassword")
        },
      ]
    },
    {
      path: "*",
      component: () => import("@/views/pages/errors/PageNotFound")
    }
  ]
};
