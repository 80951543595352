export default {
  bonus_program: 'Bonus-Programm',
  gold_partners: '"Gold-Partner"',
  golden_partner: 'Gold-Status',
  page_subtitle: 'Regelmäßige und effektive Partner können <strong>Gold erhalten</strong><br />' + 'Status und erhalten ein zusätzliches Einkommen bis zu <strong>+25%</strong> der laufenden Einnahmen.',
  how_to_get_gold_status: 'Wie man Gold Status bekommt',
  how_to_get_gold_status_subtitle: 'Du wirst <strong>Gold Partner</strong> wenn Du die Einnahmen machst ' + 'während des Monats ist höher als "Dateien speichern Preis" auf der Statistik-Seite.',
  earnings: 'Einnahmen',
  efficiency_is_above: 'die Effizienz ist höher als {value}',
  how_to_get_gold_status_line: 'Zur Analyse des Erfolgs gehen Sie bitte zur Seite {link} ' + 'wobei die Statistik und die Effizienz der letzten 30 Tage angezeigt werden. ' + 'Am Ende des Monats, wenn {efficiency} am ' + 'richtiges Level, der Status {gold_partner} wird automatisch erhalten und ' + 'Du bekommst einen zusätzlichen Bonus im folgenden Monat.',
  how_to_increase_extra_bonus_amount: 'Wie man den Goldstatus verbessert und mehr verdient',
  how_to_increase_extra_bonus_amount_subtitle: 'Da Du den Goldstatus erhalten hast, führe Aktivität aus ' + 'wird virtuelle Münzen gewähren, die Deinen Status automatisch auf die höhere erhöhen.',
  how_to_increase_extra_bonus_amount_line_1: '1 Münze wird erhalten, wenn die aktuellen 24 Stunden Du mindestens so viele Downloads haben wie in den vorherigen ' + '72 Stunden pro durchschnittlichem 24-Stunden Zeitraum (für Downloads).',
  how_to_increase_extra_bonus_amount_line_2: '1 Münze wird erhalten, wenn die aktuellen 24 Stunden Du mindestens so viele Downloads haben wie in den vorherigen ' + '72 Stunden, pro durchschnittlichem 24-Stunden-Zeitraum (für Verkauf).',
  how_to_increase_extra_bonus_amount_line_3: '1 Münze wird entfernt, wenn die Effizienz in den letzten 30 Tagen konstant kleiner als 0 ist',
  comparative_table_of_partners_statuses: 'Status und Privilegien',
  comparative_table_of_partners_statuses_subtitle: 'Bleib aktiv, um mehr Münzen zu erhalten und ' + 'erhöhe Dein Einkommen. Je mehr Münzen Du erhältst, desto höher ist der Status = mehr Einnahmen.',
  comparative_table_of_partners_statuses_hint_1: 'Wichtig! Status wird nicht herabgestuft, auch wenn Du nicht genügend Münzen hast. Wenn die Anzahl der angesammelten Münzen ' + 'ist niedriger als es für Deinen aktuellen Status und den Status vor ihm sein muss, nur dann funktioniert Dein aktuelles Gold ' + 'Status wird heruntergestuft. <br />Zum Beispiel, wenn du den Status "Gold-Magnate" besaß ' + 'wird erst herabgestuft, wenn die Anzahl der Münzen unter 80 fällt.',
  comparative_table_of_partners_statuses_hint_2: 'Du verlierst den Gold Partner Status, wenn am Ende des Monats ' + 'Effizienz liegt unter 0 und Du hast 0 Münzen.',
  comparative_table_of_partners_statuses_hint_3: 'Unsichere Käufe mit dem Status "Betrug" oder "Erstattung" ' + 'wird bei der Gewinnung der Münzen nicht berücksichtigt.',
  status: 'Status',
  efficiency: 'Effizienz<br />am Anfang des Monats',
  coins: 'Anzahl der Münzen<br />am Ende des Tages',
  percent_of_earnings: '% der Einnahmen'
};