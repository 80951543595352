export default {
  site_name: 'CostAction.com - Les meilleurs programmes d\'affiliation assemblés en un seul endroit',
  dashboard: 'Tableau de bord',
  earnings: 'Revenus',
  page_not_found: 'Page non trouvée',
  faq: 'FAQ',
  get_started: 'Demarrage',
  gold: 'Partenaires Gold',
  news: 'Actualités',
  notifications: 'Notifications',
  payouts: 'Paiements',
  referrals: 'Parrainages',
  rates: 'Conditions et forfaits',
  settings: 'Paramètres',
  sites: 'Sites',
  stats_sources: 'Sources',
  stats_sales: 'Ventes',
  stats_files_copies: 'Copies de fichiers',
  stats_files: 'Fichiers',
  stats_detailed: 'Statistiques détaillées',
  sign_in: 'Connexion',
  create_new_account: 'Créer un nouveau compte',
  restore_access: 'Restaurer l\'accès',
  confirm_registration: 'Confirmer l\'inscription',
  purse_confirmation: 'Confirmation du portefeuille',
  password_recovery: 'Récupération du mot de passe'
};