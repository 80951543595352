export default {
  sites_referrals: 'Sites_références',
  sites_referrals_subheader: 'Gagnez une commission sur le revenu de chaque achat effectué par l\'utilisateur qui a été référé par votre site ',
  data_table: {
    fields: {
      site: 'Site',
      visits: 'Visites',
      sales: 'Achats',
      percent_from_sale: '% de l\'achat',
      today_earnings: 'Gain réalisé aujourd\'hui',
      weekly_earnings: 'Gains hebdomadaires',
      total_earnings: 'Revenus totales',
      status: 'Statut',
      action: 'Action'
    }
  },
  earnings: '${value}',
  activate: 'Activer',
  add_site: 'Soumettez votre site',
  status: {
    active: 'Actif',
    created: 'Non vérifié',
    banned: 'Exclus',
    deleted: 'Supprimé'
  },
  enter_site_url: 'Spécifiez l\'URL de votre site',
  site_url_placeholder: 'http://example.com',
  enter_valid_url: 'Adresse IP non valide',
  option_1: 'Option #1',
  option_1_text: 'Placez le fichier {file} avec votre nom de connexion dans le répertoire racine de votre site.{br}' + 'Le fichier doit être disponible avec cette URL {url}.',
  option_2: 'Option #2',
  option_2_text: 'Placez un tag META-tag spécial entre les balises <head> et </head> de votre site.',
  verify_ownership_of_site: 'Vérifier la propriété du site',
  site_configuration: 'Configuration de site',
  site_configuration_line_1: 'Si votre site utilise le protocole HTTPS:',
  site_configuration_line_2: '- Placer une META-tag supplémentaire pour toutes les pages de votre site contenant des liens pour les fichiers ' + ' aux fichiers - {code} (<a href=\'{link}\' target=\'_blank\'>en savoir plus à ce sujet</a>); ',
  site_configuration_line_3: '- Assurez-vous que vos liens <strong>ne contiennent pas</strong> <code style="font-size: 14px;">' + 'rel="noopener noreferrer nofollow"</code> attributs' + ' (supprimez-le pour tous les liens de fichier). ',
  site_configuration_line_4: 'Ces paramètres <strong>absolument sûrs</strong> et nous permettront d\'identifier correctement votre site. ',
  premium_sale_promo_materials: 'Matériel promotionnel pour le site',
  premium_sale_promo_materials_new_year: 'Matériel promotionnel du Nouvel An pour le site',
  promo_link_and_banners: 'Lien premium unique et bannières',
  use_link_to_get_commision: 'Utilisez ce lien et ces bannières pour réaliser des ventes directes et gagner {value}% de commission',
  premium_sale_link: 'Lien unique pour les ventes premiums',
  premium_sale_banners: 'Bannières de vente Premium',
  download_psd: 'Vous pouvez télécharger les fichiers <a href=\'{link}\' target=\'_blank\'>.PSD originaux</a> ' + 'et traduisez la bannière pour votre langue, ou changez le texte. ',
  banner_code: 'Code de bannière'
};