export default {
  today_stat: {
    title: "Statistics today",
    sub_title: "(updated automatically every four hours)",
    refresh: "Refresh",
    gold: "Gold bonus"
  },
  chart: {
    select: { label: "Chart" },
    title: "Daily chart and filters"
  },
  table: {
    title: "Detailed statistic",
    fields: {
      date: "Date",
      downloads: "Downloads",
      sum: "Sum $",
      avg_sales: "AVG sales $",
      sales: "Sales",
      gold: "Gold bonus $",
      copies: "Copies*",
      total: "Total",
      avg_download_amount: "AVG per 1000 downloads**"
    }
  },
  sub_table: {
    title: "Detailed statistics: {date}",
    fields: {
      countries: "Countries/Groups",
      sum: "Sum + Gold bonus $"
    },
    countries: {
      A: "Group A",
      B: "Group B",
      C: "Group C",
      D: "Group D",
      E: "Group E",
      F: "Group F",
      G: "Group G",
      R: "Russian",
      U: "Ukraine"
    }
  },
  notes: {
    copies: "*Earnings on your files <a href='{copies_url}'>copies</a>, made by other users.",
    avg_downloads:
      "**Column 'AVG per 1000 downloads' displays the average amount for 1.000 unique downloads of your " +
      "files and purchases of premium access"
  }
};
