export default {
  hints: 'İpuçları',
  hint_plus: '10 günden daha kısa bir süre önce yüklenen dosyalar; 100\'den fazla indirmeye sahip dosyalar; 1 veya daha fazla satın alma işlemine sahip dosyalar.' + 'Bu dosyalar yalnızca verimliliğinizi artırabilir',
  hint_arrow_up: 'Karlı ve verimli dosyalar',
  hint_arrow_down: 'Verimsiz ve karsız dosyalar',
  hint_minus: 'Son derece karsız dosyalar verimliliğinizi önemli ölçüde azaltır',
  data_table: {
    fields: {
      name: 'Dosya Adı (folder)',
      size: 'Boyut',
      created_date: 'Ekleme tarihi',
      download_count_today: 'Bugünün indirmeleri',
      download_count_total: 'Toplam indirmeler',
      money_today: 'Bugün kazanılan',
      money_total: 'Bugün kazanılan',
      premium_count_today: 'Bugünün Satışları',
      premium_count_total: 'Toplam satılan'
    }
  },
  inbox: 'Gelen kutusu',
  show_hints: 'Bugünün ipuçları',
  folder: 'Klasör',
  find_by_name: 'İsme göre ara',
  all: 'Tümü',
  detailed_statistics_by_files: 'Tüm uygulamalar için ayrıntılı istatistikler',
  // files copies
  detailed_statistics_by_file_copies: 'Tüm uygulamalar için ayrıntılı istatistikler'
};