export default {
  today_stat: {
    title: "Доход сегодня",
    sub_title: "(обновляется автоматически каждые четыре часа)",
    refresh: "Обновить"
  },
  filters: {
    title: "Показатель дохода",
    date_from: "Дата начала",
    date_to: "Дата окончания",
    group_by: "Группировать по",
    group: {
      day: "Дням",
      week: "Неделям",
      month: "Месяцам"
    },
    switcher: "Фильтры"
  },
  gold_status: {
    title: "Программа «Gold partners»",
    current_status: "Текущий статус",
    next_status: "Следующий статус",
    levels: {
      level_0: "Обычный партнер",
      level_1: "Gold партнер",
      level_2: "Gold poster +{percent}%",
      level_3: "Gold master +{percent}%",
      level_4: "Gold admin +{percent}%",
      level_5: "Gold hero +{percent}%",
      level_6: "Gold magnate +{percent}%"
    },
    efficiency: "эффективность {value}",
    coins: "{value} монет",
    next_level_condition: {
      wait_next_period: "Подождите месяц, чтобы достичь следующего статуса",
      increase_efficiency: "Увеличьте эффективность на {value}, чтобы достичь следующего статуса",
      maximum_level: "Вы достигли наивысшего доступного статуса. Так держать!",
      need_more_coins: "Вам следует получить {value} монет, чтобы достичь следующего статуса"
    }
  },
  earnings_table: {
    title: "Подробная статистика",
    fields: {
      date: "Дата",
      files: "Файлы",
      sites: "Сайты рефералы",
      referrals: "Рефералы",
      additional: "Дополнительно*",
      total: "Всего",
      efficiency: "Эффективность**",
      coins: "Монеты"
    }
  },
  notes: {
    additional: "*Получено за специальные мероприятия, начислено в качестве компенсации и др.",
    efficiency:
      "**Пожалуйста, прочитайте о <a href='{efficiency_url}'>&laquo;Эффективности&raquo;</a> и " +
      "<a href='{gold_url}'>&laquo;бонусной программе &laquo;Золотые партнеры&raquo;</a> в <a href='{faq_url}'>FAQ</a>"
  }
};
