export default {
  page_header: 'Configurações',
  page_subtitle: 'Gerencie suas opções de informação, privacidade e segurança.{br}Os detalhes de pagamento podem ser editados na página {url}',
  payouts: '«Pagamentos»',
  personal_info: 'Informações pessoais',
  change_password: 'Alterar senha',
  security_options: 'Opções de segurança',
  email: 'E-mail',
  first_name: 'Primeiro nome',
  last_name: 'Último nome',
  contact_details: 'Detalhes de contato',
  contact_details_hint: 'Telegram, Skype ou qualquer outro tipo de contato',
  update_personal_info: 'Atualizar informações pessoais',
  old_password: 'Senha antiga',
  new_password: 'Nova senha',
  new_password_confirm: 'Nova senha (confirmação)',
  change_password_submit: 'Alterar senha',
  security_code: 'Código de segurança',
  security_code_description: 'Uma medida de segurança adicional que pode ser habilitada em sua conta. ' + 'Ao excluir arquivos, solicitando fundos acumulados ou alterando detalhes do pagamento ' + 'o sistema começará a exigir uma confirmação de código de segurança.',
  use_security_code_checkbox_label: 'Habilitar confirmação de ações por código de segurança',
  security_code_field_label: 'Código de segurança',
  security_code_confirmation_field_label: 'Código de segurança (confirmação)',
  security_code_hint: '*Cuidado, o código de segurança não pode ser restaurado. ' + 'O novo código de segurança entra em vigor em 15 dias, durante este período, todas as operações com fundos ' + ', detalhes de pagamento e exclusão de arquivos serão congeladas.',
  update_security_options: 'Atualizar opções de segurança',
  password_hacking_prevention_system: 'Sistema de prevenção de hacking de senha',
  limit_login_attempts_field_label: 'Limite de tentativas de login sem sucesso',
  limit_login_attempts_field_hint: 'Depois de 10 tentativas incorretas, será necessária autorização via e-mail',
  use_captcha_field_label: 'Ativar o captcha para proteger a minha conta',
  use_captcha_field_hint: 'Altamente recomendado ativar essa opção',
  errors: {
    password_not_match_confirmation: 'Não coincide com a senha confirmada',
    security_code_not_match_confirmation: 'O código de segurança não atende aos requisitos confirmados'
  },
  password_changing_notification_sent: 'A notificação da alteração da senha foi enviada para o e-mail especificado durante o cadastro.',
  drop_all_sessions: 'Limpar todas as sessões',
  current_security_code_field_label: 'Por favor, insira seu código de segurança atual'
};