export default {
  main: {
    periods: {
      '7D': 'Últimos 7 días',
      '30D': 'Últimos 30 días',
      W: 'Esta semana',
      M: 'Este mes'
    }
  },
  info_blocks: {
    earnings: {
      title: 'Ingresos',
      today: 'ingresos de hoy',
      stat_volume: '${volume}',
      today_volume: '+${volume}'
    },
    downloads: {
      title: 'Descargas',
      today: 'descargas actuales',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'Compras',
      today: 'compras de hoy',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'Saldo',
      link: 'cantidad en espera',
      volume: '${volume}',
      hold: '${volume}'
    }
  },
  programs: {
    title: 'Programas de afiliados',
    columns: {
      name: 'Proyecto',
      description: 'Términos',
      status: 'Estado'
    },
    status: {
      on: 'Activo',
      off: 'No activado'
    },
    conditions: 'Condiciones y planes'
  },
  files_storage_blocks: {
    title: 'Período de almacenamiento de archivos',
    title_link: 'leer más',
    term: 'Período de almacenamiento: <b>{normal_term} days</b> (<i>' + ' <b>{premium_term} days</b> with premium</i>)',
    next_update: 'Próxima actualización de estado {next_update}'
  },
  earnings_table: {
    title: 'Ingresos por meses',
    fields: {
      date: 'Mes',
      money: 'Ganado',
      files_size: 'Tamaño de archivos (GB)*',
      storage_cost: 'Gastos de almacenamiento**'
    },
    last30days_row_title: 'últimos 30 días',
    notes: {
      copies: '* No tenemos en cuenta las copias de archivos (físicos y virtuales).',
      storage_cost: '** El almacenamiento de archivos es totalmente gratuito con espacio ilimitado. Utilizamos el gráfico "Gastos de almacenamiento" para calcular\n' + ' el período de almacenamiento proporcionado.',
      unlimited_period: 'Por lo tanto, para socios con ingresos superiores a 1$ durante los últimos 30 días ' + 'y una variación positiva del ingreso a\n' + ' gastos de almacenamiemto, tiempo ilimitado para los archivos.',
      calculate_cost: 'Evaluamos el espacio en nuestros servidores a $0.016 por 1Gb al mes ' + 'y así se calculan "los gastos del almacenamiento".'
    }
  },
  earnings_chart: { title: 'Ingresos de proyectos' }
};