export default {
  main_block_title: 'Dominios',
  search_placeholder: 'Buscar por dominio',
  dates_placeholder: 'Periodo',
  domain_table: {
    fields: {
      domain: 'Fuentes',
      hits: 'Visitas',
      downloads: 'Descargas',
      unique_downloads: 'Descargas únicas',
      purchases: 'Compras'
    },
    detailed_row_title: 'Estadística detallada:'
  },
  url_table: { fields: { url: 'Enlace' } }
};