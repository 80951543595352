export default {
  today_stat: {
    title: 'إيرادات اليوم',
    sub_title: '(يتم تحديثه تلقائيا كل أربع ساعات)',
    refresh: 'تحديث'
  },
  filters: {
    title: 'مؤشر الإيرادات',
    date_from: 'تاريخ البدء',
    date_to: 'تاريخ الانتهاء',
    group_by: 'المجموعة حسب',
    group: {
      day: 'ألايام',
      week: 'الأسابيع',
      month: 'أشهر'
    },
    switcher: 'الفلاتر'
  },
  gold_status: {
    title: 'برنامج "الشركاء الذهبيين"',
    current_status: 'الحالة الحالية',
    next_status: 'الحالة التالية',
    levels: {
      level_0: 'الشريك العادي',
      level_1: 'الشريك الذهبي',
      level_2: 'الملصق الذهب +{percent}%',
      level_3: 'سيد الذهب +{percent}%',
      level_4: 'المشرف الذهبي +{percent}%',
      level_5: 'بطل الذهب +{percent}%',
      level_6: 'سيد الذهب +{percent}%'
    },
    efficiency: 'الكفاءة {value}',
    coins: '{value} العملات',
    next_level_condition: {
      wait_next_period: 'انتظر لمدة شهر للوصول والتحقيق',
      increase_efficiency: 'زيادة الكفاءة بمقدار {value} للوصول وللتحقيق',
      maximum_level: 'لقد وصلت إلى أعلى مرتبة متوفرة. استمر!',
      need_more_coins: 'يجب عليك إضافة {value} عملةاضافية(s) أخرى للوصول إليها'
    }
  },
  earnings_table: {
    title: 'إلاحصائيات المفصلة',
    fields: {
      date: 'التاريخ',
      files: 'الملفات',
      sites: 'إحالات الموقع',
      referrals: 'الإحالات',
      additional: 'الإضافية',
      total: 'الإجمالي',
      efficiency: 'الكفاءة**',
      coins: 'العملات المعدنية'
    }
  },
  notes: {
    additional: '* إضافات من الأحداث الخاصة، ومستحقات التعويض، وما إلى ذلك.',
    efficiency: '**من فضلك، اقرأ عن <a href=\'{efficiency_url}\'>&laquo;الكفاءة&raquo;</a> ' + '<a href=\'{gold_url}\'>&laquo;برنامج إضافي &laquo;شركاء ذهبي&raquo;</a> في <a href=\'{faq_url}\'>الأسئلة الشائعة</a>'
  }
};