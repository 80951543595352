import Vue from "vue";

export default {
  namespaced: true,
  state: {},
  actions: {
    sso(module, params) {
      return Vue.axios.get("/zendesk/sso", {params});
    },
  }
};
