export default {
  email_required: 'مطلوب عنوان البريد الإلكتروني',
  password_required: 'مطلوب كلمة مرور',
  enter_captcha: 'الرجاء اجتياز التسميات التوضيحية للتحقق',
  login: 'تسجيل الدخول',
  account_was_blocked: 'الحساب المختار فعال ولا يحتاج إلى استعادة.',
  account_unblocking: 'رفع الحظر عن الحساب',
  restore: 'استعادة',
  unblock: 'رفع الحظر',
  invalid_link: 'الرابط غير صالح. يرجى الاتصال بالمدير.',
  account_is_unblocked: 'تم إلغاء حظر حسابك. الآن يمكنك تسجيل الدخول.',
  account_restore_email_sent: 'لاستعادة الحساب ، يرجى اتباع الرابط الذي تم إرساله إلى عنوان بريدك الإلكتروني',
  email: 'البريد الإلكتروني',
  password: 'كلمه السر',
  email_must_be_valid: 'يجب أن يكون عنوان البريد الإلكتروني صالحًا',
  forgot_password: 'هل نسيت كلمة السر؟',
  create_new_account: 'انشاء حساب جديد',
  sign_in_to_your_account: 'تسجيل الدخول إلى Costaction',
  sign_in: 'تسجيل الدخول',
  agree_with_terms_of_site: 'موافق على شروط الخدمة',
  subscribe_to_newsletter: 'اشترك في النشرة الإخبارية',
  sign_up_now: 'قم بالتسجيل الآن!',
  required: 'مطلوب',
  register_confirm_sent_success: 'يرجى التحقق من صندوق الوارد الخاص بك ، لقد أرسلنا رابط تأكيد إلى بريدك الإلكتروني',
  account_activation: 'تفعيل الحساب',
  activation_in_progress: 'التفعيل قيد العمل ، الرجاء الانتظار',
  invalid_token: 'انتهت صلاحية رابط التحقق الخاص بك',
  register_success_message: 'تم تفعيل حسابك بنجاح ، يرجى تسجيل الدخول',
  password_recovery: 'استعادة كلمة المرور',
  recovering_password: 'استرجاع كلمة السر',
  invalid_recover_password_link: 'هذا الرابط لكلمة المرور الجديدة غير مفعل. الرجاء التقديم الى ' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>خدمة الدعم</a>إذا كان لديك أي صعوبات مع ' + 'استعادة كلمة السر.',
  password_changed_success: 'تم تغيير كلمة السر بنجاح. لقد أرسلنا كلمة مرور جديدة إلى البريد الإلكتروني. ' + 'كما يمكنك تغييرها إلى واحدة جديدة.',
  recovery_password_link_sent: 'تم إرسال رسالة تحتوي على رابط إعادة تعيين كلمة المرور إلى عنوان بريدك الإلكتروني.'
};