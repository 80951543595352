export default {
  today_stat: {
    title: 'Estadística para hoy',
    sub_title: '(se actualiza automáticamente cada cuatro horas)',
    refresh: 'Actualizar',
    gold: 'bonificación Gold'
  },
  chart: {
    select: { label: 'Gráfico' },
    title: 'Filtros y gráfico diario'
  },
  table: {
    title: 'Estadística detallada',
    fields: {
      date: 'Fecha',
      downloads: 'Descargas',
      sum: 'Suma $',
      avg_sales: 'Ventas AVG $',
      sales: 'Ventas',
      gold: 'bonificación Gold $',
      copies: 'Copias*',
      total: 'Total',
      avg_download_amount: 'AVG por 1000 descargas**'
    }
  },
  sub_table: {
    title: 'Estadísticas detalladas: {date}',
    fields: {
      countries: 'Países/Grupos',
      sum: 'Suma + bonificación Gold $'
    },
    countries: {
      A: 'Grupo A',
      B: 'Grupo B',
      C: 'Grupo C',
      D: 'Grupo D',
      E: 'Grupo E',
      F: 'Grupo F',
      G: 'Grupo G',
      R: 'Rusia',
      U: 'Ucrania'
    }
  },
  notes: {
    copies: '*Ganancias en tus archivos <a href=\'{copies_url}\'>copias</a>, hechas por otros usuarios.',
    avg_downloads: '**La columna \'AVG por cada 1000 descargas\' muestra la cantidad media de 1.000 descargas únicas de tu ' + 'archivos y compras de acceso Premium'
  }
};