export default {
  bonus_program: 'Programme bonus',
  gold_partners: '"Partenaires Gold"',
  golden_partner: 'Statut Gold',
  page_subtitle: 'Les partenaires réguliers et efficaces peuvent obtenir <strong>Gold</strong><br />' + 'et recevez un revenu supplémentaire jusqu\'à <strong>+25%</strong> du revenu actuel.',
  how_to_get_gold_status: 'Comment obtenir le statut Gold',
  how_to_get_gold_status_subtitle: 'Vous deviendrez <strong>Partenaire Gold</strong> si vous gagnez des revenus ' + 'pendant le mois est plus élevé que "les fichiers stockant le prix" reflété sur la page statistique.',
  earnings: 'Revenus',
  efficiency_is_above: 'l\'efficacité est supérieure à {value}',
  how_to_get_gold_status_line: 'Pour analyser la réussite effectuée, veuillez passer à la page {link} ' + 'où les statistiques et l\'efficacité des 30 derniers jours sont affichées. ' + 'À la fin du mois, si {efficiency} est au ' + 'niveau approprié, le statut {gold_partner} sera obtenu automatiquement et ' + 'vous recevrez un bonus supplémentaire le mois suivant.',
  how_to_increase_extra_bonus_amount: 'Comment faire évoluer le statut Gold et accroître vos revenus',
  how_to_increase_extra_bonus_amount_subtitle: 'Comme vous avez obtenu le statut Gold, vous avez exercé une activité qui ' + 'accordera des Pièces virtuelles, qui amélioreront automatiquement votre statut vers le haut.',
  how_to_increase_extra_bonus_amount_line_1: '1 pièce sera obtenue si les 24 heures en cours que vous avez autant de téléchargements ou plus que pour les précédents ' + '72 heures, par période moyenne de 24 heures (pour les téléchargements).',
  how_to_increase_extra_bonus_amount_line_2: '1 pièce sera obtenue si les 24 heures en cours que vous avez autant de téléchargements ou plus que pour les précédents ' + '72 heures par période moyenne de 24 heures (pour les ventes).',
  how_to_increase_extra_bonus_amount_line_3: '1 pièce est retirée si l\'efficacité est constamment inférieure à 0 dans les 30 derniers jours',
  comparative_table_of_partners_statuses: 'Statuts et privilèges',
  comparative_table_of_partners_statuses_subtitle: 'Restez actif pour obtenez plus de pièces et ' + 'augmentez votre revenu. Plus vous obtenez de pièces, plus le statut = plus de revenus.',
  comparative_table_of_partners_statuses_hint_1: 'Important ! Le statut ne baissera pas même si vous n\'avez pas assez de pièces. Si le nombre de pièces accumulées ' + 'est inférieur à ce qu\'il doit être pour votre statut actuel et le statut avant lui, seulement alors votre statut actuel Gold ' + 'est rétrogradés. <br />A titre d\'exemple, si vous avez eu le statut de "Magnat Gold", il ' + 'ne se fait rétrograder qu\'après que le nombre de pièces tombe en dessous de 80.',
  comparative_table_of_partners_statuses_hint_2: 'Vous perdez le statut de partenaire Gold si à la fin du mois ' + 'l\'efficacité est inférieure à 0 et vous avez 0 pièces.',
  comparative_table_of_partners_statuses_hint_3: 'Achats non sécurisés, avec le statut "fraude" ou "remboursé" ' + 'ne sera pas pris en compte en accumulant les pièces.',
  status: 'Statut',
  efficiency: 'Efficacité<br />au début du mois',
  coins: 'Nombre de pièces<br />à la fin de la journée',
  percent_of_earnings: '% de revenus'
};