export default {
  premium_title: "Премиум {premium_sub_title}",
  premium_sub_title: "Видеоплеер",
  premium_video_sub_title: "Автоматически конвертируйте свои видео-файлы для онлайн просмотра, встраивайте плеер</br>на веб-страницы и получайте прибыль с продаж премиума.",
  player_text:
          'Онлайн просмотр доступен для всех видео-файлов, которые{br}' +
          'прошли процесс конвертации.{br}{br}' +
          'Плеер по умолчанию отображается на странице{br}' +
          'скачивания, а также может быть размещен вами на любой веб-странице ' +
          'при помощи встраивания через {embed_code}.{br}{br}' +
          'Обычному пользователю доступен предпросмотр{br}' +
          'части видео от 1 до 3 минут, для продолжения просмотра {br}' +
          'необходимо приобрести премиум доступ.',
  embed_code: "Embed-код",
  tools: "Инструменты вебмастера",
  convert_video: "Конвертация видео",
  oauth_title: "OAuth авторизация и возможность использования своего видео-плеера",
  oauth_text:
          "Для опытных партнеров мы предлагаем возможность использовать свой " +
          "собственный видео плеер и получать ссылки на видео посредством API. Любой " +
          "пользователь может авторизоваться на вашем веб-сайте через наш протокол OAuth, " +
          "а затем на основе специального идентификатора ему будут предоставлены ссылки на файлы. {br}" +
          "Для получения более подробной информации, пожалуйста, обратитесь в нашу {support_link}",
  support_link: "службу поддержки",
  convert_video_text_1: "Выберите один из режимов - ручной или автоматический. Время конвертации может варьироваться в зависимости от загруженности серверов, формата/размера вашего файла.",
  manual_level: "Ручной режим",
  manual_level_text:
          'Перейдите в {manage_file_link}, выберите нужный файл и в колонке “Видео” нажмите на иконку {iconPlus}{br}' +
          'Либо выделите сразу несколько файлов в панели быстрых действий и нажмите на кнопку {iconVideoPlus}',
  manager_files: "Менеджер файлов",
  auto_level: "Автоматический режим",
  auto_level_text:
          'Перейдите в {manage_file_link}, откройте “Настройки” нажав на иконку {iconSettings} в правом верхнем углу.{br}' +
          'Для всех партнеров по умолчанию активирована опция “Конвертировать все популярные файлы”, также дополнительно рекомендуем активировать опцию “Конвертировать все файлы”.',
  public_video_pay: "Публикация видео и заработок",
  embed_version: "Embed-версия",
  embed_version_text:
          'Для размещения видео-плеера на вашем сайте, необходимо использовать {embed_code}, который можно получить в {br}' +
          '{manage_file_link}. Код доступен в блоке “Получение ссылок”, даже если видео-файл{br}' +
          'всё еще находится в процессе конвертации.{br}' +
          'Дополнительно к плееру вы можете сгенерировать превью изображения в двух форматах - основной кадр и набор кадров 3x3.',
  earnings: 'Заработок с продаж',
  earnings_text_1:
          'При переходе из плеера на страницу покупки премиум доступа, мы фиксируем ID файла и начисляем доход с продажи<br>' +
          'владельцу файла. Процент отчислений зависит от выбранного плана заработка.<br>',
  earnings_text_2:
          '<strong>Для плана PPS - 60% с продажи и 40% с ребиллов, для Mixed до 40% с продажи и до 30% c ребиллов.</strong>>',
  example_video: "Пример видеоплеера",
  addition_info: "Дополнительная информация",
  video_text: "Если у вас нет премиум доступа, вам будет предложено его купить после 1-3 минут<br>бесплатного предпросмотра видео.",
  faq: {
    what_kind_video: "В каком качестве будет доступно видео?"
  }
};
