export default {
  header_subtitle_1: 'Merci de nous avoir rejoint! Plongeons dans le processus de gestion du compte de votre partenaire.',
  header_subtitle_2: 'Veuillez suivre les étapes ci-dessous pour soumettre toutes les informations requises.',
  skip_step: 'Ignorer l\'étape',
  done: 'Terminé',
  add_payment_details: 'Soumettre les détails du porte-monnaie pour qualifier une demande de paiement',
  next_step: 'Prochaine étape',
  step1: {
    title: 'Ajoutez les détails de votre porte-monnaie à la page «Paiements». ',
    description: 'Vous pouvez utiliser des systèmes tels que PayPal, Webmoney, Bitcoin, Payoneer, Qiwi et bien d\'autres !',
    hint: 'Si vous n\'avez pas encore de porte-monnaie, vous pouvez passer cette étape et l\'ajouter plus tard.'
  },
  step2: {
    title: 'Activez le compte turbobit.net et/ou hitfile.net.',
    description: 'Sélectionnez le plan d\'affiliation en cliquant sur «Activer» dans l\'onglet «Programme d\'affiliation», ou utilisez les boutons ci-dessous:',
    hint: 'Après activation, toutes les données envoyées seront envoyées à votre adresse e-mail.'
  },
  step3: {
    title: 'Sélectionnez un plan d\'affiliation',
    description1: 'En savoir plus sur tous les plans d\'affiliation disponibles sur ' + 'La page «Conditions et Plans» et décidez de celle qui vous convient le mieux.',
    description2: 'Vous pouvez vérifier et modifier votre plan d\'affiliation sur la page «Paramètres» pour chaque projet.',
    hint: 'Le plan d’affiliation peut être changé une fois par semaine.'
  },
  step4: {
    title: ' Charger le fichier sur turbobit.net et/ou hitfile.net ',
    description1: 'Connectez-vous avec votre email actuel à turbobit.net/hitfile.net et chargez les fichiers.',
    description2: 'Ou sélectionnez le tout nouveau «Gestionnaire de fichiers» disponible dans le menu «Outils» ' + 'pour fonctionner avec des fichiers plus efficacement (recommandé).'
  },
  step5: {
    title: 'Félicitations, votre compte est activé. ',
    description1: 'Continuez, chargez et partagez vos fichiers pour générer des revenus pour chaque téléchargement ou/et vente, ' + 'selon le plan d\'affiliation sélectionné ! ',
    description2: 'Si vous avez d\'autres questions ou problèmes nécessitant de l\'aide, lisez la FAQ ou contactez-nous. {url}'
  }
};