export default {
  data_table: {
    fields: {
      date: 'Date',
      email: 'E-mail',
      file: 'Fichier',
      source: 'Source',
      payment_system: 'Méthodes de paiement',
      tariff: 'Type d\'adhésion',
      file_sales: 'Recettes $',
      site_sales: 'Revenus de référencement du site',
      status: 'Statut*'
    },
    notes: {
      note1: '* En savoir plus sur les statuts {hold}, {void} et {refund} dans nos {faq}',
      status_hold: 'Attente',
      status_void: 'Néant',
      status_refund: 'Rembourser',
      note2: '** Le tableau affiche les données des deux dernières années',
      type_rebill: '- refacturer',
      type_copy: '- depuis la copie du fichier',
      type_promo: '- lien promotionnel ou bannière'
    }
  },
  file_money_type: {
    direct: 'Direct',
    rebill: 'Refacturer',
    copy: 'Depuis la copie du fichier'
  },
  refresh: 'Actualiser',
  filters: 'Filtres',
  sites: 'Sites',
  files: 'Fichiers',
  files_copies: 'Copies de fichiers $',
  status: 'Statut',
  payment_system: 'Méthodes de paiement',
  all: 'Tout',
  date_from_placeholder: 'Date de début ',
  date_to_placeholder: 'Date de fin',
  promo_banner_or_link: 'Lien promotionnel',
  site_referral: 'Sites_références',
  unknown: 'Inconnu'
};