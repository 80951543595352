import Vue from "vue";

export default {
  namespaced: true,
  state: {
    purses: [],
    pursesNotConfirmed: [],
    cardCurrencies: [],
    pursesTypes: [],
    activePursesTypes: [],
    purseHoldPeriod: null,
    turkishBanks: [],
    availableWithdrawal: null,
    holdInfo: null,
    reloadPayoutsListTrigger: false
  },
  mutations: {
    triggerReloadPayoutsList(state, val) {
      state.reloadPayoutsListTrigger = val;
    },
    setPurses(state, purses) {
      state.purses = purses;
    },
    setPursesNotConfirmed(state, purses) {
      state.pursesNotConfirmed = purses;
    },
    setCardCurrencies(state, currencies) {
      state.cardCurrencies = currencies;
    },
    setPursesTypes(state, pursesTypes) {
      state.pursesTypes = pursesTypes;
    },
    setActivePursesTypes(state, pursesTypes) {
      state.activePursesTypes = pursesTypes;
    },
    setPurseHoldPeriod(state, purseHoldPeriod) {
      state.purseHoldPeriod = purseHoldPeriod;
    },
    setTurkishBanks(state, banks) {
      state.turkishBanks = banks;
    },
    setAvailableWithdrawal(state, amount) {
      state.availableWithdrawal = amount;
    },
    setHoldInfo(state, info) {
      state.holdInfo = info;
    }
  },
  actions: {
    loadUserPurses({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/purses")
          .then(response => {
            commit("setPurses", response.data.purses);
            commit("setPursesNotConfirmed", response.data.purses_not_confirmed);
            resolve(response.data.purses);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    loadPayoutInfo({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/payouts/info")
          .then(response => {
            commit("setCardCurrencies", response.data.card_currencies);
            commit("setPursesTypes", response.data.purses_types);
            commit("setActivePursesTypes", response.data.active_purses_types);
            commit("setPurseHoldPeriod", response.data.hold_period);
            commit("setTurkishBanks", response.data.turkish_banks);
            resolve(response.data.types);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deletePurse(module, purseId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("/purse/" + purseId)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    setPurseMain(module, purseId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/purse/" + purseId + "/main")
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    addPurse(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/purse", data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    confirmPurse(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/purse/confirm/" + data.code)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    loadAvailableWithdrawal({ commit }, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/payouts/user/available-withdrawal", data)
          .then(response => {
            commit("setAvailableWithdrawal", response.data.amount);
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    loadHoldInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("/payouts/user/hold-info", data)
          .then(response => {
            commit("setHoldInfo", response.data);

            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    loadUserPayouts(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(
            "/payouts?page=" +
              data.page +
              "&per_page=" +
              data.per_page +
              "&sort_key=" +
              data.sort.key +
              "&sort_dir=" +
              data.sort.dir
          )
          .then(response => {
            resolve({
              payouts: response.data.data,
              meta: response.data.meta
            });
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    createPayoutRequest(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("/payouts", data)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
    deletePayout(module, payoutId) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete("/payout/" + payoutId)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    }
  }
};
