export default {
  today_stat: {
    title: 'إحصاءات اليوم',
    sub_title: '(يتم تحديثه تلقائيا كل أربع ساعات)',
    refresh: 'تحديث',
    gold: 'مكافأة ذهبية'
  },
  chart: {
    select: { label: 'الرسوم البيانية' },
    title: 'الرسم البياني اليومي والفلاتر'
  },
  table: {
    title: 'إلاحصائيات المفصلة',
    fields: {
      date: 'التاريخ',
      downloads: 'التحميلات',
      sum: 'المجموع $',
      avg_sales: 'مبيعات AVG $',
      sales: 'المبيعات',
      gold: 'مكافأة ذهبية $',
      copies: 'النسخ*',
      total: 'الإجمالي',
      avg_download_amount: 'AVG لكل 1000 تنزيل**'
    }
  },
  sub_table: {
    title: 'إلاحصائيات المفصلة: {date}',
    fields: {
      countries: 'البلدان / المجموعات',
      sum: 'المجموع + مكافاة ذهبية $'
    },
    countries: {
      A: 'المجموعة A',
      B: 'المجموعة B',
      C: 'المجموعة C',
      D: 'المجموعة D',
      E: 'المجموعة E',
      F: 'المجموعة F',
      G: 'المجموعة G',
      R: 'الروسية',
      U: 'أوكرانيا'
    }
  },
  notes: {
    copies: '*الأرباح على ملفاتك <a href=\'{copies_url}\'>نسخ</a>، التي حققها مستخدمون آخرون.',
    avg_downloads: '**العمود \'AVG لكل 1000 تنزيلات\' يعرض متوسط المبلغ ل 1.000 تنزيل فريد من نوعك ' + 'الملفات والمشتريات من الوصول المميز'
  }
};