export default {
  data_table: {
    fields: {
      date: "Date",
      email: "Email",
      file: "File",
      source: "Source",
      payment_system: "Payment method",
      tariff: "Membership type",
      file_sales: "Revenue $",
      site_sales: "Site referrals revenue $",
      status: "Status*"
    },
    notes: {
      note1: "* Please read more about {hold}, {void} and {refund} statuses in our {faq}",
      status_hold: "Hold",
      status_void: "Void",
      status_refund: "Refund",
      note2: "** The table displays data for the past two years",
      type_rebill: "- rebill",
      type_copy: "- from file copy",
      type_promo: "- promo link or banner"
    }
  },
  file_money_type: {
    direct: "Direct",
    rebill: "Rebill",
    copy: "Via file copy"
  },
  refresh: "Refresh",
  filters: "Filters",
  sites: "Sites",
  files: "Files",
  files_copies: "File copies $",
  status: "Status",
  payment_system: "Payment method",
  all: "All",
  date_from_placeholder: "Start date",
  date_to_placeholder: "End date",
  promo_banner_or_link: "Promo link",
  site_referral: "Site referrals",
  unknown: "Unknown"
};
