export default {
  security_frozen_period_notification:
    "Внимание, ваш защитный код был изменен {changedAt}<br />" + "Это действие будет разрешено {allowedAt}",
  security_check: "Защитный код",
  security_code: "Защитный код",
  enter_security_code: "Введите Защитный код",
  wrong_code: "Неверный Защитный код",
  password_changed_successfully: "Ваш пароль был успешно изменен!",
  password_changing_url_is_invalid: "Ссылка для смены пароля больше не действительна!",
  sections: {
    dashboard: "Дашборд",
    statistics: "Статистика",
    stats: {
      sources: "Источники",
      sales: "Продажи",
      files: "Файлы",
      "files-copies": "Копии файлов",
      detailed: "Подробная статистика"
    },
    earnings: "Доход",
    videos: "Видео",
    referrals: "Рефералы",
    sites: "Мои сайты"
  },
  notifications: "Уведомления",
  view_notifications: "Просмотр уведомлений",
  mark_as_read: "Отметить как прочитанные",
  messages_and_notifications: "Сообщения и уведомления",
  news: "Новости",
  news_and_updates: "Новости и обновления",
  file_manager: "Менеджер файлов",
  menu: {
    tools: "Войти на",
    fd1_login: "Войти на turbobit.net",
    fd2_login: "Войти на hitfile.net"
  },
  help: "Помощь",
  faq: "FAQ",
  support_center: "Служба поддержки",
  additional_information_faq: "Дополнительная информация (FAQ)",
  activation_in_process: "Активация в процессе",
  feedback_link_text: "Что-то не работает? Напишите нам",
  feedback_link: "https://help2.costaction.com",
  faq_categories: {
    "File upload": "Загрузка файлов",
    Payouts: "Выплаты",
    "Site referals": "Сайты рефералы",
    "Downloads and statistics": "Скачивания и статистика",
    Purchases: "Покупки",
    "Other questions ": "Другие вопросы ",
    "Video player": "Видео плеер"
  },
  get_started: "Начало работы с партнерской программой",
  sidebar_nav: {
    dashboard: "Дашборд",
    page_video: "Видеоплеер",
    payouts: "Выплаты",
    turbobit_net: "Turbobit.net",
    statistics: "Статистика",
    detailed: "Подробно",
    files: "Файлы",
    videos: "Видео",
    sales: "Продажи",
    sources: "Источники",
    files_copies: "Копии файлов",
    earnings: "Доход",
    gold_partners: "Золотые партнеры",
    my_sites: "Мои сайты",
    referrals: "Рефералы",
    hitfile_net: "Hitfile.net",
    activate: "Активировать",
    conditions: "Условия и планы",
    affiliate_programs: "Партнерские программы"
  },
  months: {
    full: {
      1: "Январь",
      2: "Февраль",
      3: "Март",
      4: "Апрель",
      5: "Май",
      6: "Июнь",
      7: "Июль",
      8: "Август",
      9: "Сентябрь",
      10: "Октябрь",
      11: "Ноябрь",
      12: "Декабрь"
    },
    short: {
      1: "Янв",
      2: "Фев",
      3: "Мар",
      4: "Апр",
      5: "Май",
      6: "Июн",
      7: "Июл",
      8: "Авг",
      9: "Сен",
      10: "Окт",
      11: "Ноя",
      12: "Дек"
    }
  },
  need_security_password: "Необходимо ввести пароль от аккаунта",
  enter_security_password: "Введите пароль",
  wrong_password: "Неверный пароль",
  password_check: "Проверка пароля",
  follow_us_on_telegram: "Подписывайтесь на наш Телеграм!",
  logout: "Выйти",
  
  api: "API",
  api_for_webmasters: "API для вебмастеров",
  api_key_has_been_sent_to_email: "Ключ отправлен на ваш email",
  get_api_key: "Получить ключ",
  api_usage_description: "Документация и примеры кода доступны по ссылке <a target='_blank' href='{link}'>{link}</a><br />" +
    "Ключ авторизации будет отправлен на вашу почту<br />" +
    "При получении нового ключа старый ключ автоматически удаляется",
};
