export default {
  hints: 'Dicas',
  hint_plus: 'Arquivos carregados há menos de 10 dias; Arquivos têm mais de 100 downloads; Arquivos com 1 ou mais compras.' + 'Esses arquivos só podem melhorar sua eficiência',
  hint_arrow_up: 'Arquivos rentáveis e eficientes',
  hint_arrow_down: 'Arquivos ineficazes e não rentáveis',
  hint_minus: 'Arquivos extremamente não rentáveis diminuindo drasticamente sua eficiência',
  data_table: {
    fields: {
      name: 'Nome do arquivo (pasta)',
      size: 'Tamanho',
      created_date: 'Data de inclusão',
      download_count_today: 'Downloads hoje',
      download_count_total: 'Total de downloads',
      money_today: 'Ganho de hoje',
      money_total: 'Total de ganhos',
      premium_count_today: 'Vendas hoje',
      premium_count_total: 'Total das vendas'
    }
  },
  inbox: 'Caixa de Entrada',
  show_hints: 'Exibição de dicas',
  folder: 'Pasta',
  find_by_name: 'Pesquisar pelo nome',
  all: 'Todos',
  detailed_statistics_by_files: 'Estatísticas detalhadas para arquivos',
  // files copies
  detailed_statistics_by_file_copies: 'Estatísticas detalhadas para cópias de arquivos'
};