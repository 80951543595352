export default {
  summary: 'الخلاصة',
  total_referrals: 'مجموع الإحالات',
  first_level: 'المستوى الاول',
  second_level: 'المستوى الثاني',
  total_earned: 'إجمالي المكتسب',
  total_earned_value: '${value}',
  detailed_statistics: 'إلاحصائيات المفصلة',
  data_table: {
    fields: {
      user: 'المستخدم',
      referral_level: 'مستوى الإحالة',
      registration_date: 'تاريخ التسجيل',
      today_earnings: 'تم اكتسابها اليوم',
      weekly_earnings: 'الأرباح الأسبوعية',
      total_earnings: 'إجمالي الأرباح',
      ref_percent: 'المرجع بالنسبة المئوية'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'المستخدمون مع الأرباح',
  search_by_user: 'البحث بواسطة اسم المستخدم',
  referrals_promo_meterials: 'إحالة المواد الترويجية',
  referral_link: 'رابط الدعوة للإحالة',
  referrals_banners: 'لافتات الإحالة',
  banner_code: 'رمز البانر'
};