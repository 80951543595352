export default {
  email_required: 'E-posta zorunludur',
  password_required: 'Parola gerekli',
  enter_captcha: 'Lütfen captcha doğrulamasını geçiniz',
  login: 'Giriş yap',
  account_was_blocked: 'Seçilen hesap etkindir ve geri yükleme gerektirmez.',
  account_unblocking: 'Hesap kilidinin kaldırılması',
  restore: 'Geri yükle',
  unblock: 'Engellemeyi kaldır',
  invalid_link: 'Geçersiz bağlantı. Lütfen yönetici ile iletişime geçiniz.',
  account_is_unblocked: 'Hesabınız açılmıştır. Şimdi giriş yapabilirsiniz.',
  account_restore_email_sent: 'Hesabı kurtarmak için lütfen e-posta adresinize gönderilen bağlantıyı takip edin',
  email: 'E-posta',
  password: 'Şifre',
  email_must_be_valid: 'Geçerli bir e-posta adresi olmalıdır.',
  forgot_password: 'Şifrenizi mi unuttunuz?',
  create_new_account: 'Yeni Hesap Oluşturun',
  sign_in_to_your_account: 'Costaction\'a Giriş yapın',
  sign_in: 'Giriş yap',
  agree_with_terms_of_site: 'Hizmet Şartlarını Kabul Edin',
  subscribe_to_newsletter: 'Sunucu içeriğine üye ol',
  sign_up_now: 'Şimdi kayıt olun!',
  required: 'Gerekli',
  register_confirm_sent_success: 'Lütfen gelen kutunuzu kontrol ediniz, doğrulama linkini e-posta adresine gönderdik',
  account_activation: 'Hesap etkinleştirme',
  activation_in_progress: 'Aktivasyon devam ediyor, lütfen bekleyin...',
  invalid_token: ' Doğrulama linki zaman aşımına uğradı',
  register_success_message: 'Hesabınız başarıyla etkinleştirildi, lütfen giriş yapın',
  password_recovery: 'Şifre kurtarma',
  recovering_password: 'Şifreyi kurtar',
  invalid_recover_password_link: 'Yeni parola için bu bağlantı etkin değil. Lütfen başvuruda bulunun' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>destek</a> herhangi bir sorunla karşılaşırsanız ' + 'şifre kurtarma.',
  password_changed_success: 'Şifreniz başarı ile değiştirildi. Lütfen oturum açmak için yeni şifrenizi kullanınız. ' + 'Ayrıca yenisiyle değiştirebilirsiniz.',
  recovery_password_link_sent: 'E-posta adresinize parola sıfırlama bağlantısı içeren bir mesaj gönderildi.'
};