export default {
  payout_details: "تفاصيل الدفع",
  payout_details_subtitle:
    "لأسباب أمنية، يجب أن تكون تفاصيل الدفع الجديدة والمحدثة " +
    "تم التحقق من ذلك بالبريد الإلكتروني ودخل حيز النفاذ بعد {days} أيام من فترة الانتظار.",
  type: "النوع",
  details: "التفاصيل",
  create_date: "التاريخ",
  fee_min_payout: "الرسوم / الحد الأدنى للدفع",
  status: "الحالة",
  action: "الإجراء",
  active: "مفعل",
  on_hold: "قيد التعليق ({date})",
  waiting_email_confirmation: "في انتظار تأكيد البريد الإلكتروني",
  are_you_sure_you_want_to_delete_purse: "هل أنت متأكد أنك تريد حذف هذه المحفظة؟",
  main_purse_can_not_be_deleted:
    "لا يمكن إزالة المحفظة الرئيسية. حدد المحفظة التي تم التحقق منها وتعيينها كالمحفظة الرئيسية لتكون قادر على إزالة كل البقية",
  add_new: "تقديم",
  add_new_payout_details: "تقديم تفاصيل الدفع الجديدة",
  payout_system: "نظام الدفع",
  month: "الشهر",
  year: "السنة",
  first_name: "الاسم الأول",
  last_name: "الإسم العائلي",
  currency: "العملة",
  iban: "رقم الحساب المصرفي الدولي - IBAN",
  bank_name: "اسم البنك",
  set_as_main: "تعيين كحافظة رئيسية",
  delete_wallet: "إزالة",
  no_purses: "لم تقم بإنشاء أي محفظات حتى الان",
  confirming_purse: "التفعيل قيد العمل ، الرجاء الانتظار...",
  url_is_invalid: "عنوان URL غير صالح أو انتهت صلاحيته. الرجاء المحاولة مرة أخرى",
  purse_is_confirmed: "تم بنجاح التحقق من تفاصيل الدفع",
  available_withdrawal: "متاح للدفع: {amount} دولار أمريكي",
  new_request: "الطلب الجديد",
  amount_in_hold: "المبلغ المعلق",
  what_is_hold: "ما هو المعلق؟",
  files: "الملفات",
  referrals: "الإحالات",
  sites_referrals: "إحالات- المواقع",
  file_copies: "الملفات المنسوخة",
  request_payout: "طلب الدفع",
  send_request: "تقديم الطلب",
  request_payout_amount_field_label: "المبلغ",
  Fee: "الرسوم",
  Min_payout_is: "الحد الأدنى للدفع هو",
  exchange_by_rates: "سعر صرف الدولار الأمريكي/سعر الصرف الوطني من قبل مصرف روسيا",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "هل أنت متأكد أنك تريد حذف هذه الطلب؟",
  request_payout_errors: {
    purse_is_on_hold_one: "تم تغيير تفاصيل الدفع. يمكن إرسال طلب جديد بعد {date}",
    purse_is_on_hold_many:
      "تم تغيير تفاصيل الدفع. يمكن إرسال طلب جديد بعد {date}" + " أو تعيين محفظة المؤكدة في الإعدادات"
  },
  payouts: "المدفوعات",
  payouts_subtitle:
    "تتم معالجة طلبات الدفع عادةً خلال 7 أيام في المتوسط، ولكن في حالات انخفاض الطلب، قد يكون وقت المعالجة أسرع.",
  payout_table: {
    fields: {
      created_date: "التاريخ",
      amount: "المبلغ",
      payment_details: "تفاصيل المحفظة",
      status: "الحالة",
      action: "الإجراء"
    }
  },
  payouts_status_label: {
    created: "تم الإنشاء",
    review: "جاري التنفيذ",
    process: "جارٍ معالجة عملية الدفع",
    accepted: "اكتمل",
    rejected: "تم الرفض",
    deleted: "المحذوفة"
  },
  submit_payouts_details: "حفظ"
};
