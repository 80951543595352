export default {
  cancel: "Отменить",
  confirm: "Подтвердить",
  close: "Закрыть",
  save: "Сохранить",
  all_fields_are_required: "* все поля обязательны для заполнения",
  ok: "Готово",
  loading: "Загрузка...",
  wait: "Подождите...",
  delete: "Удалить",
  reset: "Сбросить",
  refresh: "Обновить",
  continue: "Далее",
  skip: "Пропустить",
  done: "Готово",
  send: "Далее",
  filters: "Фильтры",
  tables: { total: "Всего" },
  error: "Ошибка",
  try_later: "Возникла ошибка. Попробуйте позже",
  form: {
    errors: {
      required: "{field} обязательно для заполнения",
      number: "Пожалуйста, введите допустимое число",
      max: "Значение должно быть меньше или равно {value}",
      min: "значение должно быть больше или равно 40.00"
    }
  },
  email: "Email",
  all: "Все",
  max: "макс",
};
