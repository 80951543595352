export default {
  email_required: 'E-Mail ist erforderlich',
  password_required: 'Das Passwort ist erforderlich',
  enter_captcha: 'Bitte Captcha-Verifizierung bestehen',
  login: 'Einloggen',
  account_was_blocked: 'Das ausgewählte Konto ist aktiv und erfordert keine Wiederherstellung.',
  account_unblocking: 'Konto entsperrt',
  restore: 'Wiederherstellung',
  unblock: 'Entsperren',
  invalid_link: 'Ungültiger Link. Bitte kontaktieren Sie den Administrator.',
  account_is_unblocked: 'Ihr Konto ist entsperrt. Jetzt können Sie sich anmelden.',
  account_restore_email_sent: 'Um ein Konto wiederherzustellen, folgen Sie bitte dem Link, der an Ihre E-Mail-Adresse gesendet wurde',
  email: 'E-Mail',
  password: 'Passwort',
  email_must_be_valid: 'Es muss eine gültige Email Adresse angegeben werden.',
  forgot_password: 'Passwort vergessen?',
  create_new_account: 'Einen neues Konto erstellen',
  sign_in_to_your_account: 'Bei Costaction anmelden',
  sign_in: 'Anmelden',
  agree_with_terms_of_site: 'Ich stimme den Nutzungsbedingungen zu',
  subscribe_to_newsletter: 'Newsletter abonnieren',
  sign_up_now: 'Jetzt anmelden',
  required: 'Benötigt',
  register_confirm_sent_success: 'Bitte überprüfe Deinen Posteingang, wir haben einen Bestätigungslink an deine E-Mail gesendet',
  account_activation: 'Konto-Aktivierung',
  activation_in_progress: 'Aktivierung in Bearbeitung, bitte warten...',
  invalid_token: ' Dieses Bestätigungslink ist abgelaufen',
  register_success_message: 'Ihr Konto wurde erfolgreich aktiviert, bitte anmelden',
  password_recovery: 'Passwortwiederherstellung',
  recovering_password: 'Passwort wiederherstellen',
  invalid_recover_password_link: 'Dieser Link für neues Passwort ist nicht aktiv. Bitte anwenden auf ' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>Unterstützung</a> bei Problemen ' + 'Kennwort-Wiederherstellung.',
  password_changed_success: 'Ihr Passwort wurde erfolgreich geändert. Wir haben ein neues Passwort an E-Mail gesendet. ' + 'Sie können es auch in eine neue ändern.',
  recovery_password_link_sent: 'Eine E-Mail mit Anweisungen zum Zurücksetzen des Passwortes wurde an Ihre E-Mail Adresse gesendet.'
};