export default {
  security_frozen_period_notification:
    "Attention, your security code has been changed {changedAt}<br />" +
    "This action will be admitted after {allowedAt}",
  security_check: "Security check",
  security_code: "Security code",
  enter_security_code: "Enter the security code",
  wrong_code: "Incorrect security code",
  password_changed_successfully: "Your password has been successfully changed!",
  password_changing_url_is_invalid: "The link to change your password is no longer valid!",
  sections: {
    dashboard: "Dashboard",
    statistics: "Statistics",
    stats: {
      sources: "Sources",
      sales: "Purchases",
      files: "Files",
      "files-copies": "Files Copies",
      detailed: "Detailed Statistics"
    },
    earnings: "Revenue",
    videos: "Videos",
    referrals: "Referrals",
    sites: "My sites",
  },
  notifications: "Notifications",
  view_notifications: "View all notifications",
  mark_as_read: "Mark as read",
  messages_and_notifications: "Messages and notifications",
  news: "News",
  news_and_updates: "News and updates",
  file_manager: "File manager",
  menu: {
    tools: "Login to",
    fd1_login: "Login to turbobit.net",
    fd2_login: "Login to hitfile.net"
  },
  help: "Help",
  faq: "FAQ",
  support_center: "Support center",
  additional_information_faq: "Additional Information (FAQ)",
  activation_in_process: "Activation in process",
  feedback_link_text: "Is something not working? Write us",
  feedback_link: "https://help2.costaction.com/",
  faq_categories: {
    "File upload": "File upload",
    Payouts: "Payouts",
    "Site referals": "Site referrals",
    "Downloads and statistics": "Downloads and statistics",
    Purchases: "Purchases",
    "Other questions ": "Other questions ",
    "Video player": "Video player"
  },
  get_started: "Get started",
  sidebar_nav: {
    dashboard: "Dashboard",
    page_video: "Video player",
    payouts: "Payouts",
    turbobit_net: "Turbobit.net",
    statistics: "Statistics",
    detailed: "Detailed",
    files: "Files",
    videos: "Videos",
    sales: "Sales",
    sources: "Sources",
    files_copies: "Files copies",
    earnings: "Income",
    gold_partners: "Gold partners",
    my_sites: "My sites",
    referrals: "Referrals",
    hitfile_net: "Hitfile.net",
    activate: "Activate",
    conditions: "Conditions and plans",
    affiliate_programs: "Affiliate programs"
  },
  months: {
    full: {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    },
    short: {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    }
  },
  need_security_password: "Account password required",
  enter_security_password: "Please enter password",
  wrong_password: "Wrong password",
  password_check: "Password check",
  follow_us_on_telegram: "Follow us on telegram!",
  logout: "Logout",
  
  api: "API",
  api_for_webmasters: "API for Webmasters",
  api_key_has_been_sent_to_email: "Key has been sent to your email",
  get_api_key: "Get key",
  api_usage_description: "Documentation and code examples are available at the following link: <a target='_blank' href='{link}'>{link}</a><br />" +
    "The authorization key will be sent to your email<br />" +
    "When receiving a new key, the old key is automatically deleted",
};
