export default {
  hints: 'Tipps',
  hint_plus: 'Vor weniger als 10 Tagen hochgeladene Dateien; Dateien mit mehr als 100 Downloads; Dateien mit 1 oder mehr Käufen.' + 'Diese Dateien können Ihre Effizienz nur steigern',
  hint_arrow_up: 'Profitable und effiziente Dateien',
  hint_arrow_down: 'Ineffiziente und unrentable Dateien',
  hint_minus: 'Extrem unrentable Dateien reduzieren Ihre Effizienz drastisch',
  data_table: {
    fields: {
      name: 'Dateiname (Ordner)',
      size: 'Größe',
      created_date: 'Datum hinzugefügt',
      download_count_today: 'Downloads heute',
      download_count_total: 'Downloads insgesamt',
      money_today: 'Heute verdient',
      money_total: 'Insgesamt verdient',
      premium_count_today: 'Verkäufe heute',
      premium_count_total: 'Gesamtverkäufe'
    }
  },
  inbox: 'Inbox',
  show_hints: 'Tipps anzeigen',
  folder: 'Ordner',
  find_by_name: 'Nach Name suchen',
  all: 'Alle',
  detailed_statistics_by_files: 'Detaillierte Statistik zu Dateien',
  // files copies
  detailed_statistics_by_file_copies: 'Detaillierte Statistik zu den Kopien'
};