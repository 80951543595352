/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./lang/index";

import vuetify from "./plugins/vuetify";
import "./plugins";

import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);

import octaviaKit from "@/plugins/octavia.kit";
import VueApexCharts from "vue-apexcharts";
// mock
import "./fake-db/index.js";
import axios from "@/plugins/axios";

Vue.component("Apexchart", VueApexCharts);

Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

Vue.prototype.$bus = new Vue();

Vue.mixin({
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.rtl = lang === "ar";
      this.$vuetify.lang.current = lang;
      axios.defaults.headers.common["X-localization"] = lang;
      this.$store.dispatch("lang/storeLanguage", lang);
    }
  }
});

import CaLangSelector from "@/views/components/LangSelector";
Vue.component("CaLangSelector", CaLangSelector);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
