export default {
  sites_referrals: 'Website-Empfehlungen',
  sites_referrals_subheader: 'Verdiene die Provision aus der Einnahmen von jedem Kauf des Benutzers, der von Deiner Website empfohlen wurde ',
  data_table: {
    fields: {
      site: 'Website',
      visits: 'Besuche',
      sales: 'Einkäufe',
      percent_from_sale: '% vom Kauf',
      today_earnings: 'Verdienst heute',
      weekly_earnings: 'Wöchentliche Einnahmen',
      total_earnings: 'Insgesamt verdient',
      status: 'Status',
      action: 'Aktion'
    }
  },
  earnings: '${value}',
  activate: 'Aktivieren',
  add_site: 'Deine Seite absenden',
  status: {
    active: 'Aktiv',
    created: 'Nicht bestätigt',
    banned: 'Verbannt',
    deleted: 'Gelöscht'
  },
  enter_site_url: 'Gebe bitte Deine Site-URL an',
  site_url_placeholder: 'https://example.com',
  enter_valid_url: 'UR-Adresse ist nicht gültig',
  option_1: 'Option #1',
  option_1_text: 'Platzieren Sie die {file} -Datei mit Ihrem Login-Namen im Stammverzeichnis Ihrer Website.{br}' + 'Die Datei muss unter dieser URL {url} verfügbar sein.',
  option_2: 'Option #2',
  option_2_text: 'Platzieren Sie ein spezielles META-Tag zwischen den <head> und </head> Tags Ihrer Website.',
  verify_ownership_of_site: 'Verifiziere das Eigentum über die Seite',
  site_configuration: 'Site-Konfiguration',
  site_configuration_line_1: 'Wenn Ihre Website das HTTPS-Protokoll verwendet:',
  site_configuration_line_2: '- Fügen Sie ein zusätzliches META-Tag für alle Seiten Ihrer Website ein, die Links für Dateien enthalten ' + ' zu Dateien - {code} (<a href=\'{link}\' target=\'_blank\'>mehr darüber lesen</a>); ',
  site_configuration_line_3: '- Stellen Sie sicher, dass Ihre Links <strong></strong> <code style="font-size: 14px;"> nicht enthalten' + 'rel="noopener noreferrer nofollow"</code> Attribute' + ' (lösche es für alle Datei-Links). ',
  site_configuration_line_4: 'Diese Einstellungen <strong>absolut sicher</strong> und ermöglichen es uns, Ihre Website korrekt zu identifizieren. ',
  premium_sale_promo_materials: 'Werbematerialien für die Seite',
  premium_sale_promo_materials_new_year: 'Neujahrswerbematerialien für die Website',
  promo_link_and_banners: 'Einzigartiger Premium-Link und Banner',
  use_link_to_get_commision: 'Verwenden Sie diesen Link und Banner, um Direktverkäufe zu tätigen und {value}% Provision zu erhalten',
  premium_sale_link: 'Einmaliger Link für Premium-Verkauf',
  premium_sale_banners: 'Premium Roll-up-Banner',
  download_psd: 'Sie können <a href=\'{link}\' target=\'_blank\'>.PSD original</a> Dateien herunterladen ' + 'und übersetzen Sie Banner für Ihre Sprache, oder ändern Sie Text in diesem. ',
  banner_code: 'Bannercode'
};