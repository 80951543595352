export default {
  today_stat: {
    title: 'Einnahmen heute',
    sub_title: '(Automatisch alle vier Stunden aktualisiert)',
    refresh: 'Aktualisieren'
  },
  filters: {
    title: 'Einnahmenindex',
    date_from: 'Anfangsdatum',
    date_to: 'Abschlussdatum',
    group_by: 'Gruppieren nach',
    group: {
      day: 'Tage',
      week: 'Wochen',
      month: 'Monate'
    },
    switcher: 'Filter'
  },
  gold_status: {
    title: '«Gold-Partner» Programm',
    current_status: 'Aktueller Status',
    next_status: 'Nächster Status',
    levels: {
      level_0: 'Standardpartner',
      level_1: 'Gold-Partner',
      level_2: 'Goldposter +{percent}%',
      level_3: 'Goldmeister +{percent}%',
      level_4: 'Goldadmin +{percent}%',
      level_5: 'Gold-Held +{percent}%',
      level_6: 'Goldmagnat +{percent}%'
    },
    efficiency: 'Effizienz {value}',
    coins: '{value} Münzen',
    next_level_condition: {
      wait_next_period: 'Warte einen Monat um zu erreichen',
      increase_efficiency: 'Erhöhe die Effizienz um {value} um zu erreichen',
      maximum_level: 'Sie haben den höchsten verfügbaren Status erreicht. Weiter so!',
      need_more_coins: 'Du solltest {value} weitere Münze(n) hinzufügen, um zu erreichen'
    }
  },
  earnings_table: {
    title: 'Detaillierte Statistik',
    fields: {
      date: 'Datum',
      files: 'Dateien',
      sites: 'Website-Empfehlungen',
      referrals: 'Empfehlungen',
      additional: 'Zusätzlich*',
      total: 'Insgesamt',
      efficiency: 'Effizienz**',
      coins: 'Münzen'
    }
  },
  notes: {
    additional: '*Zusätzlich zu Sonderveranstaltungen, Entschädigungen usw.',
    efficiency: '**Bitte lesen Sie über <a href=\'{efficiency_url}\'>&laquo;Effizienz&raquo;</a> und ' + '<a href=\'{gold_url}\'>&laquo;Bonusprogramm &laquo;Goldene Partner&raquo;</a> in <a href=\'{faq_url}\'>FAQ</a>'
  }
};