export default {
  main_block_title: 'Alan adları',
  search_placeholder: 'Alan adına göre ara',
  dates_placeholder: 'Tarih aralığı',
  domain_table: {
    fields: {
      domain: 'Kaynaklar',
      hits: 'Ziyaretler',
      downloads: 'İndirmeler',
      unique_downloads: 'Benzersiz indirmeler',
      purchases: 'Satın almalar'
    },
    detailed_row_title: 'Ayrıntılı istatistikler:'
  },
  url_table: { fields: { url: 'url' } }
};