export default {
  page_title: "Tarifas y Condiciones",
  page_subtitle:
    "Configuración de los programas de afiliación, descripción de tarifas y opciones de ingresos adicionales.",
  fd1_partners_program_settings: "Configuración del programa de afiliados de Turbobit",
  fd2_partners_program_settings: "Configuración del programa de afiliados de Hitfile",
  your_current_plan: "Plan de tarifas actual:",
  rate_plan_from_next_day: "El plan de tarifas vigente al día siguiente:",
  change_rate_plan_hint: "Nota: Puedes cambiar el plan de tarifa una vez por semana.",
  change_rate_plan: "Enviar cambios",
  turbobit_rate_plans: "Plan de tarifas de Turbobit",
  hitfile_rate_plans: "Plan de tarifas de Hitfile",
  premium_sale: "Ventas Premium (Pago por Venta)",
  rates_for_sale_of_premium_accounts: "Tarifas para vender la cuenta premium (acceso turbo)",
  rates_for_sale_of_premium_accounts_hitfile: "Tarifas para vender la cuenta premium (acceso premium)",
  country: "País",
  percent_of_sale: "% de venta",
  percent_of_rebill: "% de la factura",
  all_countries: "Todos los países",
  mixed: "Mixto (Pago por Venta y Descargas)",
  mixed_hint:
    "Un plan de tarifas mixtas para hacer ingresos de las descargas de tus archivos junto con las ventas de cuentas premium",
  rates: "Tarifas",
  group: "Grupo",
  for_1000_downloads: "para 1000 descargas",
  for_1000_downloads_hint:
    "sólo se cuentan descargas de archivos más de 10MB, " +
    "incluso las descargas incompletas. La singularidad se calcula en el contexto de una cuenta",
  group_and_countries: "Ciudades y países",
  mixed_countries_g: "Otros países",
  all_inclusive: "Todo Incluido (Pago por Descarga)",
  all_inclusive_hint: "El plan de tarifas para generar ingresos mediante descargas, basándose en el tamaño del archivo",
  rate_for_downloading: "Tarifas para 1.000 descargas de tus archivos",
  size_country: "Tamaño/País",
  rate_for_downloading_hint:
    "incluso las descargas incompletas. La singularidad se calcula en el contexto de una cuenta",
  all_inclusive_countries_d: "Otros países",
  additional_earnings: "Aumenta tu ingreso",
  bonus_program: "Programa de premio",
  gold_partners: "Socios Gold",
  increased_payments_and_extra_bonuses: "Aumento de las tarifas y bonos adicionales para socios regulares y eficaces",
  increased_payments_and_extra_bonuses_1: "Hasta un <b>85%</b> de las ventas para el plan PPS;",
  increased_payments_and_extra_bonuses_2:
    "Hasta un <b>60%</b> de ventas + $8,75 por 1000 descargas para el plan Mixto;",
  increased_payments_and_extra_bonuses_3: "Hasta <b>$25</b> por cada 1000 descargas para el plan PPD.",
  for: "Para",
  site_owners: "Propietarios del sitio",
  for_site_owners_line_1:
    "Obtenga <b>+{percents}% de las ventas realizadas por usuarios referidos desde tu sitio web</b>, blog, foro, " +
    "página de red social independientemente del propietario del archivo.",
  for_site_owners_line_2: "Usa tu sitio web y comparte enlaces de Hitfile " + "para generar ingresos extra.",
  referral_system: "Sistema de referencia",
  referral_system_text:
    "Obtenga <b>12%</b> de las ganancias de los nuevos socios a partir del momento del pasar el registro con tu enlace de recomendación " +
    "en el programa de afiliación durante los próximos 3 meses.<br />" +
    "<i>Al pasar tres meses:</i><br />" +
    "<b>6%</b> de las ganancias del socio invitado en los próximos 6 meses;<br />" +
    "<b>3%</b> de las ganancias del socio invitado al pasar estos 6 meses en adelante. <br /><br />" +
    "Además, debido al sistema de referencia de dos niveles, obtienes el 1% de los ingresos de " +
    "cada socio que introdujo en el programa de afiliación de tu parte <br />" +
    "Si no tienes ninguna otra ganancia excepto el ingreso de bonificación realizada por tus referidos, el porcentaje de " +
    "los ingresos del sistema de referencia de bonificación comienzan a disminuir hasta alcanzar el 0%. El cheque se realiza mensualmente.",
  price_of_premium_account_for_users: "Precio de cuentas Premium para los usuarios",
  price_of_premium_account_for_users_subtitle:
    "Seleccione las tarifas de precios. También puedes establecer las tarifas más tarde " +
    "individualmente para cada archivo/carpeta",
  standard_rates: "Tarifas estándar",
  expensive_rates: "Tarifas más caras",
  my_price: "Mi precio",
  make_your_own_price_rates_variation: "Haz tu propia variación de precios (selecciona hasta {value})",
  free_download_options: "Las opciones de descarga gratuitas",
  free_download_options_available_only_on_pps_rate_plan:
    "Configuración de descarga gratuita disponible sólo para el plan de tarifa PPS",
  allow_a_free_download_of_all_files: "Permitir descarga gratuita de todos los archivos",
  allow_a_free_download_of_all_files_hint:
    "Todos los archivos de todas las carpetas estarán disponibles para tu descarga gratuita. " +
    "Los ajustes individuales de archivos y carpetas serán ignorados.",
  disallow_a_free_download_of_all_files: "Prohibir descarga gratuita de todos los archivos",
  disallow_a_free_download_of_all_files_hint:
    "Todos los archivos de todas las carpetas estarán disponibles para " +
    "descargarlas sólo con acceso premium. Se ignorarán los ajustes individuales de archivos y carpetas.",
  use_individual_settings_of_files_and_folders:
    "La configuración de descarga por defecto de los archivos recién subidos",
  use_individual_settings_of_files_and_folders_hint:
    "La disponibilidad de una descarga gratuita depende de ajustes individuales " +
    "que fueron seleccionados manualmente para una carpeta o archivo específico. De otra manera, se aplican adjustes predeterminados.",
  inbox_free_download_label:
    "Seleccione la configuración predeterminada de los archivos recientemente subidos a tu carpeta 'Entrada':",
  allow_free_download: "Permitir la descarga gratuita",
  disallow_free_download: "Prohibir la descarga gratuita",
  the_maximum_file_size_available_for_free_downloads: "El tamaño máximo de archivo disponible para descarga gratuita",
  the_maximum_file_size_available_for_free_downloads_label:
    "Los archivos que superan el tamaño determinado no estarán disponibles " + "para descargalos de manera gratuita:",
  free_download: { unlimited: "sin límites" }
};
