export default {
  today_stat: {
    title: 'Günün Kazancı',
    sub_title: '(her dört saatte bir otomatik olarak güncellenir)',
    refresh: 'Yenile'
  },
  filters: {
    title: 'Gelir endeksi',
    date_from: 'Başlama tarihi',
    date_to: 'Bitiş tarihi',
    group_by: 'Grupla',
    group: {
      day: 'Günler',
      week: 'Haftalar',
      month: 'Aylar'
    },
    switcher: 'Filtreler'
  },
  gold_status: {
    title: '«Altın Ortaklar» programı',
    current_status: 'Mevcut Durum',
    next_status: 'Sonraki Durum',
    levels: {
      level_0: 'Standart Ortak',
      level_1: 'Altın Ortak',
      level_2: 'Altın Yükleyici Ortaklığı +{percent}%',
      level_3: 'Altın Usta Yükleyici +{percent}%',
      level_4: 'Altın Yönetici +{percent}%',
      level_5: 'Altın Kahraman +{percent}%',
      level_6: 'Altın Mıknatıs +{percent}%'
    },
    efficiency: 'verimlilik {value}',
    coins: '{value} jetonlar',
    next_level_condition: {
      wait_next_period: 'Elde etmek için bir ay bekleyin',
      increase_efficiency: 'Elde etmek için verimliliği {value} artırın',
      maximum_level: 'Mevcut en yüksek statüye ulaştınız. Devam et!',
      need_more_coins: 'Ulaşmak için {value} daha fazla jeton (lar) eklemelisiniz'
    }
  },
  earnings_table: {
    title: 'Ayrıntılı istatistikler',
    fields: {
      date: 'Tarih',
      files: 'Dosyalar',
      sites: 'Web sitesi referansları',
      referrals: 'Referanslar',
      additional: 'Ek*',
      total: 'Toplam',
      efficiency: 'Verimlilik**',
      coins: 'Jetonlar'
    }
  },
  notes: {
    additional: '* Özel etkinliklerden, tazminat tahakkukundan vb. Ek.',
    efficiency: '**Lütfen <a href=\'{faq_url}\'>SSS</a> bölümünden <a href=\'{efficiency_url}\'>&laquo;Verimlilik&raquo;</a> ve ' + '<a href=\'{gold_url}\'>&laquo;Bonus programı &laquo;Altın Ortaklar&raquo;</a>hakkında bilgi edinin'
  }
};