export default {
  header_subtitle_1: "Thank you for joining us! Let's dive into the process of maintaining your partner's account.",
  header_subtitle_2: "Please follow the steps below to submit all required information.",
  skip_step: "Skip step",
  done: "Done",
  add_payment_details: "Submit purse details to qualify a payout request",
  next_step: "Next step",
  step1: {
    title: "Add your purse details to the «Payouts» page. ",
    description: "You can use such systems as PayPal, Webmoney, Bitcoin, Payoneer, Qiwi and many others!",
    hint: "If you do not have a purse yet, you can skip this step and add it later."
  },
  step2: {
    title: "Activate turbobit.net and/or hitfile.net account.",
    description: "Select affiliate plan by clicking «Activate» on «Affiliate Program» tab, or use the buttons below:",
    hint: "After activation, all the data submitted will be sent to your email."
  },
  step3: {
    title: "Select affiliate plan",
    description1:
      "Learn about all available affiliate plans on the " +
      "«Conditions and plans» page and decide on the one that suits you best.",
    description2: "You can check and change your affiliate plan on the «Settings» page for each project.",
    hint: "Affiliate plan can be switched once a week."
  },
  step4: {
    title: " Upload the file to turbobit.net and/or hitfile.net ",
    description1: "Log in with your current email to turbobit.net/hitfile.net and upload the files.",
    description2:
      "Or select brand new «File manager» available from the «Tools» menu " +
      "to operate with files more effectively (recommended)."
  },
  step5: {
    title: "Congratulations, your account is ready. ",
    description1:
      "Keep going, upload and share your files to generate revenue for each and every download or/and sale, " +
      "according to the selected affiliate plan! ",
    description2: "If you have any further questions or issues requiring support, read FAQ or contact us. {url}"
  }
};
