export default {
  cancel: "Cancel",
  confirm: "Confirm",
  close: "Close",
  save: "Save",
  submit: "Submit",
  all_fields_are_required: "* all fields are required",
  ok: "OK",
  loading: "Loading...",
  wait: "Wait...",
  delete: "Delete",
  reset: "Reset",
  refresh: "Refresh",
  continue: "Continue",
  skip: "Skip",
  done: "Done",
  send: "Send",
  filters: "Filters",
  tables: { total: "Total" },
  error: "Error",
  try_later: "Error happened. Try later",
  form: {
    errors: {
      required: "{field} is required",
      number: "Please, qualify valid number",
      max: "Value must be less than or equal to {value}",
      min: "value must be greater than or equal to 40.00"
    }
  },
  email: "Email",
  password: "Password",
  all: "All",
  max: "max",
  create: "Create",
};
