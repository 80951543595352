export default {
  page_title: "Koşullar ve Oran planları",
  page_subtitle: "Ortaklık programı ayarları, açıklamaları ve ek gelir seçenekleri ile oran planları.",
  fd1_partners_program_settings: "Hitfile ortaklık programı ayarları",
  fd2_partners_program_settings: "Hitfile ortaklık programı ayarları",
  your_current_plan: "Geçerli tarife planı:",
  rate_plan_from_next_day: "Sonraki gün geçerli olan oran planı:",
  change_rate_plan_hint: "Önemli: Ücret planını haftada bir kez değiştirebilirsiniz.",
  change_rate_plan: "Değişiklikleri gönder",
  turbobit_rate_plans: "Turbobit Oran planları",
  hitfile_rate_plans: "Hitfile Oranı planları",
  premium_sale: "Premium Satışlar (Satış Başına Ödeme)",
  rates_for_sale_of_premium_accounts: "Premium hesap satış fiyatları (turbo access)",
  rates_for_sale_of_premium_accounts_hitfile: "Paralı hesapların (turbo erişim) satışı için ücret",
  country: "Ülke",
  percent_of_sale: "Satış %'si",
  percent_of_rebill: "Yeni fatura %'si",
  all_countries: "Tüm ülkeler",
  mixed: "Karışık (Satış ve İndirmeler Başına Ödeme)",
  mixed_hint:
    "Dosyalarınızın indirilmesi ve paralı hesap satışları için ödemeler dahil olmak üzere karışık tarife planı",
  rates: "Tarifeler",
  group: "Grup",
  for_1000_downloads: "1000 indirme için",
  for_1000_downloads_hint:
    "yalnızca 10 Mb'den büyük dosyaların indirilmesi sayılır, " +
    "tamamlanmamış indirmeler dahil. Benzersizlik bir hesap bağlamında hesaplanır",
  group_and_countries: "Gruplar ve ülkeler",
  mixed_countries_g: "Diğer ülkeler için",
  all_inclusive: "Herşey Dahil (İndirme Başına Ödeme)",
  all_inclusive_hint: "Dosya boyutuna göre indirmelere göre gelir elde etme oranı planı",
  rate_for_downloading: "Dosyalarınızın 1.000 indirme ücreti",
  size_country: "Boyut/Ülke",
  rate_for_downloading_hint: "tamamlanmamış indirmeler dahil. Benzersizlik bir hesap bağlamında hesaplanır",
  all_inclusive_countries_d: "Diğer Ülkeler",
  additional_earnings: "Gelirinizi yükseltin",
  bonus_program: "Bonus programı",
  gold_partners: "Altın Üyeler",
  increased_payments_and_extra_bonuses: "Düzenli ve etkin ortaklara artan oranlar ve ekstra bonuslar",
  increased_payments_and_extra_bonuses_1: "PPS planı için <b> %85'e kadar</b> satış;",
  increased_payments_and_extra_bonuses_2: "Karma plan için 1000 indirme başına <b>% 60 </ b> satış + 8,75 $'a kadar;",
  increased_payments_and_extra_bonuses_3: "PPD planı için 1000 indirme başına 25$'a kadar.",
  for: "için",
  site_owners: "Site sahipleri",
  for_site_owners_line_1:
    "<b>Web sitenizden, blogunuzdan, forumunuzdan yönlendirilen kullanıcılar tarafından yapılan satışların +% {percents}'unu alın</b>, " +
    "dosya sahibinden bağımsız olarak sosyal ağ sayfası.",
  for_site_owners_line_2: "Web sitenizi kullanın ve Hitfile bağlantılarını paylaşın " + "ekstra gelir getirici için.",
  referral_system: "Referans Sistemi",
  referral_system_text:
    "Yönlendirme bağlantınıza kaydoldukları andan itibaren yeni ortakların kazançlarının <b>%12'sini </b> alın " +
    "önümüzdeki 3 ay boyunca ortaklık programında.<br />" +
    "<i>Üç ay geçtikten sonra:</i><br />" +
    "<b>davet edilen ortağın kazancının%6'sı</b> gelecek 6 aylık kazançlar;<br />" +
    "<b>6 aydan sonraki davetli ortak kazançlarının %3'ü </b>. <br /><br />" +
    "Ayrıca, iki seviyeli referans sistemi sayesinde kazancınızın% 1'ini " +
    "ortaklık programına sizin tarafınızdan tanıtılan her ortak. <br />" +
    "Yönlendirmeleriniz tarafından elde edilen bonus geliri dışında başka bir kazancınız yoksa, " +
    "bonus yönlendirme sistemi geliri% 0'a ulaşana kadar azalmaya başlar. Kontrol aylık olarak yapılır.",
  price_of_premium_account_for_users: "Kullanıcılar için Premium Hesapların Fiyatı",
  price_of_premium_account_for_users_subtitle:
    "Fiyat oranlarını seçin. Daha sonra oranları da ayarlayabilirsiniz " + "her dosya / klasör için ayrı ayrı",
  standard_rates: "Standart oranlar",
  expensive_rates: "Pahalı Oranlar",
  my_price: "Benim fiyatım",
  make_your_own_price_rates_variation: "Kendi fiyat oranlarınızı değiştirin ({value}'e kadar seçin)",
  free_download_options: "Ücretsiz indirme seçenekleri",
  free_download_options_available_only_on_pps_rate_plan:
    "Ücretsiz indirme ayarları yalnızca PPS oranı planı için kullanılabilir",
  allow_a_free_download_of_all_files: "Tüm dosyaların ücretsiz indirilmesine izin ver",
  allow_a_free_download_of_all_files_hint:
    "Tüm klasörlerdeki tüm dosyalar ücretsiz olarak indirilebilir. " +
    "Dosya ve klasörlerin tek tek ayarları yoksayılır.",
  disallow_a_free_download_of_all_files: "Tüm dosyaların ücretsiz indirilmesine izin verme",
  disallow_a_free_download_of_all_files_hint:
    "Tüm klasörlerdeki tüm dosyalar aşağıdakiler için kullanılabilir olacaktır " +
    "yalnızca premium erişim ile indirin. Dosya ve klasörler için ayrı ayrı ayarlar yoksayılır.",
  use_individual_settings_of_files_and_folders: "Yeni yüklenen dosyaların varsayılan indirme ayarları",
  use_individual_settings_of_files_and_folders_hint:
    "Ücretsiz indirmenin kullanılabilirliği bireye bağlıdır " +
    "belirli bir klasör veya dosya için el ile seçilen ayarlar. Aksi takdirde, varsayılan ayarlar uygulanır.",
  inbox_free_download_label: "'Gelen Kutusu' klasörünüze en son yüklenen dosyalar için varsayılan ayarları seçin:",
  allow_free_download: "Ücretsiz indirmeye izin ver",
  disallow_free_download: "Ücretsiz indirmeye izin verme",
  the_maximum_file_size_available_for_free_downloads: "Ücretsiz indirilebilecek maksimum dosya boyutu",
  the_maximum_file_size_available_for_free_downloads_label:
    "Belirli bir boyuttan büyük dosyalar kullanılamaz " + "ücretsiz indirmek için:",
  free_download: { unlimited: "sınır yok" }
};
