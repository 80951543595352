export default {
  page_header: "Settings",
  page_subtitle: "Manage your info, privacy and security options.{br}Payout details can be edited on {url} page",
  payouts: "«Payouts»",
  personal_info: "Personal info",
  change_password: "Change password",
  security_options: "Security options",
  email: "Email",
  first_name: "First name",
  last_name: "Last name",
  contact_details: "Contact details",
  contact_details_hint: "Telegram, Skype or any other contact type",
  update_personal_info: "Update personal info",
  old_password: "Old password",
  new_password: "New password",
  new_password_confirm: "New password (confirmation)",
  change_password_submit: "Change password",
  security_code: "Security code",
  security_code_description:
    "An additional security measure that can be enabled on your own accord. " +
    "While at deleting files, requesting accumulated funds or changing payout details " +
    "the system will start to require a security code confirmation.",
  use_security_code_checkbox_label: "Enable confirmation of actions by security code",
  security_code_field_label: "Security code",
  security_code_confirmation_field_label: "Security code (confirmation)",
  security_code_hint:
    "*Be careful, security code cannot be restored. " +
    "The new security code comes into force in 15 days, during this period all operations with funds " +
    ", payout details and files deletion will be frozen.",
  update_security_options: "Update security options",
  password_hacking_prevention_system: "Password hacking prevention system",
  limit_login_attempts_field_label: "Limit of unsuccessful log-in attempts",
  limit_login_attempts_field_hint: "After 10 unsuccessful attempts, authorization via email will be required",
  use_captcha_field_label: "Enable captcha to protect my account",
  use_captcha_field_hint: "Highly recommended to enable this option",
  errors: {
    password_not_match_confirmation: "Does not match the confirmed password",
    security_code_not_match_confirmation: "Security code doesn't meet confirmed requirements"
  },
  password_changing_notification_sent:
    "Notification of the password change has been sent to the email specified during registration.",
  drop_all_sessions: "Drop all sessions",
  current_security_code_field_label: "Please enter your current security code"
};
