export default {
  site_name: "CostAction.com - Лучшие партнерские программы собранные в одном месте",
  dashboard: "Дашборд",
  videos: "Видео",
  earnings: "Доходы",
  page_not_found: "Страница не найдена",
  faq: "FAQ",
  get_started: "Начало работы с партнерской программой",
  gold: "Золотые партнеры",
  news: "Новости",
  notifications: "Уведомления",
  payouts: "Выплаты",
  referrals: "Рефералы",
  rates: "Условия и планы заработка",
  settings: "Настройки",
  sites: "Сайты",
  stats_sources: "Источники",
  stats_sales: "Продажи",
  stats_files_copies: "Копии файлов",
  stats_files: "Файлы",
  stats_detailed: "Подробная статистика",
  sign_in: "Вход",
  create_new_account: "Создать учетную запись",
  restore_access: "Восстановить доступ",
  confirm_registration: "Подтвердите регистрацию",
  purse_confirmation: "Подтверждение способа вывода",
  video_page: "Видео"
};
