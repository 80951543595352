// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "../../src/themeConfig";

// Переводы vuetify
import ru from "vuetify/lib/locale/ru";
import ar from "vuetify/lib/locale/ar";
import es from "vuetify/lib/locale/es";
import it from "vuetify/lib/locale/it";
import fr from "vuetify/lib/locale/fr";
import de from "vuetify/lib/locale/de";
import ja from "vuetify/lib/locale/ja";
import tr from "vuetify/lib/locale/tr";

Vue.use(Vuetify);

import Hitfile from "@/views/components/icons/Hitfile";
import Turbobit from "@/views/components/icons/Turbobit";

export default new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl,
  lang: {
    locales: { ru, ar, es, it, fr, de, ja, tr },
    current: "en"
  },
  icons: {
    values: {
      hitfile: {
        component: Hitfile
      },
      turbobit: {
        component: Turbobit
      }
    }
  }
});
