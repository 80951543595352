export default {
  security_frozen_period_notification: 'تنبيه، لقد تم تغيير رمز الحماية الخاص بك {changedAt}<br />' + 'سيتم قبول هذا الإجراء بعد {allowedAt}',
  security_check: 'فحص أمني',
  security_code: 'رمز الحماية',
  enter_security_code: 'أدخل رمز الحماية',
  wrong_code: 'رمز الحماية غير صحيحة',
  password_changed_successfully: 'لقد تم تغيير كلمة المرور الخاصة بك بنجاح!',
  password_changing_url_is_invalid: 'رابط تغيير كلمة المرور لم يعد صالحًا!',
  sections: {
    dashboard: 'لوحة المعلومات',
    statistics: 'إلاحصائيات',
    stats: {
      sources: 'المصادر',
      sales: 'المشتريات',
      files: 'الملفات',
      'files-copies': 'نسخ الملفات',
      detailed: 'إحصائيات مفصلة'
    },
    earnings: 'الإيرادات',
    referrals: 'الإحالات',
    sites: 'المواقع الخاصة بي'
  },
  notifications: 'الإشعارات',
  view_notifications: 'عرض الإشعارات',
  mark_as_read: 'وضع علامة كمقروء',
  messages_and_notifications: 'الرسائل والإشعارات',
  news: 'ألأخبار',
  news_and_updates: 'الأخبار والتحديثات',
  file_manager: 'مدير الملفات',
  menu: {
    tools: 'تسجيل الدخول إلى',
    fd1_login: 'تسجيل الدخول إلى turbobit.net',
    fd2_login: 'تسجيل الدخول إلى موقع hitfile.net'
  },
  help: 'مساعدة',
  faq: 'الأسئلة الأكثر شيوعاً',
  support_center: 'خدمة العملاء',
  additional_information_faq: 'معلومات إضافية (الأسئلة الشائعة)',
  activation_in_process: 'جاري التفعيل',
  feedback_link_text: 'هل هناك شيء لا يعمل كما ينبغي ؟ أكتب لنا',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'رفع الملف',
    Payouts: 'المدفوعات',
    'Site referals': 'إحالات الموقع',
    'Downloads and statistics': 'التنزيلات والإحصائيات',
    Purchases: 'المشتريات',
    'Other questions ': 'اسئلة اخرى '
  },
  get_started: 'بدأ الإستخدام',
  sidebar_nav: {
    dashboard: 'لوحة المعلومات',
    payouts: 'المدفوعات',
    turbobit_net: 'Turbobit.net',
    statistics: 'إلاحصائيات',
    detailed: 'المفصلة',
    files: 'الملفات',
    sales: 'المبيعات',
    sources: 'المصادر',
    files_copies: 'نسخ الملفات',
    earnings: 'الدخل',
    gold_partners: 'الشركاء الذهبيين',
    my_sites: 'المواقع الخاصة بي',
    referrals: 'الإحالات',
    hitfile_net: 'Hitfile.net',
    activate: 'تفعيل',
    conditions: 'الشروط والخطط',
    affiliate_programs: 'برامج الشركاء'
  },
  months: {
    full: {
      1: 'يناير',
      2: 'فبراير',
      3: 'مارس',
      4: 'أبريل',
      5: 'مايو',
      6: 'يونيو',
      7: 'يوليو',
      8: 'أغسطس',
      9: 'سبتمبر',
      10: 'أكتوبر',
      11: 'نوفمبر',
      12: 'ديسمبر'
    },
    short: {
      1: 'يناير',
      2: 'فبراير',
      3: 'مارس',
      4: 'إبريل',
      5: 'مايو',
      6: 'يونيو',
      7: 'يوليو',
      8: 'اغسطس',
      9: 'سبتمبر',
      10: 'أكتوبر',
      11: 'نوفمبر',
      12: 'ديسمبر'
    }
  },
  need_security_password: 'مطلوب كلمة مرور الحساب',
  enter_security_password: 'يرجى إدخال كلمة المرور',
  wrong_password: 'كلمة مرور خاطئة',
  password_check: 'التحقق من كلمة المرور'
};