export default {
  site_name: 'CostAction.com - Melhores programas de afiliados reunidos em um só lugar',
  dashboard: 'Painel de Controle',
  earnings: 'Ganhos',
  page_not_found: 'Página não encontrada',
  faq: 'Perguntas Frequentes',
  get_started: 'Começar',
  gold: 'Parceiros gold',
  news: 'Notícias',
  notifications: 'Notificações',
  payouts: 'Pagamentos',
  referrals: 'Indicações',
  rates: 'Condições e planos',
  settings: 'Configurações',
  sites: 'Sites',
  stats_sources: 'Fontes',
  stats_sales: 'Vendas',
  stats_files_copies: 'Cópias de arquivos',
  stats_files: 'Arquivos',
  stats_detailed: 'Estatísticas detalhadas',
  sign_in: 'Entrar',
  create_new_account: 'Criar nova conta',
  restore_access: 'Restaurar acesso',
  confirm_registration: 'Confirmar registro',
  purse_confirmation: 'Confirmação da carteira',
  password_recovery: 'Recuperação de senha'
};