export default {
  header_subtitle_1:
    "Спасибо, что присоединились к нам! " +
    "Давайте углубимся в процесс настройки вашей учетной записи в партнерской программе.",
  header_subtitle_2: "Пожалуйста, следуйте инструкциям ниже, чтобы добавить всю необходимую информацию.",
  skip_step: "Пропустить шаг",
  done: "Готово",
  add_payment_details: "Добавить способ вывода",
  next_step: "Следующий шаг",
  step1: {
    title: "На странице «Выплаты», добавьте электронный кошелек для вывода заработка",
    description:
            "Вы можете использовать PayPal, Webmoney, Bitcoin, Payoneer, Qiwi и многие другие виды вывода!" +
            "</br><a href='https://cabinet.costaction.com/faq' target='_blank'>Подробнее о доступных методах вывода.</a>",
    hint: "Если на текущий момент вы не готовы добавить реквизиты, этот шаг можно пропустить и добавить их позже."
  },
  step2: {
    title: "Активировать партнерскую программу",
    description:
            "Чтобы начать получать доход, вам нужно активировать партнерскую программу. " +
            "Costaction предоставляет возможность работать одновременно с двумя проектами - turbobit и hitfile. " +
            "Две партнерские программы могут быть активированы одновременно. " +
            "Нажмите кнопку  «Активировать» на боковой панели слева или используйте кнопки быстрой активации ниже:",
    hint: "После активации все предоставленные данные будут отправлены на вашу электронную почту."
  },
  step3: {
    title: "Выберите план заработка",
    description1:
            "Для turbobit, вы можете зарабатывать на скачиваниях, продажах премиум-доступа или всём вместе! " +
            "Выберите подходящий план заработка на странице <a href='https://cabinet.costaction.com/app/fd1/rates' target='_blank'>«Условия и планы»</a>",
    description2:
            "Для Hitfile доступен только заработок на продажах. Но зато, вы можете применить больше настроек. " +
            "Настройте свой план заработка и условия на странице <a href='https://cabinet.costaction.com/app/fd2/rates' target='_blank'>«Условия и планы»</a>",
    hint: "План заработка может быть изменен раз в неделю."
  },
  step4: {
    title: "Загрузите файл используя  «Менеджер файлов»",
    description1:
      "Нажмите  «Менеджер файлов» в левом верхнем углу costaction.",
    description2:
      "Также можно использовать ссылку: filemanager.costaction.com"
  },
  step5: {
    title: "Поздравляем, ваша учетная запись готова к работе.",
    description1:
      "Больше информации о партнерской программе можно прочитать по ссылке: {url}",
    description2:
      "Если у вас есть дополнительные вопросы или проблемы, требующие поддержки, свяжитесь с нами. {url}"
  }
};
