export default {
  data_table: {
    fields: {
      date: 'Data',
      file: 'Arquivo',
      source: 'Fonte',
      payment_system: 'Método de pagamento',
      tariff: 'Tipo de assinatura',
      file_sales: 'Receita $',
      site_sales: 'Receita de indicações de site $',
      status: 'Status*'
    },
    notes: {
      note1: '* Por favor, leia mais sobre status de {hold}, {void} e {refund} em nossa {faq}',
      status_hold: 'Retenção',
      status_void: 'Anulação',
      status_refund: 'Reembolso',
      note2: '** A tabela exibe dados dos últimos dois anos',
      type_rebill: '- resgate',
      type_copy: '- da cópia de arquivo',
      type_promo: '- link promocional ou banner'
    }
  },
  file_money_type: {
    direct: 'Direto',
    rebill: 'Resgate',
    copy: 'Da cópia de arquivo'
  },
  refresh: 'Atualizar',
  filters: 'Filtros',
  sites: 'Sites',
  files: 'Arquivos',
  files_copies: 'Cópias de arquivos $',
  status: 'Status',
  payment_system: 'Método de pagamento',
  all: 'Todos',
  date_from_placeholder: 'Data de início',
  date_to_placeholder: 'Data de conclusão',
  promo_banner_or_link: 'Link promocional',
  site_referral: 'Indicações do site',
  unknown: 'Desconhecido'
};