export default {
  today_stat: {
    title: 'Statistik heute',
    sub_title: '(Automatisch alle vier Stunden aktualisiert)',
    refresh: 'Aktualisieren',
    gold: 'Goldbonus'
  },
  chart: {
    select: { label: 'Diagramm' },
    title: 'Tagesdiagramm und Filter'
  },
  table: {
    title: 'Detaillierte Statistik',
    fields: {
      date: 'Datum',
      downloads: 'Downloads',
      sum: 'Summe $',
      avg_sales: 'AVG Verkäufe $',
      sales: 'Verkäufe',
      gold: 'Goldbonus $',
      copies: 'Kopien*',
      total: 'Gesamt',
      avg_download_amount: 'AVG pro 1000 Downloads**'
    }
  },
  sub_table: {
    title: 'Detaillierte Statistik {date}',
    fields: {
      countries: 'Länder/Gruppen',
      sum: 'Summe + Goldbonus $'
    },
    countries: {
      A: 'Gruppe A',
      B: 'Gruppe B',
      C: 'Gruppe C',
      D: 'Gruppe D',
      E: 'Gruppe E',
      F: 'Gruppe F',
      G: 'Gruppe G',
      R: 'Russisch',
      U: 'Ukraine'
    }
  },
  notes: {
    copies: '*Einnahmen auf Ihre Dateien <a href=\'{copies_url}\'>Kopien</a>, gemacht von anderen Benutzern.',
    avg_downloads: '**Spalte \'AVG pro 1000 Downloads\' zeigt den durchschnittlichen Betrag für 1.000 einzigartige Downloads deiner Downloads an ' + 'Dateien und Einkäufe von Premium-Zugriff'
  }
};