export default {
  cancel: 'İptal et',
  confirm: 'Onayla',
  close: 'Kapat',
  save: 'Kaydet',
  all_fields_are_required: '* Tüm alanlar gereklidir',
  ok: 'Tamam',
  loading: 'Yükleniyor...',
  wait: 'Bekleyin...',
  delete: 'Sil',
  reset: 'Sıfırla',
  refresh: 'Yenile',
  continue: 'Devam et',
  skip: 'Atla',
  done: 'Tamamlandı',
  send: 'Gönder',
  filters: 'Filtreler',
  tables: { total: 'Toplam' },
  error: 'Hata',
  try_later: 'Hata oluştu. Lütfend aha sonra deneyin',
  form: {
    errors: {
      required: '{field} gerekli',
      number: 'Lütfen geçerli bir numara girin',
      max: 'Değer {value} değerinden küçük veya ona eşit olmalıdır',
      min: 'değer 40,00\'dan büyük veya ona eşit olmalıdır'
    }
  },
  email: 'E-posta',
  password: 'Şifre'
};