export default {
  site_name: 'CostAction.com - Los mejores programas asociados en un solo lugar',
  dashboard: 'Panel de control',
  earnings: 'Ganancias',
  page_not_found: 'No se encontró la página',
  faq: 'FAQ/preguntas frecuentes',
  get_started: 'Comenzar',
  gold: 'Socios Gold',
  news: 'Noticias',
  notifications: 'Notificaciones',
  payouts: 'Pagos',
  referrals: 'Referencias',
  rates: 'Condiciones y planes',
  settings: 'Ajustes',
  sites: 'Sitios',
  stats_sources: 'Fuentes',
  stats_sales: 'Ventas',
  stats_files_copies: 'Copias de archivos',
  stats_files: 'Archivos',
  stats_detailed: 'Estadísticas detalladas',
  sign_in: 'Iniciar sesión',
  create_new_account: 'Crear una nueva cuenta',
  restore_access: 'Recuperar el acceso',
  confirm_registration: 'Confirmar el registro',
  purse_confirmation: 'Confirmación del bolso',
  password_recovery: 'Recuperar la contraseña'
};