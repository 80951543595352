export default {
  email_required: 'Tienes que escribir tu email',
  password_required: 'Necesitamos una contraseña',
  enter_captcha: 'Por favor, pase la verificación de captcha',
  login: 'Iniciar sesión',
  account_was_blocked: 'La cuenta seleccionada está activa y no requiere restauración.',
  account_unblocking: 'Desbloqueo de cuenta',
  restore: 'Restaurar',
  unblock: 'Desbloquear',
  invalid_link: 'Enlace no válido. Por favor, ponte en contacto con el Administrador.',
  account_is_unblocked: 'Tu cuenta está desbloqueada. Ya puedes iniciar sesión.',
  account_restore_email_sent: 'Para recuperar la cuenta, por favor siga el enlace que hemos enviado a tu mail',
  email: 'E-mail',
  password: 'Contraseña',
  email_must_be_valid: 'El correo electrónico debe ser uno válido',
  forgot_password: '¿Olvidaste la contraseña?',
  create_new_account: 'Crear una nueva cuenta',
  sign_in_to_your_account: 'Iniciar sesión en Costaction',
  sign_in: 'Acceder',
  agree_with_terms_of_site: 'Aceptar los Términos del Servicio',
  subscribe_to_newsletter: 'Suscribirse al boletín',
  sign_up_now: '¡Regístrate ahora!',
  required: 'Requerido',
  register_confirm_sent_success: 'Por favor, comprueba tu bandeja de entrada, hemos enviado un enlace de confirmación a tu correo electrónico',
  account_activation: 'Activación de la cuenta',
  activation_in_progress: 'Proceso de activación, por favor espera',
  invalid_token: 'Tu enlace de verificación ha expirado',
  register_success_message: 'Tu cuenta ha sido activada correctamente, por favor inicia sesión',
  password_recovery: 'Recuperar la contraseña',
  recovering_password: 'Recuperación de la contraseña',
  invalid_recover_password_link: 'Este enlace para la nueva contraseña no está activo. Por favor, aplica a ' + '<a href=\'http://turbobit.net/helpdesk/\' target=\'_blank\'>support</a> if you have any difficulties with ' + 'restablecer la contraseña.',
  password_changed_success: 'Tu contraseña ha sido cambiada correctamente. Hemos enviado una nueva contraseña a tu correo electrónico. ' + 'También puedes cambiarla a una nueva.',
  recovery_password_link_sent: 'Hemos enviado a tu mail un mensaje con instrucciones y enlace para recuperar la contraseña.'
};