export default {
  hints: 'النصائح',
  hint_plus: 'الملفات التي تم رقعها قبل أقل من 10 أيام؛ الملفات التي تحتوي على أكثر من 100 تنزيل؛ الملفات التي تحتوي على مشتريات واحدة أو أكثر.' + 'هذه الملفات يمكنها فقط تحسين كفاءتك',
  hint_arrow_up: 'الملفات المربحة والفعالة',
  hint_arrow_down: 'ملفات غير فعالة وغير مربحة',
  hint_minus: 'الملفات غير المربحة بشكل كبير تقلل من كفاءتك بشكل كبير',
  data_table: {
    fields: {
      name: 'اسم الملف (مجلد)',
      size: 'الحجم',
      created_date: 'تم إضافة التاريخ',
      download_count_today: 'تحميلات اليوم',
      download_count_total: 'إجمالي التنزيلات',
      money_today: 'تم اكتسابها اليوم',
      money_total: 'مجموع المكتسبات',
      premium_count_today: 'مبيعات اليوم',
      premium_count_total: 'إجمالي المبيعات'
    }
  },
  inbox: 'البريد الوارد',
  show_hints: 'عرض النصائح',
  folder: 'المجلد',
  find_by_name: 'البحث عن طريق الإسم',
  all: 'الكل',
  detailed_statistics_by_files: 'إحصائيات مفصلة للملفات',
  // files copies
  detailed_statistics_by_file_copies: 'إحصائيات مفصلة لملفات المنسوخة'
};