import Vue from "vue";

export default {
  namespaced: true,
  state: {},
  actions: {
    loadVideosData(module, params) {
      return Vue.axios.get("/videos/stat", { params });
    },
    loadTodayData(module, params) {
      return Vue.axios.get("/videos/today", { params });
    }
  }
};
