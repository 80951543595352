export default {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  close: 'Cerrar',
  save: 'Guardar',
  all_fields_are_required: '* todos los campos son obligatorios',
  ok: 'Aceptar',
  loading: 'Cargando...',
  wait: 'Espera...',
  delete: 'Borrar',
  reset: 'Reiniciar',
  refresh: 'Actualizar',
  continue: 'Continuar',
  skip: 'Omitir',
  done: 'Listo',
  send: 'Enviar',
  filters: 'Filtros',
  tables: { total: 'Total' },
  error: 'Fallo de conexión',
  try_later: 'Ha ocurrido un error. Inténtalo más tarde',
  form: {
    errors: {
      required: '{field} este campo es obligatorio',
      number: 'Por favor, escribe un número válido',
      max: 'El valor debe ser inferior o igual a {value}',
      min: 'el valor debe ser superior o igual a 40.00'
    }
  },
  email: 'E-mail',
  password: 'Contraseña'
};