export default {
  summary: 'Resumo',
  total_referrals: 'Total de indicações',
  first_level: 'Primeiro nível',
  second_level: 'Segundo nível',
  total_earned: 'Total de ganhos',
  total_earned_value: '${value}',
  detailed_statistics: 'Estatística detalhada',
  data_table: {
    fields: {
      user: 'Usuário',
      referral_level: 'Nível de indicação',
      registration_date: 'Data de registro',
      today_earnings: 'Ganhos de hoje',
      weekly_earnings: 'Ganhos semanais',
      total_earnings: 'Total de ganhos',
      ref_percent: 'Porcentagem de ref.'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'Usuários com ganhos',
  search_by_user: 'Pesquisar por usuário',
  referrals_promo_meterials: 'Materiais promocionais de indicação',
  referral_link: 'Link de convite de indicação',
  referrals_banners: 'Banners de Indicação',
  banner_code: 'Código do banner'
};