export default {
  today_stat: {
    title: 'Statistiques du jour',
    sub_title: '(mise à jour automatique toutes les quatre heures)',
    refresh: 'Actualiser',
    gold: 'Bonus Gold'
  },
  chart: {
    select: { label: 'Graphique' },
    title: 'Graphique quotidien et filtres'
  },
  table: {
    title: 'Statistiques détaillées',
    fields: {
      date: 'Date',
      downloads: 'Téléchargements',
      sum: 'Montant $',
      avg_sales: 'Moyenne des ventes $',
      sales: 'Ventes',
      gold: 'Bonus Gold $',
      copies: 'Copies',
      total: 'Total',
      avg_download_amount: 'Moyenne par 1000 téléchargements**'
    }
  },
  sub_table: {
    title: 'Statistiques détaillées{date}',
    fields: {
      countries: 'Pays/Groupes',
      sum: 'Montant + bonus Gold $'
    },
    countries: {
      A: 'Groupe A',
      B: 'Groupe B',
      C: 'Groupe C',
      D: 'Groupe D',
      E: 'Groupe E',
      F: 'Groupe F',
      G: 'Groupe G',
      R: 'Russe',
      U: 'Ukraine'
    }
  },
  notes: {
    copies: '*Gains sur vos fichiers <a href=\'{copies_url}\'>copies</a>, réalisés par d\'autres utilisateurs.',
    avg_downloads: '**colonne " Moyenne par 1000 téléchargements " affiche le montant moyen pour 1 000 téléchargements uniques de votre produit ' + 'les fichiers et les achats d\'accès premium'
  }
};