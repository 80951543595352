<template>
  <v-snackbar v-model="visible" :timeout="timeout">
    {{ text }}

    <template #action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="visible = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    visible: {
      get() {
        return this.$store.state.app.snackbar.visible;
      },
      set(val) {
        this.$store.commit("app/setSnackbar", {
          visible: val,
          text: null
        });
      }
    },

    text() {
      return this.$store.state.app.snackbar.text;
    },

    timeout() {
      return this.$store.state.app.snackbar.timeout;
    }
  }
};
</script>
