<template>
  <v-dialog width="500" persistent :value="dialog.visible">
    <v-card>
      <v-card-title :class="[dialogColor]" class="headline lighten-2">
        {{ dialog.title }}
      </v-card-title>

      <v-card-text class="pt-4" v-html="dialog.message" />

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="close">
          {{ dialog.closeButtonLabel ? dialog.closeButtonLabel : $t("common.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CaDialog",
  computed: {
    dialog() {
      return this.$store.state.app.dialog;
    },
    type() {
      return this.dialog.type ? this.dialog.type : "info";
    },
    dialogColor() {
      const colors = {
        info: "info",
        success: "success",
        error: "error"
      };

      return colors[this.type];
    }
  },
  methods: {
    close() {
      this.$store.dispatch("app/closeDialog");
    }
  }
};
</script>
