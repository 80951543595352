export default {
  main_block_title: 'Domänen',
  search_placeholder: 'Suche nach Domain',
  dates_placeholder: 'Datumsbereich',
  domain_table: {
    fields: {
      domain: 'Quellen',
      hits: 'Besuche',
      downloads: 'Downloads',
      unique_downloads: 'Eindeutige Downloads',
      purchases: 'Einkäufe'
    },
    detailed_row_title: 'Detaillierte Statistik:'
  },
  url_table: { fields: { url: 'URL' } }
};