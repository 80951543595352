export default {
  hints: "Tips",
  hint_plus:
    "Files uploaded less than 10 days ago; Files having more than 100 downloads; Files with 1 or more purchases." +
    "Those files can only improve your efficiency",
  hint_arrow_up: "Profitable and efficient files",
  hint_arrow_down: "Inefficient and unprofitable files",
  hint_minus: "Extremely unprofitable files drastically decreasing your efficiency",
  data_table: {
    fields: {
      name: "File name (folder)",
      size: "Size",
      created_date: "Date added",
      download_count_today: "Downloads today",
      download_count_total: "Downloads total",
      video_view_count_today: "Video views for today",
      video_view_count_total: "Total video views",
      money_today: "Earned today",
      money_total: "Earned total",
      premium_count_today: "Sales today",
      premium_count_total: "Sales total"
    }
  },
  inbox: "Inbox",
  show_hints: "Tips display",
  folder: "Folder",
  find_by_name: "Search by name",
  all: "All",
  detailed_statistics_by_files: "Detailed statistics to files",
  // files copies
  detailed_statistics_by_file_copies: "Detailed statistics to file copies"
};
