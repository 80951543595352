export default {
  security_frozen_period_notification: 'Atención, tu código de seguridad ha sido cambiado {changedAt}<br />' + 'Esta acción será admitida después de {allowedAt}',
  security_check: '\'Control de seguridad',
  security_code: 'Código de seguridad',
  enter_security_code: 'Introduzca el código de seguridad',
  wrong_code: '\'Código de seguridad incorrecto\'',
  password_changed_successfully: '¡Tu contraseña ha sido modificada con éxito!',
  password_changing_url_is_invalid: '¡El enlace para cambiar tu contraseña ya no es válido!',
  sections: {
    dashboard: 'Panel de control',
    statistics: 'Estadística',
    stats: {
      sources: 'Fuentes',
      sales: 'Compra',
      files: 'Archivos',
      'files-copies': 'Copias de archivos',
      detailed: 'Estadística detallada'
    },
    earnings: 'Ingresos',
    referrals: 'Referencias',
    sites: 'Mis sitios'
  },
  notifications: 'Notificaciones',
  view_notifications: 'Ver todas las notificaciones',
  mark_as_read: 'Marcar como leído',
  messages_and_notifications: 'Mensajes y notificaciones',
  news: 'Noticias',
  news_and_updates: 'Noticias y actualizaciones',
  file_manager: 'Administrador de archivos',
  menu: {
    tools: 'Iniciar sesión en',
    fd1_login: 'Iniciar sesión en turbobit.net',
    fd2_login: 'Iniciar sesión en hitfile.net'
  },
  help: 'Ayuda',
  faq: 'FAQ/preguntas frecuentes',
  support_center: 'Atención al Cliente',
  additional_information_faq: 'Información adicional (FAQ)',
  activation_in_process: 'Proceso de activación',
  feedback_link_text: '¿No funciona algo? Escríbenos',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'Subir archivos',
    Payouts: 'Pagos',
    'Site referals': 'Referencias del sitio',
    'Downloads and statistics': 'Estadísticas de descarga',
    Purchases: 'Compra',
    'Other questions ': 'Otras preguntas '
  },
  get_started: 'Comenzar',
  sidebar_nav: {
    dashboard: 'Panel de control',
    payouts: 'Pagos',
    turbobit_net: 'Turbobit.net',
    statistics: 'Estadística',
    detailed: 'Detallado',
    files: 'Archivos',
    sales: 'Ventas',
    sources: 'Fuentes',
    files_copies: 'Copias de archivos',
    earnings: 'Ingresos',
    gold_partners: 'Socios Gold',
    my_sites: 'Mis sitios',
    referrals: 'Referencias',
    hitfile_net: 'Hitfile.net',
    activate: 'Activar',
    conditions: 'Condiciones y planes',
    affiliate_programs: 'Programas de afiliados'
  },
  months: {
    full: {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    },
    short: {
      1: 'En',
      2: 'Febr',
      3: 'Mzo',
      4: 'Abr',
      5: 'My',
      6: 'Jun',
      7: 'Jul',
      8: 'Ag',
      9: 'Sept',
      10: 'Oct',
      11: 'Nov',
      12: 'Dic'
    }
  },
  need_security_password: 'Contraseña requerida',
  enter_security_password: 'Introducir contraseña',
  wrong_password: 'Contraseña incorrecta',
  password_check: 'Verificación de la contraseña'
};