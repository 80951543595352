export default {
  today_stat: {
    title: 'Bugünün istatistikleri',
    sub_title: '(her dört saatte bir otomatik olarak güncellenir)',
    refresh: 'Yenile',
    gold: 'Altın Bonuslar'
  },
  chart: {
    select: { label: 'Liste' },
    title: 'Günlük grafik ve filtreler'
  },
  table: {
    title: 'Ayrıntılı istatistikler',
    fields: {
      date: 'Tarih',
      downloads: 'İndirmeler',
      sum: 'Toplam $',
      avg_sales: 'ORTALAMA satış $',
      sales: 'Satışlar',
      gold: 'Altın Bonuslar $',
      copies: 'Kopyalar*',
      total: 'Toplam',
      avg_download_amount: '1000 indirme başına ortalama**'
    }
  },
  sub_table: {
    title: 'Ayrıntılı istatistikler {date}',
    fields: {
      countries: 'Ülke / Gruplar',
      sum: 'Toplam + Altın bonus $'
    },
    countries: {
      A: 'Grup A',
      B: 'Grup B',
      C: 'Grup C',
      D: 'Grup D',
      E: 'Grup E',
      F: 'Grup F',
      G: 'Grup G',
      R: 'Rusya',
      U: 'Ukrayna'
    }
  },
  notes: {
    copies: '* Dosyalarınızdaki kazançlar <a href=\'{copies_url}\'>kopyalar</a>, diğer kullanıcılar tarafından yapılmıştır.',
    avg_downloads: '** \'1000 indirme başına ortalama\' sütunu, 1.000 benzersiz indirme işleminizin ortalama tutarını görüntüler ' + 'dosyalar ve premium erişim satın alımları'
  }
};