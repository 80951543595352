export default {
  payout_details: "Detalhes de pagamento",
  payout_details_subtitle:
    "Por motivos de segurança, dados de pagamento novos e atualizados devem ser " +
    "verificado por e-mail e entrar em vigor após {days} dias de retenção.",
  type: "Tipo",
  details: "Detalhes",
  create_date: "Data",
  fee_min_payout: "Taxa / Pagamento mínimo",
  status: "Status",
  action: "Ação",
  active: "Ativo",
  on_hold: "Retido ({date})",
  waiting_email_confirmation: "Aguardando confirmação por email",
  are_you_sure_you_want_to_delete_purse: "Tem certeza que deseja remover esta carteira?",
  main_purse_can_not_be_deleted:
    "A carteira principal não pode ser removida. Selecione a carteira verificada e defina-a como a principal para poder remover todas as outras",
  add_new: "Enviar",
  add_new_payout_details: "Enviar novos detalhes de pagamento",
  payout_system: "Sistema de pagamento",
  month: "Mês",
  year: "Ano",
  first_name: "Primeiro Nome",
  last_name: "Último Nome",
  currency: "Moeda",
  iban: "IBAN",
  bank_name: "Nome do Banco",
  set_as_main: "Definir como carteira principal",
  delete_wallet: "Remover",
  no_purses: "Ainda não adicionou nenhuma carteira",
  confirming_purse: "Verificação em andamento. Por favor aguarde...",
  url_is_invalid: "URL inválida ou expirada. Por favor, tente novamente",
  purse_is_confirmed: "Verificação de detalhes de pagamento concluída com sucesso",
  available_withdrawal: "Disponível para pagamento: {amount} USD",
  new_request: "Nova solicitação",
  amount_in_hold: "Valor Retido",
  what_is_hold: "o que é retido?",
  files: "Arquivos",
  referrals: "Indicações",
  sites_referrals: "Indicações de site",
  file_copies: "Cópias de arquivos",
  request_payout: "Solicitar pagamento",
  send_request: "Enviar pedido",
  request_payout_amount_field_label: "Valor",
  Fee: "Taxa",
  Min_payout_is: "Pagamento mínimo é",
  exchange_by_rates: "Taxa de câmbio USD/RUB pelo Banco da Rússia",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Tem certeza que deseja excluir o pedido?",
  request_payout_errors: {
    purse_is_on_hold_one: "Os detalhes de pagamento foram alterados. Novo pedido pode ser enviado após {date}",
    purse_is_on_hold_many:
      "Os detalhes de pagamento foram alterados. Novo pedido pode ser enviado após {date}" +
      " ou defina a carteira verificada nas Configurações"
  },
  payouts: "Pagamentos",
  payouts_subtitle:
    "As solicitações de pagamento normalmente são processadas em média 7 dias, " +
    "mas em casos de baixa demanda o tempo de processamento pode ser ainda mais rápido.",
  payout_table: {
    fields: {
      created_date: "Data",
      amount: "Valor",
      payment_details: "Detalhes da carteira",
      status: "Status",
      action: "Ação"
    }
  },
  payouts_status_label: {
    created: "Criado",
    review: "Em progresso",
    process: "Processamento do pagamento",
    accepted: "Concluído",
    rejected: "Rejeitado",
    deleted: "Excluído"
  },
  submit_payouts_details: "Salvar"
};
