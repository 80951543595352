export default {
  site_name: 'CostAction.com - Beste Affiliate-Programme an einem Ort zusammengestellt',
  dashboard: 'Dashboard',
  earnings: 'Einnahmen',
  page_not_found: 'Seite nicht gefunden',
  faq: 'FAQ',
  get_started: 'Starte los',
  gold: 'Gold-Partner',
  news: 'News',
  notifications: 'Benachrichtigungen',
  payouts: 'Auszahlungen',
  referrals: 'Empfehlungen',
  rates: 'Bedingungen und Pläne',
  settings: 'Einstellungen',
  sites: 'Websites',
  stats_sources: 'Quellen',
  stats_sales: 'Verkäufe',
  stats_files_copies: 'Kopien der Dateien',
  stats_files: 'Dateien',
  stats_detailed: 'Detaillierte Statistik',
  sign_in: 'Anmelden',
  create_new_account: 'Einen neues Konto erstellen',
  restore_access: 'Zugriff wiederherstellen',
  confirm_registration: 'Registrierung bestätigen',
  purse_confirmation: 'Geldbörse bestätigen',
  password_recovery: 'Passwortwiederherstellung'
};