export default {
  email_required: "Необходим Email",
  password_required: "Необходим пароль",
  enter_captcha: "Пожалуйста, завершите captcha",
  login: "Авторизоваться",
  account_was_blocked: "Выбранная учетная запись активна и не требует восстановления.",
  account_unblocking: "Разблокировать аккаунт",
  restore: "Восстановить",
  invalid_link: "Неверная ссылка. Пожалуйста, обратитесь к администратору.",
  account_is_unblocked: "Ваша учетная запись разблокирована. Авторизация снова доступна.",
  account_restore_email_sent:
    "Чтобы восстановить учетную запись, перейдите по ссылке, отправленной на ваш адрес электронной почты",
  email: "Email",
  password: "Пароль",
  email_must_be_valid: "Электронная почта должна быть действующей",
  forgot_password: "Забыли пароль?",
  create_new_account: "Создать учетную запись",
  sign_in_to_your_account: "Войти на Costaction",
  sign_in: "Вход",
  agree_with_terms_of_site: "Подтвердить согласие с правилами и условиями сайта",
  subscribe_to_newsletter: "Подписаться на новостную рассылку",
  sign_up_now: "Зарегистрироваться сейчас!",
  required: "Требуется",
  register_confirm_sent_success:
    "Пожалуйста, проверьте свой почтовый ящик, мы отправили ссылку для подтверждения на вашу электронную почту",
  account_activation: "Активация аккаунта",
  activation_in_progress: "Подтверждение в процессе. Пожалуйста, ожидайте",
  invalid_token: "Срок действия ссылки для подтверждения истек",
  register_success_message: "Ваша учетная запись успешно активирована, пожалуйста, войдите",
  password_recovery: "Восстановление пароля",
  recovery_password_link_sent: "На ваш электронный адрес было отправлено сообщение со ссылкой для сброса пароля."
};
