export default {
  premium_title: "Premium {premium_sub_title}",
  premium_sub_title: "Video Player",
  premium_video_sub_title: "Automatically convert uploaded video files for online watching, embed the player</br> on web pages, and generate revenue from premium sales.",
  player_text:
          "Video Player is available for all video files that {br}" +
          "have undergone the conversion process.{br}{br}" +
          "The player is displayed by default on the {br}" +
          "download page and can also be embedded on any" +
          " web page using {embed_code}.{br}{br}" +
          "Regular users have access to a preview of a portion {br}" +
          "of the video, ranging from 1 to 3 minutes. {br}" +
          "To continue watching, premium access must be purchased.",
  embed_code: "Embed Code",
  tools: "Webmaster Tools",
  convert_video: "Convert Video",
  oauth_title: "OAuth authorization and the ability to use your own video player",
  oauth_text:
          "For experienced partners, we offer the option to use your custom video player and obtain video links via the API. " +
          "Any user can authenticate on your website through our OAuth protocol, and then, based on their identifier, file links are provided. {br}" +
          "For more detailed information, please contact our {support_link}.",
  support_link: "Support Service",
  convert_video_text_1: "Choose either manual or automatic mode. The conversion time may vary depending on server load and the format/size of your file.",
  manual_level: "Manual Mode",
  manual_level_text:
          "Go to {manage_file_link}, select the desired file, and click on the {iconPlus} icon in the \"Video\" section. {br}" +
          "Alternatively, select multiple files using quick action and click on the {iconVideoPlus} button.",
  manager_files: "File manager",
  auto_level: "Automatic Mode",
  auto_level_text:
          "Go to {manage_file_link}, open “Settings” by clicking on the {iconSettings} icon in the upper right corner. {br}" +
          "All partners have the option “Convert popular video files” enabled by default, we also recommend enabling the option “Convert all uploaded video files”.",
  public_video_pay: "Video Sharing and Earnings",
  embed_version: "Embed Version",
  embed_version_text:
          "To embed the video player on your website, you need to use the {embed_code}, which can be obtained at {br}" +
          "{manage_file_link}. The code is available in the “Get Links” section, even if {br}" +
          "the video file is still being converted. {br}" +
          "In addition to the player, you can generate preview images in two formats - main frame and a 3x3 frame set.",
  earnings: "Earnings from Sales",
  earnings_text_1:
          "When transitioning from the player to the premium access purchase page, we record the file ID and <br>" +
          "credit the file owner with income from the sale. The percentage of earnings depends on the selected rate plan. <br>",
  earnings_text_2:
          "<strong>For the PPS plan, it\"s 60% of the sales and 40% of rebills, and for the Mixed plan, it's up to 40% of sales and up to 30% of rebills.</strong>>",
  example_video: "Example Video Player",
  addition_info: "Additional Information",
  video_text: "If user don't have premium access, they'll be prompted to purchase it after 1-3 minutes <br>of free video preview.",
};