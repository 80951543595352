export default {
  bonus_program: 'Programa de premio',
  gold_partners: '"Socios Gold"',
  golden_partner: 'Estado Gold',
  page_subtitle: 'Los socios regulares y eficaces pueden obtener <strong>Oro</strong><br />' + 'estatus y recibir un ingreso adicional hasta <strong>+25%</strong> de los ingresos actuales.',
  how_to_get_gold_status: 'Cómo obtener el estado Gold',
  how_to_get_gold_status_subtitle: 'Te convertirás en <strong>Socio Gold/strong> si alcanzas el nivel ' + 'de los ingresos superior al «precio de almacenamiento de archivos» reflejado en la página estadística durante el mes.',
  earnings: 'Ganancias',
  efficiency_is_above: 'la eficiencia es superior a {value}',
  how_to_get_gold_status_line: 'Para analizar el éxito obtenido, visita la página {link} ' + 'donde aparecen las estadísticas y la eficiencia de los últimos 30 días. ' + 'Al final del mes, si {efficiency} está en el ' + 'el nivel adecuado, el estado {gold_partner} se obtendrá automáticamente y ' + 'recibirás un bono adicional para el próximo mes.',
  how_to_increase_extra_bonus_amount: 'Cómo mejorar el estado Gold y ganar más',
  how_to_increase_extra_bonus_amount_subtitle: 'En cuanto obtengas el estado Gold, ' + 'otorgarás Monedas virtuales, que superan automáticamente el estado de tu nivel.',
  how_to_increase_extra_bonus_amount_line_1: 'obtendrás 1 moneda si en 24 horas conservas la misma o mayor cantidad de descargadas como en ' + '72 horas anteriores, por cada periodo promedio de 24 horas (para Descargas).',
  how_to_increase_extra_bonus_amount_line_2: 'obtendrás 1 moneda si en 24 horas conservas la misma o mayor cantidad de descargadas como en ' + '72 horas anteriores, por cada periodo promedio de 24 horas (ventas).',
  how_to_increase_extra_bonus_amount_line_3: 'Se retira 1 moneda si la eficiencia es constantemente inferior a 0 en los últimos 30 días',
  comparative_table_of_partners_statuses: 'Estados y privilegios',
  comparative_table_of_partners_statuses_subtitle: 'Mantente activo para obtener más monedas y ' + 'incrementar tus ingresos. Cuantas más monedas obtengas, será más alto el estado = más ingresos.',
  comparative_table_of_partners_statuses_hint_1: '¡Atención! Aunque no dispones de la cantidad de monedas suficiente el estado no se degradará. Si el número de monedas acumuladas ' + 'es más bajo de lo que tiene que ser para su estado actual y el estado anterior, resulta que tu estado ' + 'gold se ha degradado. <br />Por ejemplo, si tienes el estado de "Magnato Gold", éste ' + 'se reduce sólo después de que el número de monedas caiga por debajo de 80.',
  comparative_table_of_partners_statuses_hint_2: 'Perderás el estatus de socio Gold si al final del mes ' + 'si la eficiencia es inferior a 0 y tienes 0 monedas.',
  comparative_table_of_partners_statuses_hint_3: 'Compras inseguras, con el estado de "fraude" o "reembolso" ' + 'no se tendrán en cuenta mientras se acumulan las monedas.',
  status: 'Estado',
  efficiency: 'Eficiencia<br />a principios del mes',
  coins: 'Cantidad de monedas<br />al final del día',
  percent_of_earnings: '% de las ganancias'
};