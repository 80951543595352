export default {
  bonus_program: 'Bonus programı',
  gold_partners: '"Altın Ortaklar"',
  golden_partner: 'Altın statüsü',
  page_subtitle: 'Düzenli ve etkili ortaklar  alabilirler <strong>Altın</strong><br />' + 'mevcut gelirin <strong>+%25</strong>\'ine kadar ek gelir elde edin.',
  how_to_get_gold_status: 'Altın durumu nasıl alınır',
  how_to_get_gold_status_subtitle: 'Kazandığınız gelirle <strong>Altın Ortak</strong> olacaksınız' + 'ay boyunca istatistik sayfasına yansıyan "dosya saklama fiyatı" ndan daha yüksektir.',
  earnings: 'Kazançlar',
  efficiency_is_above: 'verimlilik {value} değerinden yüksek',
  how_to_get_gold_status_line: 'Gerçekleştirilen başarıyı analiz etmek için lütfen {link} sayfasına gidin ' + 'son 30 güne ait istatistiklerin ve verimliliğin görüntülendiği yer.' + 'Ayın sonunda, eğer {efficiency} ' + 'uygun seviyede, {gold_partner} durumu otomatik olarak elde edilecek ve ' + 'ertesi ay ek bir bonus alacaksınız.',
  how_to_increase_extra_bonus_amount: 'Altın durumu nasıl yükseltilir ve daha fazla kazanılır',
  how_to_increase_extra_bonus_amount_subtitle: 'Altın statüsünü elde ettiğiniz için etkinlik gerçekleştirdiniz ' + 'durumunuzu otomatik olarak daha yükseğe yükselten sanal Paralar verecektir.',
  how_to_increase_extra_bonus_amount_line_1: 'geçerli 24 saat içinde bir öncekine göre daha fazla veya daha fazla indirmeniz varsa 1 jeton elde edilir ' + '72 saat, her ortalama 24 saatlik süre için (İndirmeler için).',
  how_to_increase_extra_bonus_amount_line_2: 'geçerli 24 saat içinde bir öncekine göre daha fazla veya daha fazla indirmeniz varsa 1 jeton elde edilir ' + '72 saat, her ortalama 24 saatlik süre için (Satışlar için).',
  how_to_increase_extra_bonus_amount_line_3: 'Son 30 günde verimlilik sürekli olarak 0\'dan düşükse 1 Jeton kaldırılır',
  comparative_table_of_partners_statuses: 'Durumlar ve ayrıcalıklar',
  comparative_table_of_partners_statuses_subtitle: 'Daha fazla para almak için aktif kalın ve ' + 'gelir artışı. Daha fazla paraları almak, daha yüksek statü = daha fazla gelir',
  comparative_table_of_partners_statuses_hint_1: 'Önemli! Yeterli jetonunuz olmasa bile durum düşmez. Birikmiş jeton sayısı ise ' + 'mevcut durumunuz ve ondan önceki durumunuz için olması gerekenden daha düşük, ancak o zaman mevcut altınınız ' + 'durum düşürüldü. <br />Örnek olarak, "Altın Mıknatıs" statüsüne sahipseniz, bu ' + 'sadece madeni para sayısı 80\'in altına düştükten sonra düşürülür.',
  comparative_table_of_partners_statuses_hint_2: 'Ayın sonunda Altın Ortak statüsünü kaybedersiniz ' + 'verimlilik 0\'ın altında ve 0 jetonunuz var.',
  comparative_table_of_partners_statuses_hint_3: 'Teminatsız alımları, "dolandırıcılık" Veya "iade" durumu ile, ' + 'paralar tahakkuk ederken dikkate alınmayacaktır.',
  status: 'Durum',
  efficiency: 'Verimlilik<br />ayın başında',
  coins: 'Günün sonunda jeton <br />sayısı',
  percent_of_earnings: '% kazanç'
};