<template>
  <div class="recaptcha" :class="{ 'is-invalid': invalid }">
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="key"
      :load-recaptcha-script="true"
      @verify="verify"
      @expired="expired"
    ></vue-recaptcha>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Recaptcha",
  components: { VueRecaptcha },
  props: ["value", "invalid"],
  computed: {
    key() {
      return process.env.VUE_APP_RECAPTCHA_KEY;
    }
  },
  watch: {
    value(val) {
      if (val === false) {
        this.$refs.recaptcha.reset();
      }
    }
  },
  methods: {
    verify() {
      this.$emit("input", true);
    },
    expired() {
      this.$emit("input", false);
    },
    error() {
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped>
.recaptcha {
  text-align: center;
}
.recaptcha > div {
  display: inline-block;
}
.recaptcha .is-invalid {
  border: 1px solid red;
}
</style>
