/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

const router = new Router(routes);

import { pageLoadingProgressBeforeRoute, pageLoadingProgressAfterRoute } from "@/plugins/pageLoadingProgress";

router.beforeEach(pageLoadingProgressBeforeRoute);
router.afterEach(pageLoadingProgressAfterRoute);

export default router;
