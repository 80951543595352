import Vue from "vue";

export default {
  namespaced: true,
  state: {
    unlimitedStatusStoreFilesDialog: {
      visible: false,
      title: null,
      message: null,
      onCancel: null,
      onConfirm: null
    },
  },
  mutations: {
    setUnlimitedStatusStoreFilesDialog(state, payload) {
      state.unlimitedStatusStoreFilesDialog = payload;
    }
  },
  actions: {
    loadEarningsByMonth() {
      return Vue.axios.get("/dashboard/earnings-by-month");
    },
    loadEarningsByDay(module, params) {
      return Vue.axios.get("/dashboard/earnings-by-day", { params });
    },
    loadInfoBlocksData(module, params) {
      return Vue.axios.get("/dashboard/info-blocks", { params });
    },
    loadInfoBlocksTodayData(module, params) {
      return Vue.axios.get("/dashboard/info-blocks-today", { params });
    },
    loadStoragePeriod() {
      return Vue.axios.get("/dashboard/storage-period");
    },
    loadUnlimitedActivationHistory() {
      return Vue.axios.get("/dashboard/unlimited-activation-history");
    },
    loadUnusedFiles() {
      return Vue.axios.get("/dashboard/unused-files");
    },
    sendUnlimitedStatusRequest(module, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
            .post("/dashboard/activate-unlimited-status", data)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response);
            });
      });
    },
    showUnlimitedStatusStoreFilesDialog({ commit }, payload) {
      commit("setUnlimitedStatusStoreFilesDialog", {
        visible: true,
        title: null,
        message: null,
        onCancel: payload.onCancel,
        onConfirm: payload.onConfirm
      });
    },
    closeUnlimitedStatusStoreFilesDialog({ commit }) {
      commit("setUnlimitedStatusStoreFilesDialog", {
        visible: false,
        title: null,
        message: null,
        onCancel: null,
        onConfirm: null
      });
    },
  }
};
