export default {
  security_frozen_period_notification: 'Dikkat, güvenlik kodunuz değiştirildi {changedAt}<br />' + 'Bu eylem daha sonra kabul edilecektir {allowedAt}',
  security_check: 'Güvenlik kontrolü',
  security_code: 'Güvenlik kodu',
  enter_security_code: 'Güvenlik Kodunu Girin',
  wrong_code: 'Hatalı Güvenlik Kodu',
  password_changed_successfully: 'Şifreniz başarılı bir şekilde değiştirilmiştir!',
  password_changing_url_is_invalid: 'Parolanızı değiştirme bağlantısı artık geçerli değil!',
  sections: {
    dashboard: 'Kontrol Paneli',
    statistics: 'İstatistikler',
    stats: {
      sources: 'Kaynaklar',
      sales: 'Satın almalar',
      files: 'Dosyalar',
      'files-copies': 'Dosya kopyaları',
      detailed: 'Ayrıntılı istatistikler'
    },
    earnings: 'Gelir',
    referrals: 'Referanslar',
    sites: 'Sitelerim'
  },
  notifications: 'Bildirimler',
  view_notifications: 'Tüm bildirimleri görüntüle',
  mark_as_read: 'Okundu olarak işaretle',
  messages_and_notifications: 'Mesajlar ve bildirimler',
  news: 'Haberler',
  news_and_updates: 'Haberler ve güncellemeler',
  file_manager: 'Dosya Yöneticisi',
  menu: {
    tools: 'Giriş yap',
    fd1_login: 'Giriş yap turbobit.net',
    fd2_login: 'Giriş yap hitfile.net'
  },
  help: 'Yardım',
  faq: 'SSS',
  support_center: 'Destek merkezi',
  additional_information_faq: 'İlave Bilgi (SSS)',
  activation_in_process: 'Aktivasyon yapılıyor',
  feedback_link_text: 'Bir şey çalışmıyor mu? Bize yazın',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'Dosya yükle',
    Payouts: 'Ödemeler',
    'Site referals': 'Web sitesi referansları',
    'Downloads and statistics': 'İndirme istatistikleri',
    Purchases: 'Satın almalar',
    'Other questions ': 'Diğer sorular '
  },
  get_started: 'Başlayın',
  sidebar_nav: {
    dashboard: 'Kontrol Paneli',
    payouts: 'Ödemeler',
    turbobit_net: 'Turbobit.net',
    statistics: 'İstatistikler',
    detailed: 'Detaylı',
    files: 'Dosyalar',
    sales: 'Satışlar',
    sources: 'Kaynaklar',
    files_copies: 'Dosya kopyaları',
    earnings: 'Gelirler',
    gold_partners: 'Altın Ortaklar',
    my_sites: 'Sitelerim',
    referrals: 'Başvurular',
    hitfile_net: 'Hitfile.net',
    activate: 'Etkinleştir',
    conditions: 'Koşullar ve Tarife planları',
    affiliate_programs: 'Ortaklık Programları'
  },
  months: {
    full: {
      1: 'Ocak',
      2: 'Şubat',
      3: 'Mart',
      4: 'Nisan',
      5: 'Mayıs',
      6: 'Haziran',
      7: 'Temmuz',
      8: 'Ağustos',
      9: 'Eylül',
      10: 'Ekim',
      11: 'Kasım',
      12: 'Aralık'
    },
    short: {
      1: 'Ocak',
      2: 'Şub',
      3: 'Mar',
      4: 'Nisan',
      5: 'Mayıs',
      6: 'Haz',
      7: 'Tem',
      8: 'Ağu',
      9: 'Eyl',
      10: 'Eki',
      11: 'Kasım',
      12: 'Ara'
    }
  },
  need_security_password: 'Hesap şifresi gerekli',
  enter_security_password: 'Lütfen parola girin',
  wrong_password: 'Yanlış şifre',
  password_check: 'Şifre kontrolü'
};