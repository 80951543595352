export default {
  security_frozen_period_notification: 'Achtung, Ihr Sicherheitscode wurde geändert {changedAt}<br />' + 'Diese Aktion wird nach {allowedAt} freigegeben',
  security_check: 'Sicherheitskontrolle',
  security_code: 'Sicherheitskode',
  enter_security_code: 'Sicherheitscode eingeben',
  wrong_code: 'Sicherheitscode falsch',
  password_changed_successfully: 'Ihr Passwort wurde erfolgreich geändert!',
  password_changing_url_is_invalid: 'Der Link zum Ändern Ihres Passworts ist nicht mehr gültig!',
  sections: {
    dashboard: 'Dashboard',
    statistics: 'Statistik',
    stats: {
      sources: 'Quellen',
      sales: 'Einkäufe',
      files: 'Dateien',
      'files-copies': 'Kopien von Dateien',
      detailed: 'Detaillierte Statistik'
    },
    earnings: 'Einnahmen',
    referrals: 'Empfehlungen',
    sites: 'Meine Websites'
  },
  notifications: 'Benachrichtigungen',
  view_notifications: 'Alle Benachrichtigungen anzeigen',
  mark_as_read: 'Als gelesen markieren',
  messages_and_notifications: 'Nachrichten und Benachrichtigungen',
  news: 'News',
  news_and_updates: 'Neuigkeiten und Updates',
  file_manager: 'Dateimanager',
  menu: {
    tools: 'Bei anmelden',
    fd1_login: 'Bei turbobit.net anmelden',
    fd2_login: 'Bei hitfile.net anmelden'
  },
  help: 'Hilfe',
  faq: 'FAQ',
  support_center: 'Hilfecenter',
  additional_information_faq: 'Zusätzliche Informationen (FAQ)',
  activation_in_process: 'Aktivierung zurzeit im Gange',
  feedback_link_text: 'Funktioniert etwas nicht? Schreiben Sie uns',
  feedback_link: 'https://help2.costaction.com',
  faq_categories: {
    'File upload': 'Datei-Upload',
    Payouts: 'Auszahlungen',
    'Site referals': 'Website-Empfehlungen',
    'Downloads and statistics': 'Downloads und Statistik',
    Purchases: 'Einkäufe',
    'Other questions ': 'Weitere Fragen '
  },
  get_started: 'Starte los',
  sidebar_nav: {
    dashboard: 'Dashboard',
    payouts: 'Auszahlungen',
    turbobit_net: 'Turbobit.net',
    statistics: 'Statistik',
    detailed: 'Im Detail',
    files: 'Dateien',
    sales: 'Verkäufe',
    sources: 'Quellen',
    files_copies: 'Kopien der Dateien',
    earnings: 'Einnahmen',
    gold_partners: 'Gold-Partner',
    my_sites: 'Meine Webseiten',
    referrals: 'Freunden empfehlen',
    hitfile_net: 'Hitfile.net',
    activate: 'Aktivieren',
    conditions: 'Bedingungen und Pläne',
    affiliate_programs: 'Partnerprogramme'
  },
  months: {
    full: {
      1: 'Januar',
      2: 'Februar',
      3: 'März',
      4: 'April',
      5: 'Mai',
      6: 'Juni',
      7: 'Juli',
      8: 'August',
      9: 'September',
      10: 'Oktober',
      11: 'November',
      12: 'Dezember'
    },
    short: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mrz',
      4: 'Apr',
      5: 'Mai',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Okt',
      11: 'Nov',
      12: 'Dez'
    }
  },
  need_security_password: 'Kontopasswort erforderlich',
  enter_security_password: 'Bitte geben Sie Ihr Passwort ein',
  wrong_password: 'Falsches Passwort',
  password_check: 'Passwort überprüfen'
};