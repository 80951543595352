export default {
  main: {
    periods: {
      '7D': 'Son 7 gün',
      '30D': 'Son 30 Gün',
      W: 'Bu hafta',
      M: 'Bu ay'
    }
  },
  info_blocks: {
    earnings: {
      title: 'Gelir',
      today: 'Günün Kazancı',
      stat_volume: '${volume}',
      today_volume: '${volume}'
    },
    downloads: {
      title: 'indirmeler',
      today: 'Bugünün indirmeleri',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'Satın Alımlar',
      today: 'Bugün Satın Alınanlar',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'Bakiye',
      link: 'Beklemedeki Tutar',
      volume: '+{volume}',
      hold: '+{volume}'
    }
  },
  programs: {
    title: 'Ortaklık Programları',
    columns: {
      name: 'Proje',
      description: 'Koşullar',
      status: 'Durum'
    },
    status: {
      on: 'Aktif',
      off: 'Etkinleştirilmedi'
    },
    conditions: 'Koşullar ve Tarife planları'
  },
  files_storage_blocks: {
    title: 'Dosyanızın depolanma süresi',
    title_link: 'daha fazlasını oku',
    term: 'Depolama süreniz: <b>{normal_term} günler</b> (<i>' + ' <b>{premium_term} günler</b> premiumla birlikte</i>)',
    next_update: 'Bir sonraki durum güncellemesi açık {next_update}'
  },
  earnings_table: {
    title: 'Aylara Göre Gelir',
    fields: {
      date: 'Ay',
      money: 'Kazanılan',
      files_size: 'Dosya Boyutu (GB)*',
      storage_cost: 'Depolama maliyeti**'
    },
    last30days_row_title: 'son 30 Gün',
    notes: {
      copies: '* Dosyaların kopyalarını (fiziksel ve sanal) saymıyoruz.',
      storage_cost: '** Dosya depolama alanı tamamen ücretsizdir, alan da sınırsızdır. Hesaplamak için "Depolama maliyeti" grafiğini kullanıyoruz' + ' sağlanabilecek dosya saklama süresi.',
      unlimited_period: 'Bu nedenle, son 30 gün içinde 1 dolardan fazla geliri olan ortaklar için ' + 've gelirin pozitif bir varyansı to\n' + ' depolama maliyeti, dosyalar ve depolama süresi için sınırsızdır.',
      calculate_cost: 'Sunucularımızdaki alanı ayda 1Gb başına 0,016 Abd Doları olarak değerlendiriyoruz ' + 've "depolama maliyeti" grafiği bu şekilde hesaplanır.'
    }
  },
  earnings_chart: { title: 'Gelen Projeler' }
};