<template>
  <v-list-item
    v-if="isVisibleItem(item)"
    v-ripple="{ class: 'primary--text' }"
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    :active-class="` ${isDark ? ' grey--text text--lighten-5' : 'primary--text'}`"
    :height="48"
    :class="hasParent ? 'pl-6' : ''"
    class="text-18"
    @click="onClick"
  >
    <v-list-item-icon v-if="text" class="v-list-item__icon--text" v-text="computedText" />

    <v-list-item-icon v-else-if="item.icon" :style="{ 'margin-right': isRTL ? '0' : '20px' }">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title || item.subtitle" class="text-13">
      <v-list-item-title class="ma-0 text-13" v-text="item.title" />

      <v-list-item-subtitle v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";

export default {
  name: "Item",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        title: undefined,
        to: undefined,
        hasParent: false,
        onClick: null
      })
    },
    text: {
      type: Boolean,
      default: false
    },
    hasParent: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isRTL() {
      return this.$vuetify.rtl;
    },
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach(val => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href;
    }
  },

  methods: {
    /**
     * В задаче #6293 есть требование скрывать пункт в меню, для этого реализуем
     * возможность указать условие для отображения меню
     * Можно указать bool или callback
     */
    isVisibleItem(item) {
      if (!item.hasOwnProperty("visible")) {
        return true;
      }

      if (typeof item.visible === "boolean") {
        return item.visible;
      }

      return item.visible(this);
    },

    onClick() {
      if (this.item.onClick) {
        this.item.onClick(this);
      }
    }
  }
};
</script>
