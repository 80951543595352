export default {
  today_stat: {
    title: 'Receita de hoje',
    sub_title: '(atualizada automaticamente a cada quatro horas)',
    refresh: 'Atualizar'
  },
  filters: {
    title: 'Índice de Receita',
    date_from: 'Data de início',
    date_to: 'Data de conclusão',
    group_by: 'Agrupar por',
    group: {
      day: 'Dias',
      week: 'Semanas',
      month: 'Meses'
    },
    switcher: 'Filtros'
  },
  gold_status: {
    title: 'Programa «Parceiros gold»',
    current_status: 'Status atual',
    next_status: 'Próximo status',
    levels: {
      level_0: 'Parceiro Padrão',
      level_1: 'Parceiros gold',
      level_2: 'Cartaz gold +{percent}%',
      level_3: 'Gold master +{percent}%',
      level_4: 'Administrador gold +{percent}%',
      level_5: 'Herói gold +{percent}%',
      level_6: 'Magnata gold +{percent}%'
    },
    efficiency: 'eficiência {value}',
    coins: '{value} moedas',
    next_level_condition: {
      wait_next_period: 'Espere um mês para alcançar',
      increase_efficiency: 'Aumente a eficiência em {value} para alcançar',
      maximum_level: 'Você atingiu o status mais alto disponível. Continue assim!',
      need_more_coins: 'Você deve adicionar mais {value} moedas para alcançar'
    }
  },
  earnings_table: {
    title: 'Estatísticas detalhadas',
    fields: {
      date: 'Data',
      files: 'Arquivos',
      sites: 'Indicações do site',
      referrals: 'Indicações',
      additional: 'Adicional*',
      total: 'Total',
      efficiency: 'Eficiência**',
      coins: 'Moedas'
    }
  },
  notes: {
    additional: '*Adicionais de eventos especiais, análise de compensação e etc.',
    efficiency: '**Por favor, leia sobre <a href=\'{efficiency_url}\'>&laquo;Eficiência&raquo;</a> e ' + '<a href=\'{gold_url}\'>&laquo;Programa bônus &laquo;Parceiros Gold&raquo;</a> em <a href=\'{faq_url}\'>Perguntas Frequentes</a>'
  }
};