export default {
  page_title: "Bedingungen und Tarife",
  page_subtitle: "Partner-Programmeinstellungen, Kurspläne mit Beschreibungen und Optionen für zusätzliche Einnahmen.",
  fd1_partners_program_settings: "Turbobit Affiliate-Programmeinstellungen",
  fd2_partners_program_settings: "Hitfile Affiliate Programmeinstellungen",
  your_current_plan: "Aktueller Kursplan:",
  rate_plan_from_next_day: "Der am nächsten Tag in Kraft befindliche Kurs:",
  change_rate_plan_hint: "Wichtig: Sie können den Tarif einmal pro Woche ändern.",
  change_rate_plan: "Änderungen absenden",
  turbobit_rate_plans: "Turbobit-Tarife Pläne",
  hitfile_rate_plans: "Hitfile Rate Pläne",
  premium_sale: "Premium-Verkäufe (Pro Verkauf)",
  rates_for_sale_of_premium_accounts: "Tarife für den Verkauf des Premium-Kontos (Turbo-Zugang)",
  rates_for_sale_of_premium_accounts_hitfile: "Tarife für den Verkauf des Premium-Kontos (Premium-Zugang)",
  country: "Land",
  percent_of_sale: "% des Verkaufs",
  percent_of_rebill: "% der Nachzahlung",
  all_countries: "Alle Länder",
  mixed: "Gemischt (Pro Verkauf & Downloads)",
  mixed_hint:
    "Ein gemischter Tarif für Einnahmen aus Downloads Ihrer Dateien zusammen mit Verkäufen von Premium-Konten",
  rates: "Raten",
  group: "Gruppe",
  for_1000_downloads: "für 1000 Downloads",
  for_1000_downloads_hint:
    "nur Downloads von Dateien größer als 10 MB werden gezählt, " +
    "unvollständige Downloads inklusive Einzigartigkeit wird im Kontext eines Kontos berechnet",
  group_and_countries: "Gruppen und Länder",
  mixed_countries_g: "Andere Länder",
  all_inclusive: "Alles Inklusiv (Pro Download)",
  all_inclusive_hint: "Der Tarif für Einnahmen durch Downloads, basierend auf Dateigröße",
  rate_for_downloading: "Preise für 1.000 Downloads deiner Dateien",
  size_country: "Größe/Land",
  rate_for_downloading_hint:
    "unvollständige Downloads inklusive Einzigartigkeit wird im Kontext eines Kontos berechnet",
  all_inclusive_countries_d: "Andere Länder",
  additional_earnings: "Deine Einnahmen erhöhen",
  bonus_program: "Bonus-Programm",
  gold_partners: "Gold-Partner",
  increased_payments_and_extra_bonuses: "Erhöhte Raten und zusätzliche Boni für regelmäßige und effektive Partner",
  increased_payments_and_extra_bonuses_1: "Bis zu <b>85%</b> des Umsatzes für den PPS-Plan;",
  increased_payments_and_extra_bonuses_2:
    "Bis zu <b>60%</b> von Verkäufen + $8,75 pro 1000 Downloads für den Mixed-Plan;",
  increased_payments_and_extra_bonuses_3: "Bis zu <b>$25</b> pro 1000 Downloads für den PPD-Plan.",
  for: "Für",
  site_owners: "Inhaber der Website",
  for_site_owners_line_1:
    "Holen Sie sich <b>+{percents}% der Verkäufe von Nutzern, die von Ihrer Website verwiesen wurden,</b>, Blog, Forum, " +
    "Social-Netzwerk-Seite unabhängig vom Datei-Eigentümer.",
  for_site_owners_line_2: "Verwende Webseite und teile Hitfile-Links " + "um zusätzliche Einnahmen zu generieren.",
  referral_system: "Empfehlungssystem",
  referral_system_text:
    "Erhalte <b>12%</b> des Einkommens neuer Partner sobald mit Deinem Empfehlungslink registriert " +
    "im Affiliate-Programm in den nächsten 3 Monaten.<br />" +
    "<i>Nach drei Monaten vergangen:</i><br />" +
    "<b>6%</b> der eingeladenen Partner-Einnahmen im nächsten 6 Monaten;<br />" +
    "<b>3%</b> der eingeladenen Partner Einnahmen nach diesen 6 Monaten. <br /><br />" +
    "Darüber hinaus erhältst Du aufgrund des zweistufigen Überweisungssystems 1% der Einnahmen aus " +
    "jeder Partner, der dem Affiliate-Programm von Dir vorgestellt wurde. <br />" +
    "Wenn Du keine anderen Einnahmen hast, außer Bonuseinnahmen von Deinen Empfehlungen, dem Prozentsatz von " +
    "Bonusempfehlungssystemeinnahmen sinken bis sie 0% erreichen. Die Überprüfung wird monatlich durchgeführt.",
  price_of_premium_account_for_users: "Preis für Premium-Accounts für Benutzer",
  price_of_premium_account_for_users_subtitle:
    "Wählen Sie die Preise aus. Später können Sie auch die Preise festlegen " +
    "individuell für jede Datei/jeden Ordner",
  standard_rates: "Standardtarife",
  expensive_rates: "Aufwendige Tarife",
  my_price: "Mein Preis",
  make_your_own_price_rates_variation: "Machen Sie Ihre eigenen Preisschwankungen (wählen Sie bis zu {value})",
  free_download_options: "Freie Download-Optionen",
  free_download_options_available_only_on_pps_rate_plan:
    "Kostenlose Download-Einstellungen nur für den PPS-Tarif verfügbar",
  allow_a_free_download_of_all_files: "Erlaube den kostenlosen Download aller Dateien",
  allow_a_free_download_of_all_files_hint:
    "Alle Dateien aus allen Ordnern stehen zum kostenlosen Download zur Verfügung. " +
    "Individuelle Einstellungen von Dateien und Ordnern werden ignoriert.",
  disallow_a_free_download_of_all_files: "Kostenlosen Download aller Dateien verbieten",
  disallow_a_free_download_of_all_files_hint:
    "Alle Dateien aus allen Ordnern sind verfügbar für " +
    "nur mit Premium-Zugriff herunterladen. Individuelle Einstellungen für Dateien und Ordner werden ignoriert.",
  use_individual_settings_of_files_and_folders: "Die Standard-Download-Einstellungen für neu hochgeladene Dateien",
  use_individual_settings_of_files_and_folders_hint:
    "Die Verfügbarkeit eines kostenlosen Downloads hängt von der individuellen Verfügbarkeit ab " +
    "Einstellungen, die manuell für einen bestimmten Ordner oder eine bestimmte Datei ausgewählt wurden, ansonsten werden die Standardeinstellungen angewendet.",
  inbox_free_download_label:
    'Wählen Sie die Standardeinstellungen für kürzlich hochgeladene Dateien in Ihren "Posteingang"-Ordner:',
  allow_free_download: "Kostenlosen Download erlauben",
  disallow_free_download: "Kostenlosen Download verbieten",
  the_maximum_file_size_available_for_free_downloads: "Die maximale Dateigröße zum kostenlosen Download",
  the_maximum_file_size_available_for_free_downloads_label:
    "Dateien größer als eine bestimmte Größe werden nicht verfügbar " + "Kostenlosen Download erlauben:",
  free_download: { unlimited: "keine Begrenzung" }
};
