export default {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  close: 'Fechar',
  save: 'Salvar',
  all_fields_are_required: '* todos os campos são obrigatórios',
  ok: 'OK',
  loading: 'Carregando...',
  wait: 'Aguarde...',
  delete: 'Excluir',
  reset: 'Redefinir',
  refresh: 'Atualizar',
  continue: 'Continuar',
  skip: 'Pular',
  done: 'Concluído',
  send: 'Enviar',
  filters: 'Filtros',
  tables: { total: 'Total' },
  error: 'Erro',
  try_later: 'Ocorreu um erro. Tente mais tarde',
  form: {
    errors: {
      required: '{field} é obrigatório',
      number: 'Por favor, insira um número válido',
      max: 'O valor deve ser menor ou igual a {value}',
      min: 'o valor deve ser maior ou igual a 40.00'
    }
  },
  email: 'E-mail',
  password: 'Senha'
};