export default {
  page_title: "Conditions et forfaits",
  page_subtitle:
    "Réglages du programme d’affiliation, forfaits avec descriptions et options pour des revenus supplémentaires.",
  fd1_partners_program_settings: "Paramètres du programme d'affiliation Turbobit",
  fd2_partners_program_settings: "Paramètres du programme d'affiliation Hitfile",
  your_current_plan: "Forfait actuel :",
  rate_plan_from_next_day: "Le plan tarifaire en vigueur le lendemain:",
  change_rate_plan_hint: "Important : Vous pouvez modifier le tarif une fois par semaine.",
  change_rate_plan: "Soumettre les modifications",
  turbobit_rate_plans: "Tarif des plans Turbobits",
  hitfile_rate_plans: "Plans de taux Hitfile",
  premium_sale: "Ventes Premium (Paiement par vente)",
  rates_for_sale_of_premium_accounts: "Taux de vente de compte premium (accès turbo)",
  rates_for_sale_of_premium_accounts_hitfile: "Tarifs pour la vente d'un compte premium (accès premium)",
  country: "Pays",
  percent_of_sale: "% de vente",
  percent_of_rebill: "% de la refacturation",
  all_countries: "Tous les pays",
  mixed: "Mixte - Paiement à la vente et téléchargements)",
  mixed_hint:
    "Un plan tarifaire mixte permettant de tirer un revenu des téléchargements de vos fichiers ainsi que des ventes de comptes premium",
  rates: "Taux",
  group: "Groupe",
  for_1000_downloads: "pour 1000 téléchargements",
  for_1000_downloads_hint:
    "seuls les téléchargements de fichiers de plus de 10MB sont comptés, " +
    "téléchargements incomplets inclus. L'unicité est calculée dans le contexte d'un compte",
  group_and_countries: "Groupes et pays",
  mixed_countries_g: "Autres pays",
  all_inclusive: "Tout compris (paiement par téléchargement)",
  all_inclusive_hint: "Le plan de taux pour faire un revenu par téléchargement, basé sur la taille du fichier",
  rate_for_downloading: "Taux pour 1 000 téléchargements de vos fichiers",
  size_country: "Taille/Pays",
  rate_for_downloading_hint: "téléchargements incomplets inclus. L'unicité est calculée dans le contexte d'un compte",
  all_inclusive_countries_d: "Autres pays",
  additional_earnings: "Augmenter votre revenu",
  bonus_program: "Programme bonus",
  gold_partners: "Partenaires Gold",
  increased_payments_and_extra_bonuses:
    "Augmentation des taux et des bonus supplémentaires pour les partenaires réguliers et efficaces",
  increased_payments_and_extra_bonuses_1: "Jusqu'à <b>85 %</b> de ventes pour le plan PPS ;",
  increased_payments_and_extra_bonuses_2:
    "Jusqu'à <b>60 %</b> de ventes + 8,75 $ par 1000 téléchargements pour le plan mixte ;",
  increased_payments_and_extra_bonuses_3: "Jusqu'à <b>25 $</b> par 1000 téléchargements pour le forfait PPD.",
  for: "Pour",
  site_owners: "Propriétaire du site",
  for_site_owners_line_1:
    "Obtenez <b>+ {percents}% des ventes réalisées par les utilisateurs référencés sur votre site web</b>, blog, forum, " +
    "page du réseau social quel que soit le propriétaire du fichier.",
  for_site_owners_line_2:
    "Utilisez votre site web et partagez des liens Hitfile " + "pour générer des revenus supplémentaires.",
  referral_system: "Système de parrainage",
  referral_system_text:
    "Obtenez <b>12%</b> des gains des nouveaux partenaires dès qu'ils se sont inscrits avec votre lien de parrainage " +
    "dans le programme d'affiliation au cours des 3 prochains mois.<br />" +
    "<i>Après trois mois passés :</i><br />" +
    "<b>6%</b> des gains du partenaire invité au cours des 6 prochains mois ;<br />" +
    "<b>3%</b> des gains du partenaire invité après les 6 mois suivants. <br /><br />" +
    "En outre, en raison du système de parrainage à deux niveaux, tu obtiens 1% des revenus de " +
    "chaque partenaire qui a été présenté au programme d'affiliation par vous. <br />" +
    "Si vous n'avez aucun autre revenu à l'exception des revenus bonus faits par vos recommandations, le pourcentage de " +
    "le système de parrainage bonus commence à diminuer jusqu'à ce qu'il atteigne 0%. La vérification est effectuée mensuellement.",
  price_of_premium_account_for_users: "Prix des comptes Premium pour les utilisateurs",
  price_of_premium_account_for_users_subtitle:
    "Sélectionnez les tarifs plus tard. Vous pouvez également définir les tarifs " +
    "individuellement pour chaque fichier/dossier",
  standard_rates: "Tarif standard",
  expensive_rates: "Tarifs coûteux",
  my_price: "Mon prix",
  make_your_own_price_rates_variation: "Faites vos propres variations de prix (sélectionnez jusqu'à {value})",
  free_download_options: "Options de téléchargement gratuit",
  free_download_options_available_only_on_pps_rate_plan:
    "Les paramètres de téléchargement gratuit ne sont disponibles que pour le tarif PPS",
  allow_a_free_download_of_all_files: "Autoriser le téléchargement gratuit de tous les fichiers",
  allow_a_free_download_of_all_files_hint:
    "Tous les fichiers de tous les dossiers seront disponibles pour téléchargement gratuit. " +
    "Les paramètres individuels des fichiers et des dossiers seront ignorés.",
  disallow_a_free_download_of_all_files: "Refuser le téléchargement gratuit de tous les fichiers",
  disallow_a_free_download_of_all_files_hint:
    "Tous les fichiers de tous les dossiers seront disponibles pour " +
    "télécharger uniquement avec un accès Premium. Les paramètres individuels pour les fichiers et les dossiers seront ignorés.",
  use_individual_settings_of_files_and_folders:
    "Les paramètres de téléchargement par défaut des fichiers nouvellement téléchargés",
  use_individual_settings_of_files_and_folders_hint:
    "La disponibilité d'un téléchargement gratuit dépend de l'individu " +
    "paramètres qui ont été sélectionnés manuellement pour un dossier ou un fichier spécifique. Sinon, les paramètres par défaut ont été appliqués.",
  inbox_free_download_label:
    'Sélectionnez les paramètres par défaut pour les fichiers récemment téléchargés dans votre dossier " Boîte de réception " :',
  allow_free_download: "Autoriser le téléchargement gratuit",
  disallow_free_download: "Désactiver le téléchargement gratuit",
  the_maximum_file_size_available_for_free_downloads:
    "La taille maximale des fichiers disponibles pour les téléchargements gratuits",
  the_maximum_file_size_available_for_free_downloads_label:
    "La taille maximale des fichiers disponibles pour les téléchargements gratuits. label. restant " +
    "pour téléchargement gratuit :",
  free_download: { unlimited: "aucune limite" }
};
