export default {
  main: {
    periods: {
      '7D': 'اخر 7 ايام',
      '30D': 'آخر 30 يوماً',
      W: 'هذا الأسبوع',
      M: 'هذا الشهر'
    }
  },
  info_blocks: {
    earnings: {
      title: 'الإيرادات',
      today: 'إيرادات اليوم',
      stat_volume: '${volume}',
      today_volume: '+${volume}'
    },
    downloads: {
      title: 'التحميلات',
      today: 'تحميلات اليوم',
      today_volume: '+{volume}'
    },
    sales: {
      title: 'المشتريات',
      today: 'مشتريات اليوم',
      today_volume: '+{volume}'
    },
    balance: {
      title: 'الرصيد',
      link: 'المبلغ المعلق',
      volume: '${volume}',
      hold: '${volume}'
    }
  },
  programs: {
    title: 'برامج الشركاء',
    columns: {
      name: 'المشروع',
      description: 'الشروط',
      status: 'الحالة'
    },
    status: {
      on: 'مفعل',
      off: 'غير مفعل'
    },
    conditions: 'الشروط والخطط'
  },
  files_storage_blocks: {
    title: 'مدة تخزين الملفات',
    title_link: 'إقرأ المزيد',
    term: 'فترة التخزين الخاصة بك: <b>{normal_term} أيام</b> (<i>' + ' <b>{premium_term} أيام</b> مع النسخة المميزة</i>)',
    next_update: 'تحديث الحالة التالية في {next_update}'
  },
  earnings_table: {
    title: 'الايرادات بالشهور',
    fields: {
      date: 'الشهر',
      money: 'المكتسبة',
      files_size: 'حجم الملف (GB)*',
      storage_cost: 'تكلفة التخزين**'
    },
    last30days_row_title: 'آخر 30 يوماً',
    notes: {
      copies: '* نحن لا نأخذ في الحسبان نسخ الملفات (المادية والافتراضية).',
      storage_cost: '** تخزين الملفات مجاني تمامًا ، كما أن المساحة غير محدودة. نستخدم الرسوم البياني "تكلفة التخزين" للحساب\n' + ' فترة تخزين الملفات التي يمكننا توفيرها.',
      unlimited_period: 'لذلك ، بالنسبة للشركاء الذين يزيد دخلهم عن1$ خلال الثلاثين يومًا الماضية ' + 'والفرق الإيجابي للدخل ل\n' + ' تكلفة التخزين، وقت التخزين للملفات غير محدودة.',
      calculate_cost: 'نقوم بتقييم المساحة على خوادمنا بسعر 0.016$ لكل 1 جيجابايت شهريًا ' + 'وتلك هي الطريقة التي يتم بها حساب "تكلفة التخزين".'
    }
  },
  earnings_chart: { title: 'الإيرادات بحسب المشاريع' }
};