export default {
  bonus_program: "Бонусная программа",
  gold_partners: "«Золотые партнеры»",
  golden_partner: "Золотой статус",
  page_subtitle:
    "Постоянные и эффективные партнеры могут получить <strong>Gold</strong><br />" +
    "статус и получать дополнительный доход до <strong>+25%</strong> от текущих доходов.",
  how_to_get_gold_status: 'Как получить статус "Gold"',
  how_to_get_gold_status_subtitle:
    "Вы станете <strong> Золотым партнером </strong>, если уровень заработка " +
    'в течение месяца выше, чем сумма в графе "стоимость хранения", отображаемая на странице статистики.',
  earnings: "Доходы",
  efficiency_is_above: "эффективность выше, чем {value}",
  how_to_get_gold_status_line:
    "Для анализа успешности выполненной работы перейдите на страницу {link} " +
    "где отображается статистика и эффективность за последние 30 дней. " +
    "В конце месяца, если {efficiency} находится на достаточном " +
    "уровне, статус {gold_partner} будет получен автоматически и " +
    "в следующем месяце вы получите дополнительный бонус.",
  how_to_increase_extra_bonus_amount: "Как повысить золотой статус и зарабатывать больше",
  how_to_increase_extra_bonus_amount_subtitle:
    "После того, как вы получили статус Gold, поддерживая активность " +
    "вы будете зарабатывать виртуальные монеты, которые автоматически повышают Gold статус до более высокого.",
  how_to_increase_extra_bonus_amount_line_1:
    "1 монета будет получена, если за текущие 24 часа у вас будет столько же или больше скачиваний, " +
    "столько и за предыдущие " +
    "72 часа, в каждый 24-часовой период (для Скачиваний).",
  how_to_increase_extra_bonus_amount_line_2:
    "1 монета будет получена, если за текущие 24 часа у вас будет столько же или больше покупок, " +
    "столько и за предыдущие " +
    "72 часа, в каждый 24-часовой период (для Покупок).",
  how_to_increase_extra_bonus_amount_line_3:
    "1 монета удаляется, если эффективность постоянно ниже 0 в течение последних 30 дней",
  comparative_table_of_partners_statuses: "Статусы и привилегии",
  comparative_table_of_partners_statuses_subtitle:
    "Поддерживайте активность, чтобы получить больше монет и " +
    "увеличить доход. Чем больше монет вы получите, тем выше статус = больше дохода.",
  comparative_table_of_partners_statuses_hint_1:
    "Обратите внимание! Статус не будет понижаться, даже если у вас недостаточно монет. " +
    "Если количество накопленных монет " +
    "ниже, чем должно быть для вашего текущего статуса и статуса до него, только тогда текущий статус " +
    'начнёт понижается. <br /> Например, если у вас был статус "Gold Magnate", он ' +
    "понижается только после того, как количество монет упадет ниже 80.",
  comparative_table_of_partners_statuses_hint_2:
    "Вы теряете статус Gold, если в конце месяца " + "эффективность ниже нуля количество монет равно 0.",
  comparative_table_of_partners_statuses_hint_3:
    'Небезопасные покупки со статусом "fraud" или "refund", ' + "не учитываются при начислении монет.",
  status: "Статус",
  efficiency: "Эффективность<br />на начало месяца",
  coins: "Количество монет<br />в конце дня",
  percent_of_earnings: "% к доходу"
};
