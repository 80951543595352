export default {
  main_block_title: "Domains",
  search_placeholder: "Search by domain",
  dates_placeholder: "Date range",
  domain_table: {
    fields: {
      domain: "Sources",
      hits: "Visits",
      downloads: "Downloads",
      unique_downloads: "Unique downloads",
      purchases: "Purchases"
    },
    detailed_row_title: "Detailed statistic:"
  },
  url_table: { fields: { url: "Url" } }
};
