export default {
  page_header: 'Einstellungen',
  page_subtitle: 'Verwalten Sie Ihre Infos, Privatsphäre- und Sicherheitsoptionen.{br}Auszahlungsdetails können auf {url} Seite bearbeitet werden',
  payouts: '«Auszahlungen»',
  personal_info: 'Persönliche info',
  change_password: 'Passwort ändern',
  security_options: 'Sicherheitsoptionen',
  email: 'E-Mail',
  first_name: 'Vorname',
  last_name: 'Nachname',
  contact_details: 'Kontakt-Details',
  contact_details_hint: 'Telegram, Skype oder jeder andere Kontakttyp',
  update_personal_info: 'Persönliche Info aktualisieren',
  old_password: 'Altes Kennwort',
  new_password: 'Neues Passwort:',
  new_password_confirm: 'Neues Passwort bestätigen',
  change_password_submit: 'Passwort ändern',
  security_code: 'Sicherheitskode',
  security_code_description: 'Eine zusätzliche Sicherheitsmaßnahme, die auf eigene Faust aktiviert werden kann. ' + 'Beim Löschen von Dateien anfordern von Guthaben oder Änderung der Auszahlungsdetails ' + 'das System eine Sicherheitscode Bestätigung benötigt.',
  use_security_code_checkbox_label: 'Aktiviere die Bestätigung von Aktionen durch Sicherheitscode',
  security_code_field_label: 'Sicherheitskode',
  security_code_confirmation_field_label: 'Sicherheitscode (Bestätigung)',
  security_code_hint: '*Vorsicht, Sicherheitscode kann nicht wiederhergestellt werden. ' + 'Der neue Sicherheitscode tritt in 15 Tagen in Kraft, während dieses Zeitraums alle Operationen mit Mitteln ' + ', Auszahlungsdetails und das Löschen von Dateien werden eingefroren.',
  update_security_options: 'Test-Sicherheitsoptionen',
  password_hacking_prevention_system: 'Passwort-Hacking-Präventionssystem',
  limit_login_attempts_field_label: 'Begrenzung der fehlgeschlagenen Anmeldeversuche',
  limit_login_attempts_field_hint: 'Nach 10 erfolglosen Versuchen wird eine Autorisierung per E-Mail erforderlich',
  use_captcha_field_label: 'Captcha zum Schutz meines Kontos aktivieren',
  use_captcha_field_hint: 'Besonders empfohlen um diese Option zu aktivieren',
  errors: {
    password_not_match_confirmation: 'stimmt nicht mit der Bestätigung überein',
    security_code_not_match_confirmation: 'Sicherheitscode erfüllt nicht die bestätigten Anforderungen'
  },
  password_changing_notification_sent: 'Die Benachrichtigung über die Passwortänderung wurde an die bei der Registrierung angegebene E-Mail gesendet.',
  drop_all_sessions: 'Lassen Sie alle Sitzungen verfallen',
  current_security_code_field_label: 'Geben Sie bitte Ihren aktuellen Sicherheitscode ein'
};