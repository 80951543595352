export default {
  today_stat: {
    title: 'Recettes aujourd\'hui',
    sub_title: '(mise à jour automatique toutes les quatre heures)',
    refresh: 'Actualiser'
  },
  filters: {
    title: 'Indice des revenus',
    date_from: 'Date de début ',
    date_to: 'Date de fin',
    group_by: 'Groupe par',
    group: {
      day: 'Jours',
      week: 'Semaines',
      month: 'Mois'
    },
    switcher: 'Filtres'
  },
  gold_status: {
    title: 'Programme « Partenaires Gold »',
    current_status: 'Statut actuel',
    next_status: 'Prochain statut',
    levels: {
      level_0: 'Partenaire standard',
      level_1: 'Partenaires Gold',
      level_2: 'Affiche Gold +{percent}%',
      level_3: 'Gold master +{percent}%',
      level_4: 'Admin Gold +{percent}%',
      level_5: 'Hero Gold +{percent}%',
      level_6: 'Magnat Gold +{percent}%'
    },
    efficiency: 'efficacité {value}',
    coins: '{value} pièces',
    next_level_condition: {
      wait_next_period: 'Attendez un mois pour atteindre',
      increase_efficiency: 'Augmentez l\'efficacité de {value} pour atteindre',
      maximum_level: 'Vous avez atteint le statut le plus élevé disponible. Continuez !',
      need_more_coins: 'Vous devriez ajouter {value} pièce(s) de plus pour atteindre'
    }
  },
  earnings_table: {
    title: 'Statistiques détaillées',
    fields: {
      date: 'Date',
      files: 'Fichiers',
      sites: 'Sites_références',
      referrals: 'Parrainages',
      additional: 'Additionnel',
      total: 'Total',
      efficiency: 'Efficacité**',
      coins: 'Pièces'
    }
  },
  notes: {
    additional: '*Supplémentaire à partir d\'événements spéciaux, d\'accumulations de rémunération, etc.',
    efficiency: '**Veuillez lire à propos de <a href=\'{efficiency_url}\'>&laquo;Efficacité&raquo;</a> et ' + '<a href=\'{gold_url}\'>&laquo;Programme bonus &laquo;Partenaires Gold&raquo;</a> dans <a href=\'{faq_url}\'>FAQ</a>'
  }
};