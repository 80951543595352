import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const DEFAULT_LANG = "en";
const langs = ["en", "ru", "de", "es", "fr", "ja", "tr", "ar", "pt"];

const files = {
  common: "common",
  app: "app",
  userBar: "user-bar",
  auth: "auth",
  titles: "titles"
};

const pages = {
  dashboard: "dashboard",
  payouts: "payouts",
  settings: "settings",
  earnings: "earnings",
  getStarted: "get-started",
  videos: "videos",
  referrals: "referrals",
  sites: "sites",
  gold: "gold",
  rates: "rates",
  pageVideo: "page-video"
};

let trans = {};

for (let key in langs) {
  let lang = langs[key];
  trans[lang] = {};

  for (let fileAlias in files) {
    trans[lang][fileAlias] = require("./" + lang + "/" + files[fileAlias] + ".js").default;
  }

  trans[lang]["pages"] = [];

  for (let pageAlias in pages) {
    trans[lang]["pages"][pageAlias] = require("./" + lang + "/pages/" + pages[pageAlias] + ".js").default;
  }
  trans[lang]["pages"]["statistics"] = {
    sources: require("./" + lang + "/pages/statistics/sources.js").default,
    sales: require("./" + lang + "/pages/statistics/sales.js").default,
    files: require("./" + lang + "/pages/statistics/files.js").default,
    detailed: require("./" + lang + "/pages/statistics/detailed.js").default
  };
}

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: trans
});

export default i18n;
