export default {
  subTitle: "(Learn more about the video)",
  today_stat: {
    title: "Today's Statistics",
    sub_title: "(Automatically updated every four hours)",
    refresh: "Refresh",
    views: "Views",
    viewers: "Viewers",
    new_videos: "New Videos",
    sales: "Sales",
  },
  chart: {
    title: "Daily Chart and Filters",
    target_select: {
      label: "Metric",
      views: "Views",
      viewers: "Unique Viewers",
      sales: "Sales",
    },
    player_select: {
      label: "Player Type",
      all: "All",
      embed: "Embed",
      on_site: "On_site",
      full: "Full",
      demo: "Demo"
    }
  },
  filters: {
    title: "Summarize Revenue",
    date_from: "Start Date",
    date_to: "End Date",
    group_by: "Group By",
    group: {
      day: "Days",
      week: "Weeks",
      month: "Months"
    },
    switcher: "Filters"
  },
  table: {
    title: "Detailed Statistics",
    fields: {
      date: "Date",
      video_files: "Video Files",
      views: "Views*",
      viewers: "Unique Viewers*",
      viewers_embed: "Unique Viewers (embed)",
      sales: "Sales",
      sum: "$ Amount"
    }
  },
  notes: {
    reference: "* By default, statistics for <b>views</b> and <b>viewers</b> include all player types:",
    embed: "embed - player embedded on your website using a code snippet",
    on_site: "on-site - video player on the turbobit.net download page",
    full: "full - fully-featured player with full-length video, available to premium users",
    demo: "demo - free player with a short video-preview, available to regular users"
  }
};
