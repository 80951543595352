export default {
  summary: 'Übersicht',
  total_referrals: 'Bewertete Empfehlungen',
  first_level: 'Erste Ebene',
  second_level: 'Zweite Ebene',
  total_earned: 'Insgesamt verdient',
  total_earned_value: '${value}',
  detailed_statistics: 'Detaillierte Statistik',
  data_table: {
    fields: {
      user: 'Benutzer',
      referral_level: 'Empfehlungslevel',
      registration_date: 'Registrierungsdatum',
      today_earnings: 'Verdienst heute',
      weekly_earnings: 'Wöchentliche Einnahmen',
      total_earnings: 'Insgesamt verdient',
      ref_percent: 'Prozentsatz für Ref.'
    }
  },
  earnings: '${value}',
  users_with_earnings: 'Benutzer mit Einnahmen',
  search_by_user: 'Nach Benutzern suchen',
  referrals_promo_meterials: 'Empfehlungsmaterialien',
  referral_link: 'Empfehlungs-Einladungslink',
  referrals_banners: 'Empfehlungs-Banner',
  banner_code: 'Bannercode'
};