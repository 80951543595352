export default {
  main_block_title: 'المجالات',
  search_placeholder: 'البحث عن طريق المجال',
  dates_placeholder: 'نطاق التاريخ',
  domain_table: {
    fields: {
      domain: 'المصادر',
      hits: 'الزيارات',
      downloads: 'التحميلات',
      unique_downloads: 'التنزيلات الفريدة',
      purchases: 'المشتريات'
    },
    detailed_row_title: 'إلاحصائيات المفصلة:'
  },
  url_table: { fields: { url: 'العنوان الشبكي Url' } }
};