export default {
  payout_details: "Ödeme bilgileri",
  payout_details_subtitle:
    "Güvenlik nedenleriyle, yeni ve güncellenmiş ödeme ayrıntıları aşağıdaki gibi olmalıdır" +
    "e-posta ile doğrulandı ve {days} gün bekletme süresinden sonra yürürlüğe girdi.",
  type: "Tür",
  details: "Detaylar",
  create_date: "Tarih",
  fee_min_payout: "Ücret / Min ödeme",
  status: "Durum",
  action: "Eylem",
  active: "Aktif",
  on_hold: "Beklemede ({date})",
  waiting_email_confirmation: "E-posta onayı bekleniyor",
  are_you_sure_you_want_to_delete_purse: "Bu cüzdanı kaldırmak istediğinizden emin misiniz?",
  main_purse_can_not_be_deleted:
    "Ana cüzdan kaldırılamaz. Doğrulanmış cüzdan'ı seçin ve geri kalanını kaldırabilmek için ana cüzdan olarak ayarlayın",
  add_new: "Gönder",
  add_new_payout_details: "Yeni ödeme ayrıntılarını gönder",
  payout_system: "Ödeme Sistemi",
  month: "Ay",
  year: "Yıl",
  first_name: "İsim",
  last_name: "Soyisim",
  currency: "Para birimi",
  iban: "iban",
  bank_name: "Banka Adı",
  set_as_main: "Ana para birimi olarak ayarla",
  delete_wallet: "Kaldır",
  no_purses: "Henüz bir cüzdan eklemediniz.",
  confirming_purse: "Doğrulama devam ediyor. Lütfen bekleyin.",
  url_is_invalid: "URL geçersiz veya süresi doldu. Lütfen tekrar deneyin",
  purse_is_confirmed: "Ödeme detaylarının doğrulanması başarıyla tamamlandı",
  available_withdrawal: "Ödeme için kullanılabilir: {amount} USD",
  new_request: "Yeni talep",
  amount_in_hold: "Beklemedeki tutar",
  what_is_hold: "bekleme nedir?",
  files: "Dosyalar",
  referrals: "Referanslar",
  sites_referrals: "Site Referansları",
  file_copies: "Dosya kopyaları",
  request_payout: "Ödeme talep et",
  send_request: "İstek gönder",
  request_payout_amount_field_label: "Miktar",
  Fee: "Ücret",
  Min_payout_is: "Minimum ödeme tutarı",
  exchange_by_rates: "Rusya Merkez Bankası tarafından USD / RUB döviz kuru",
  payout_system_field_name: "{purseType}",
  request_delete_confirmation: "Isteği silmek istediğinizden emin misiniz?",
  request_payout_errors: {
    purse_is_on_hold_one: "Ödeme detayları değiştirildi. Yeni istek {date} tarihinden sonra gönderilebilir",
    purse_is_on_hold_many:
      "Ödeme detayları değiştirildi. Yeni istek {date} tarihinden sonra gönderilebilir" +
      " veya Ayarlarda doğrulanmış cüzdanı seçin"
  },
  payouts: "Ödemeler",
  payouts_subtitle:
    "Ödeme talepleri genellikle ortalama 7 gün içinde işleme koyulur, " +
    "ancak talebin düşük olduğu durumlarda işlem süresi daha da hızlı olabilir.",
  payout_table: {
    fields: {
      created_date: "Tarih",
      amount: "Miktar",
      payment_details: "Cüzdan detayları",
      status: "Durum",
      action: "Eylem"
    }
  },
  payouts_status_label: {
    created: "Oluşturuldu",
    review: "Devam ediyor",
    process: "Ödeme işleme",
    accepted: "Tamamlandı",
    rejected: "Reddedildi",
    deleted: "Silinen"
  },
  submit_payouts_details: "Kaydet"
};
